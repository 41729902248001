import React from 'react';
import { Button, Grid, GridItem, Heading, HStack } from '@chakra-ui/react';

import AnimatedPageWrapper from 'components/App/AnimatedPageWrapper';
import CopyrightNotice from 'components/common/atoms/CopyrightNotice';

import { StandardAuthorisedPageProps } from './StandardAuthorisedPage.types';
import Styled from './StandardAuthorisedPage.styled';

function StandardAuthorisedPage({
    children,
    tabsConfiguration,
    pageTitle,
    footerContent,
    additionalButtons,
}: StandardAuthorisedPageProps): JSX.Element {
    return (
        <AnimatedPageWrapper>
            <Styled.StandardAuthorisedPageWrapper>
                <Styled.StandardAuthorisedPageInnerWrapper>
                    <Grid
                        height="100%"
                        templateAreas={`
                        "header"
                        "main"
                        "footer"`}
                        gridTemplateRows={'minmax(0,auto) minmax(0,1fr) minmax(0, auto)'}
                        gridTemplateColumns={'minmax(0,1fr)'}
                    >
                        <GridItem area={'header'}>
                            <Grid
                                gridTemplateColumns={'minmax(0,1fr) minmax(0,1fr)'}
                                gridColumnGap={'20px'}
                                padding={'10px'}
                            >
                                <GridItem>
                                    <Heading
                                        noOfLines={1}
                                        as="h2"
                                        size="lg"
                                        pb={3}
                                    >
                                        {pageTitle}
                                    </Heading>
                                </GridItem>

                                <GridItem alignItems="center" justifyContent="center">
                                    {additionalButtons && additionalButtons?.length > 0
                                        ? (
                                            <HStack justifyContent={'flex-end'}>
                                                {additionalButtons.map((buttonConfiguration) => (

                                                    <Button
                                                        key={buttonConfiguration.id}
                                                        id={buttonConfiguration.id}
                                                        type={buttonConfiguration.type}
                                                        isDisabled={buttonConfiguration.isDisabled}
                                                        leftIcon={buttonConfiguration.leftIcon}
                                                        onClick={buttonConfiguration.onClick}
                                                    >
                                                        {`${buttonConfiguration.label}`}
                                                    </Button>
                                                ))}

                                            </HStack>
                                        )
                                        : (<></>)}

                                </GridItem>
                            </Grid>
                        </GridItem>

                        <GridItem
                            padding={'0'}
                            area={'main'}
                            overflow={'hidden'}
                        >
                            {children || <></>}
                            {tabsConfiguration ? tabsConfiguration.map((tabConfigItem) => tabConfigItem) : <></>}

                            {!children && !tabsConfiguration ? (<div />) : <></>}
                        </GridItem>


                        <GridItem
                            area={'footer'}
                            padding={'10px'}
                        >
                            {footerContent || <CopyrightNotice />}
                        </GridItem>

                    </Grid>
                </Styled.StandardAuthorisedPageInnerWrapper>
            </Styled.StandardAuthorisedPageWrapper>

        </AnimatedPageWrapper>
    );
}

export default StandardAuthorisedPage;
