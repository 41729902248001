import { getBusinessUserIdDetails, updateBusinessUserId } from './index';


class BusinessUsersService {
    public getBusinessUserDetails(businessUserId) {
        return getBusinessUserIdDetails(businessUserId);
    }

    public updateBusinessUser(requestPayload) {
        const { businessUserId, ...rest } = requestPayload;

        return updateBusinessUserId(businessUserId, rest);
    }
}

export default BusinessUsersService;

