import {
    fetchBeneficiariesAPI,
    fetchBeneficiaryUuidDetails,
    fetchBeneficiaryRequirementsApi,
    createBeneficiaryApi,
    updateBeneficiaryUuid,
    deleteBeneficiaryUuid,
} from './index';


class BeneficiariesService {
    public fetchBeneficiaries(rawQueryParams) {
        const { limit, offset, companyId } = rawQueryParams;

        const queryParams = {
            limit,
            offset,
            companyId,
        };

        return fetchBeneficiariesAPI({ params: queryParams });
    }

    public fetchBeneficiaryDetails(beneficiaryUuid) {
        return fetchBeneficiaryUuidDetails(beneficiaryUuid);
    }

    public fetchBeneficiaryRequirements(rawQueryParams) {
        const { country, currency, memberId, companyId, clientId } = rawQueryParams;

        return fetchBeneficiaryRequirementsApi({
            params: {
                beneficiary_country: country,
                beneficiary_currency: currency,
                ...(memberId > 0 ? { memberId } : {}),
                ...(companyId > 0 ? { companyId } : {}),
                ...(clientId > 0 ? { clientId } : {}),
            },
        });
    }

    public createBeneficiary(formData) {
        return createBeneficiaryApi(formData);
    }

    public updateBeneficiary(beneficiaryUuid, formData) {
        return updateBeneficiaryUuid(beneficiaryUuid, formData);
    }

    public deleteBeneficiary(beneficiaryUuid) {
        return deleteBeneficiaryUuid(beneficiaryUuid);
    }
}

export default BeneficiariesService;
