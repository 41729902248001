import React, { useMemo } from 'react';
import { Button } from '@chakra-ui/react';
import { FaAngleLeft, FaAngleDoubleLeft, FaAngleRight, FaAngleDoubleRight } from 'react-icons/fa';
import { isCurrent } from 'utils/list-tools';

import * as Styled from './Pagination.styled';
import { PaginationProps } from './Pagination.types';

function Pagination({
    totalCount,
    fetchData,
    queryParams,
    showQuickJumper,
}: PaginationProps) {
    const { offset, limit } = queryParams;

    const pages = useMemo(() => totalCount ? Math.ceil(totalCount / limit) : 0,
        [totalCount, limit]);

    const onChange = (newOffset) =>
        fetchData({ ...queryParams, offset: newOffset });

    const getButtonContent = (index) => {
        const handlers = [
            {
                predicate: index === 0 || index === pages - 1 || (index >= offset - 2 && index <= offset + 2),
                handler: () => index + 1,
            },
            {
                predicate: index === offset - 3 || index === offset + 3,
                handler: () => '...',
            },
        ];
        return handlers.find(({ predicate }) => predicate)?.handler();
    };

    return (
        <Styled.PaginationWrapper>
            {
                showQuickJumper && (
                    <Button
                        size="md"
                        variant="ghost"
                        onClick={() => onChange(0)}
                        className="quick-jumper-first"
                    >
                        <FaAngleDoubleLeft />
                    </Button>
                )
            }

            <Button
                size="md"
                variant="ghost"
                onClick={() => offset !== 0 && onChange(offset - 1)}
                isDisabled={offset === 0}
            >
                <FaAngleLeft />
            </Button>

            {
                Array(pages).fill(0).map((_, index) => {
                    const content = getButtonContent(index);
                    return content && (
                        <Button
                            key={index}
                            size="md"
                            variant={offset === index ? 'solid' : 'outline'}
                            onClick={() => onChange(index)}
                            isDisabled={isCurrent({ offset, index })}
                            display={{ base: 'none', md: 'none', lg: 'inline-block' }}
                        >
                            {content}
                        </Button>
                    );
                })
            }

            <Button
                size="md"
                variant="outline"
                disabled
                display={{ md: 'initial', lg: 'none' }}
            >
                {`${offset + 1} / ${pages}`}
            </Button>

            <Button
                size="md"
                variant="ghost"
                onClick={() => offset !== pages - 1 && onChange(offset + 1)}
                isDisabled={offset === pages - 1}
            >
                <FaAngleRight />
            </Button>

            {
                showQuickJumper && (
                    <Button
                        size="md"
                        variant="ghost"
                        onClick={() => onChange(pages - 1)}
                        className="quick-jumper-last"
                    >
                        <FaAngleDoubleRight />
                    </Button>
                )
            }
        </Styled.PaginationWrapper>
    );
}
export default Pagination;
