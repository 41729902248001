import { removeUnderscores } from 'utils/string-tools';
import { CardStatus } from 'models/domain/cards';

export const createCardStatusHandlers = (cardStatus) => {
    const handlers = [
        {
            predicate: () => cardStatus === CardStatus.ACTIVE,
            handler: () => ({ color: 'green', text: removeUnderscores(CardStatus.ACTIVE) }),
        },
        {
            predicate: () => [CardStatus.NOT_ACTIVATED, CardStatus.SUSPENDED].includes(cardStatus),
            handler: () => ({ color: 'orange', text: removeUnderscores(cardStatus) }),
        },
        {
            predicate: () => [
                CardStatus.NOT_DELIVERED,
                CardStatus.LOST,
                CardStatus.STOLEN,
                CardStatus.FRAUD,
                CardStatus.VOIDED,
                CardStatus.EXPIRED,
            ].includes(cardStatus),
            handler: () => ({ color: 'red', text: removeUnderscores(cardStatus) }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown', color: 'red' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { createCardStatusHandlers };
