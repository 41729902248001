import * as React from 'react';
import { Box, Skeleton, Stack } from '@chakra-ui/react';
import { useMemo } from 'react';

import RefreshButton from 'components/common/atoms/RefreshButton';
import Pagination from 'components/common/molecules/Pagination';

import { StandardDataTableFooterProps } from './StandardDataListFooter.types';
import Styled from './StandardDataListFooter.styled';
import { getPaginationSummary } from './StandardDataListFooter.helpers';


function StandardDataListFooter({
    totalCount,
    queryParams,
    fetchData,
    itemsCount,
    t,
    isListReady,
}: StandardDataTableFooterProps) {
    const textSummary = useMemo(
        () => getPaginationSummary({ totalCount, itemsCount, queryParams, t }),
        [itemsCount, queryParams, totalCount],
    );

    return (
        <Box>
            <Styled.StandardDataListFooterWrapper>
                <Styled.StandardDataListFooterRefreshWrapper>
                    {isListReady ? (
                        <RefreshButton onClick={() => fetchData(queryParams)} />
                    ) : (
                        <Skeleton height="20px" width="70px" />
                    )}

                </Styled.StandardDataListFooterRefreshWrapper>

                <Styled.StandardDataListFooterPaginationWrapper>

                    {isListReady ? (
                        <>
                            {totalCount as number <= queryParams.limit
                                ? <span>{textSummary}</span>
                                : (
                                    <>
                                        <span>{textSummary}</span>
                                        <Pagination fetchData={fetchData} queryParams={queryParams} totalCount={totalCount as number} />
                                    </>
                                )}
                        </>
                    ) : (
                        <Skeleton height="40px" width="250px" />
                    )}

                </Styled.StandardDataListFooterPaginationWrapper>
            </Styled.StandardDataListFooterWrapper>
        </Box>
    );
}


export default StandardDataListFooter;
