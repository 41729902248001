import * as yup from 'yup';
import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import { createAddressFields } from 'utils/addressFields-tools';
import { CardType } from 'models/domain/cards';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'store/accounts/reducer';
import { fetchAccountsList } from 'store/accounts/actions';
import { businessUsersListsPerLocationStoreKeyName, businessUsersReducerName } from 'store/business-users/reducer';
import { fetchBusinessUsersList } from 'store/business-users/actions';

export const createAddCardFormFieldsConfiguration = ({ t, countries }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.RADIO,
        id: 'type',
        name: 'type',
        labelText: t('cardFields.type.text'),
        placeholderText: t('cardFields.type.placeholder'),
        optionalConfiguration: {
            isRequired: true,
            options: [
                { value: CardType.physical, label: t('cardFields.type.values.physical') },
                { value: CardType.virtual, label: t('cardFields.type.values.virtual') },
            ],
        },
    },
    {
        fieldType: FormInputTypes.LAZY_AUTOCOMPLETE_SINGLE_VALUE,
        id: 'business_user_id',
        name: 'business_user_id',
        labelText: t('accountFields.employee.label'),
        placeholderText: t('accountFields.employee.placeholder'),
        optionalConfiguration: {
            listsByLocationStoreKeyName: businessUsersListsPerLocationStoreKeyName,
            reduxSetter: fetchBusinessUsersList,
            reducerName: businessUsersReducerName,
            labelKey: 'firstName',
            valueKey: 'id',
            isRequired: true,
            isSearchable: true,

        },
    },
    {
        fieldType: FormInputTypes.LAZY_AUTOCOMPLETE_SINGLE_VALUE,
        id: 'account_id',
        name: 'account_id',
        labelText: t('accountFields.accounts.label'),
        placeholderText: t('accountFields.accounts.placeholder'),
        optionalConfiguration: {
            listsByLocationStoreKeyName: accountsListsPerLocationStoreKeyName,
            reduxSetter: fetchAccountsList,
            reducerName: accountsReducerName,
            labelKey: 'name',
            valueKey: 'id',
            isRequired: true,
            isSearchable: true,
        },
    },
    ...createAddressFields({ t, countries }),
]);


export const createAddCardFormSchema = ({ t }) => (
    yup.object().shape({
        type: yup.number().required(t('common:validationMsg.fieldMandatory')).oneOf([CardType.physical, CardType.virtual]),
        business_user_id: yup.string().required(t('common:validationMsg.fieldMandatory')),
        account_id: yup.string().required(t('common:validationMsg.fieldMandatory')),
        address: yup.object().when('type', (type) => {
            const typeValue = type?.length ? type[0] : null;
            if (typeValue === CardType.physical) {
                return yup.object().shape({
                    address_line_1: yup.string().required(t('common:validationMsg.fieldMandatory')),
                    address_line_2: yup.string(),
                    city: yup.string().required(t('common:validationMsg.fieldMandatory')),
                    country: yup.string().required(t('common:validationMsg.fieldMandatory')),
                    zip_code: yup.string().required(t('common:validationMsg.fieldMandatory')),
                });
            }
            return yup.object().shape({});
        }),

    }).required()
);
