import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';
import { clearCurrentUser } from 'store/current-user/actions';
import { LocationChangeRequest } from 'models/app/navigation';
import { showConfirmation, ShowConfirmationPayload } from 'store/alert/actions';
import { UserDropdownDispatchProps, UserDropdownStateProps } from './UserDropdown.types';
import UserDropdown from './UserDropdown.layout';


const mapStateToProps = (state: RootState):UserDropdownStateProps => ({
    enhancedCurrentLocation: state.navigation.currentLocation,
    username: state.currentUser.userData?.fullName,
});

const mapDispatchToProps = (dispatch: Dispatch): UserDropdownDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) =>
        dispatch(requestNavigation({ locationPathname, meta })),
    dispatchClearCurrentUser: () => dispatch(clearCurrentUser()),
    dispatchShowConfirmation: (payload:ShowConfirmationPayload) => dispatch(showConfirmation(payload)),
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(UserDropdown);
