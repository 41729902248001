import React, { useMemo } from 'react';

import { MAINTANCE_MODE_ENABLED } from 'config/environment';
import { APPLICATION_STATE } from 'models/app/applicationState';

import AppMaintenancePlaceholder from '../AppMaintenancePlaceholder';
import { createAuthorisedRoutesConfiguration, createDefaultRedirectionPath, createPublicRoutesConfiguration } from '../createRoutesConfiguration';
import AppLoadingPlaceholder from '../AppLoadingPlaceholder';
import PublicLayout from '../layouts/PublicLayout';
import AuthorisedLayout from '../layouts/AuthorisedLayout';
import { ApplicationContentProps } from './ApplicationContent.types';
import { hasAccessRights, renderApplicationContent, renderRoutes } from './ApplicationContent.helpers';

function ApplicationContent({ accessControl, applicationState, enhancedCurrentLocation }: ApplicationContentProps): JSX.Element {

    const publicRoutesConfiguration = createPublicRoutesConfiguration();

    const authorisedRoutesConfiguration = createAuthorisedRoutesConfiguration();

    const isAuthorised = useMemo(
        () => accessControl?.isAuthorised,
        [accessControl],
    );


    const showSpinner = useMemo(
        () => applicationState === APPLICATION_STATE.APPLICATION_STARTED
            || (accessControl.isAuthorised && applicationState !== APPLICATION_STATE.AUTHORISED_READY),
        [isAuthorised, applicationState],
    );

    const defaultRedirectionPath = useMemo(
        () => createDefaultRedirectionPath({ isAuthorised }),
        [isAuthorised],
    );

    const publicRoutes = useMemo(
        () => (!isAuthorised ? renderRoutes({ routesConfiguration: publicRoutesConfiguration }) : []),
        [publicRoutesConfiguration, defaultRedirectionPath, accessControl],
    );


    const availableAuthorisedRoutes = useMemo(
        () => {
            const availableRoutes = authorisedRoutesConfiguration.filter((routeConfiguration) => hasAccessRights({
                routeConfiguration,
                accessControl,
            }));

            return renderRoutes({ routesConfiguration: availableRoutes });
        },
        [authorisedRoutesConfiguration, isAuthorised, accessControl],
    );

    return (
        <>
            {MAINTANCE_MODE_ENABLED ? <AppMaintenancePlaceholder /> : null}

            {showSpinner ? <AppLoadingPlaceholder /> : null}


            {!showSpinner && !MAINTANCE_MODE_ENABLED && !isAuthorised
                ? renderApplicationContent({
                    Layout: PublicLayout,
                    routesConfiguration: publicRoutes,
                    defaultRedirectionPath,
                    enhancedCurrentLocation,
                })
                : null}

            {!showSpinner && !MAINTANCE_MODE_ENABLED && isAuthorised
                ? renderApplicationContent({
                    Layout: AuthorisedLayout,
                    routesConfiguration: availableAuthorisedRoutes,
                    defaultRedirectionPath,
                    enhancedCurrentLocation,
                })
                : null}
        </>
    );
}


export default ApplicationContent;

