import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT, DELETE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_SUCCESS,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, FETCH_ACCOUNT_DETAILS, FETCH_ACCOUNT_DETAILS_FAILURE, FETCH_ACCOUNT_DETAILS_SUCCESS,
} from './actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from '../standardEntityDetails.helpers';
import { SET_QUERY_PARAMS } from '../application/action.types';

export const accountReducerName = 'account';
export const accountDetailsPerLocationStoreKeyName = 'accountDetailsPerLocation';


const initialState = {
    [accountDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    isLoadingDeleteAccount: false,
    downloadPdfStatement: {},
};

const handlers = {

    [FETCH_ACCOUNT_DETAILS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
            entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_ACCOUNT_DETAILS_SUCCESS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
            entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_ACCOUNT_DETAILS_FAILURE]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
            entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [CLEAR_ACCOUNT_DETAILS]: (state, action) => {
        state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
            entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === accountReducerName
            && action.payload?.fieldName === accountDetailsPerLocationStoreKeyName
        ) {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                entitiesDetailsState: state?.[accountDetailsPerLocationStoreKeyName],
                action,
            });
        }
    },

    [DOWNLOAD_ACCOUNT_STATEMENT_PDF]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload]: {
                ...state.downloadPdfStatement[action.payload],
                isDownloading: true,
            },
        };
    },

    [DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload]: {
                ...state.downloadPdfStatement[action.payload],
                isDownloading: false,
            },
        };
    },
    [DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE]: (state, action) => {
        state.downloadPdfStatement = {
            ...state.downloadPdfStatement,
            [action.payload]: {
                ...state.downloadPdfStatement[action.payload],
                isDownloading: false,
            },
        };
    },
    [DELETE_ACCOUNT]: (state) => {
        state.isLoadingDeleteAccount = true;
    },
    [DELETE_ACCOUNT_SUCCESS]: (state) => {
        state.isLoadingDeleteAccount = false;
        state.accountDetails = {};
    },
    [DELETE_ACCOUNT_FAILURE]: (state) => {
        state.isLoadingDeleteAccount = false;
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
