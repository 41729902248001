import React from 'react';
import { Spinner } from '@chakra-ui/react';

import Styled from './TheSpinner.styled';
import { TheSpinnerProps } from './TheSpinner.types';


function TheSpinner({ colorScheme, thickness }: TheSpinnerProps): JSX.Element {
    return (
        <Styled.TheSpinnerWrapper>
            <Spinner
                thickness={thickness || '50px'}
                color={colorScheme}
                colorScheme={colorScheme}
                speed="0.9s"
            />
        </Styled.TheSpinnerWrapper>
    );
}

export default TheSpinner;

