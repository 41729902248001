import styled from 'styled-components';


const CardWrapper = styled.div`
  outline: none;
  
  .chakra-card__footer {
    display: block;
  }
`;
const CardFooterActionsWrapper = styled.div`
  display: grid;
  justify-content: right;
  align-items: center;
`;


export default { CardWrapper, CardFooterActionsWrapper };
