import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { showToast } from 'store/application/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'store/accounts/reducer';
import { fetchAccountsList } from 'store/accounts/actions';
import { Epic } from 'models/meta/epic';
import { ToastType } from 'models/app/toast';

import { DELETE_ACCOUNT, FETCH_ACCOUNT_DETAILS } from './actions.types';
import {
    deleteAccountSuccess,
    deleteAccountFailure,
    fetchAccountDetailsSuccess, fetchAccountDetailsFailure,
} from './actions';

export const onFetchAccountDetails: Epic = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNT_DETAILS),
    switchMap(({ payload }) => from(accounts.getAccountDetails(payload.queryParams.accountId))
        .pipe(
            switchMap((response) => of(fetchAccountDetailsSuccess({
                responsePayload: response.data,
                locationPathname: payload.locationPathname,
            }))),
            catchError(() => of(fetchAccountDetailsFailure(payload.locationPathname))),
        )),
);

export const onDeleteAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(DELETE_ACCOUNT),
    switchMap(({ payload }) => from(accounts.deleteAccount(payload.accountId))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    deleteAccountSuccess({ responsePayload: response }),
                    showToast({ type: ToastType.success, message: i18n.t('accounts:actionMessages.deleteAccountSuccess') }),
                    fetchAccountsList({ queryParams: currentAccountListQueryParams, locationPathname: pathname }),
                    // hideDrawer(),
                );
            }),
            catchError(() => of(deleteAccountFailure())),
        )),
);


// export const onDownloadAccountStatementPdf = (action$, state$, { reports }) => action$.pipe(
//     ofType(DOWNLOAD_ACCOUNT_STATEMENT_PDF),
//     switchMap(({ payload }) => from(reports.downloadTransactionStatement(payload))
//         .pipe(
//             switchMap((response) => {
//                 const { userPreferences: { dateFormat } } = state$.value.currentUser.userData;
//                 const formattedDateFrom = formatDate({
//                     date: payload.dateFrom,
//                     dateFormat,
//                     convertFromUTC: false,
//                 })?.replace(/\D/g, '_');
//
//                 const formattedDateTo = formatDate({
//                     date: payload.dateTo,
//                     dateFormat,
//                     convertFromUTC: false,
//                 })?.replace(/\D/g, '_');
//
//                 return of(
//                     saveFile({
//                         blob: response,
//                         fileName: `Statement_${payload.currency}_account_#${payload.accountId}_${formattedDateFrom}-${formattedDateTo}`,
//                         mimeType: CommonMimeTypes.PDF,
//                     }),
//                     downloadAccountStatementPdfSuccess(payload.accountId),
//                     // hideDownloadPdfStatementForm(payload.accountId),
//                 );
//             }),
//             catchError(() => of(downloadAccountStatementPdfFailure(payload.accountId))),
//         )),
// );

export default [
    onFetchAccountDetails,
    onDeleteAccount,
    // onDownloadAccountStatementPdf,
];

