import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { navigationReducerName } from 'store/navigation/reducer';

import { RootState } from 'store/rootReducer';

import { currentUserReducerName } from 'store/current-user/reducer';
import { addAccount } from 'store/accounts/actions';
import { accountsReducerName } from 'store/accounts/reducer';
import AddAccountPage from './AddAccountPage.layout';

import { AddAccountPageStateProps, AddAccountPageDispatchProps } from './AddAccountPage.types';


const mapStateToProps = (state: RootState): AddAccountPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    companyId: state[currentUserReducerName]?.userData?.companyId,
    isLoading: state[accountsReducerName].isLoadingAddAccount,
});

const mapDispatchToProps = (dispatch): AddAccountPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    dispatchAddAccount: ({ locationPathname, requestPayload }) => dispatch(addAccount({ requestPayload, locationPathname })),
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddAccountPage);
