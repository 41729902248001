import { cleanNilValues } from 'utils/dataMappers';
import { SortDirections, SortDirectionsAPI } from 'models/app/applicationState';
import {
    getAccountIdDetails,
    createNewAccountApi,
    deleteAccountId,
    getAccountsAPI,
} from './index';


class AccountsService {
    public getAccountDetails(accountId) {
        return getAccountIdDetails(accountId);
    }

    public getAccountsList(rawQueryParams) {
        const { ownerType, sortOrder, sortBy = 'id', companyId, ...standardQueryParams } = rawQueryParams;


        const queryParams = {
            params: cleanNilValues({
                ...standardQueryParams,
                sortBy,
                companyId,
                ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
            }),
        };
        return getAccountsAPI(queryParams);
    }


    public createNewAccount(requestPayload) {
        return createNewAccountApi(requestPayload);
    }

    public deleteAccount(accountId) {
        return deleteAccountId(accountId);
    }
}

export default AccountsService;

