import React from 'react';

import { emptyValue, StandardValueType } from 'models/app/standardValues';

import Styled from './StandardValue.styled';
import { StandardValueProps } from './StandardValue.types';
import { decorateValue, formatValue } from './StandardValue.helpers';


function StandardValue({
    value,
    dataTestId,
    searchQuery,
    valueType,
    dateFormat,
    timeFormat,
    showTime = false,
    showTimeInTooltip = true,
    disableTextEllipsis = false,
    showCopyToClipboard = true,
    showTooltip = true,
    userPreferences,
}: StandardValueProps): JSX.Element {

    const isTextEllipsisDisabled = disableTextEllipsis || valueType === StandardValueType.AMOUNT;
    const formattedValue = formatValue({
        userPreferences,
        valueType: valueType || StandardValueType.TEXT,
        value,
        dateFormat,
        showTime,
        timeFormat,
    });
    const isAmount = valueType === StandardValueType.AMOUNT;
    const isEmpty = formattedValue === emptyValue;
    const decoratedValue = decorateValue({
        userPreferences,
        timeFormat,
        dateFormat,
        valueType,
        value,
        formattedValue,
        searchQuery,
        showCopyToClipboard,
        isTextEllipsisDisabled,
        dataTestId,
        isEmpty,
        showTimeInTooltip,
        isAmount,
        showTooltip,
    });
    return (
        <Styled.StandardValueWrapper className={isAmount ? 'amount-type-value' : ''}>
            {decoratedValue}
        </Styled.StandardValueWrapper>
    );
}

export default StandardValue;

