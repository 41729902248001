import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'; import { compose } from '@reduxjs/toolkit';

import { RootState } from 'store/rootReducer';
import AuthorisedLayout from './AuthorisedLayout.layout';
import { AuthorisedLayoutOwnProps } from './AuthorisedLayout.types';

const mapStateToProps = (state: RootState) => ({ enhancedCurrentLocation: state.navigation.currentLocation });

const decorate = compose<ComponentType<AuthorisedLayoutOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(AuthorisedLayout);
