import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { standardPageSize } from 'config/config';
import { SortDirections } from 'models/app/applicationState';
import { withTotalCount } from 'services/http/http.helpers';
import { SET_QUERY_PARAMS } from 'store/application/action.types';
import {
    ADD_CARD,
    ADD_CARD_SUCCESS,
    ADD_CARD_FAILURE,
    CLEAR_CARDS_LIST,
    FETCH_CARDS_LIST,
    FETCH_CARDS_LIST_FAILURE, FETCH_CARDS_LIST_SUCCESS,
} from './actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';

export const cardsReducerName = 'cards';
export const cardsListsPerLocationStoreKeyName = 'cardsListsPerLocation';

export const cardsListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    cardStatus: [], // or undefined - TODO double check
    search: '',
    clientId: undefined,
    companyId: undefined,
    memberId: undefined,
    businessUserId: undefined,
    accountId: undefined,
    ...withTotalCount,
};

const initialState = {
    [cardsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddCard: false,
    //
};

const handlers = {
    [FETCH_CARDS_LIST]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[cardsListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CARDS_LIST_SUCCESS]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[cardsListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CARDS_LIST_FAILURE]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[cardsListsPerLocationStoreKeyName],
            action,
        });
    },
    [ADD_CARD]: (state) => {
        state.isLoadingAddCard = true;
    },
    [ADD_CARD_SUCCESS]: (state) => {
        state.isLoadingAddCard = false;
    },
    [ADD_CARD_FAILURE]: (state) => {
        state.isLoadingAddCard = false;
    },
    [CLEAR_CARDS_LIST]: (state, action) => {
        state[cardsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[cardsListsPerLocationStoreKeyName],
            action,
        });
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === cardsReducerName
            && action.payload?.fieldName === cardsListsPerLocationStoreKeyName
        ) {
            state[cardsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state?.[cardsListsPerLocationStoreKeyName],
                action,
            });
        }
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
