import { baseApiUrl } from 'config/config';
import { Action, Dispatch } from 'models/meta/action';
import { ApiError, ApiResponse } from 'models/api/http';
import { convertKeysToCamelCase } from 'utils/convertKeysToCamelCase';

import { createApiError } from './http.helpers';

class HttpServiceInstance {
    private readonly baseUrl;

    private headers?;

    private userJwtToken;

    dispatch?: Dispatch;


    public constructor(baseUrl = baseApiUrl) {
        this.baseUrl = baseUrl;
        this.reset();
    }


    private reset() {
        this.headers = new Headers({ 'Content-Type': 'application/json' });
    }


    public configure(dispatch: Dispatch) {
        this.reset();
        this.dispatch = dispatch;
        return this;
    }

    private handleResponseError(error) {
        if (error.handled) {
            error.actions.forEach((action) => this.dispatch ? this.dispatch(action) : undefined);
        }

        return Promise.reject(error);
    }

    private async request({
        method,
        url,
        data,
        config = {},
    }: {
        method: 'get' | 'post' | 'put' | 'patch' | 'delete',
        url: string,
        config?: any,
        data?: any
    }): Promise<ApiResponse | ApiError> {
        const queryParams = config?.params ? new URLSearchParams(config?.params).toString() : undefined;
        const buildUrl = (endpointURL, queryParamsString) => {
            return `${this.baseUrl}${endpointURL}${queryParamsString?.length > 0 ? `?${queryParamsString}` : ''}`;
        };

        const response = await fetch(
            buildUrl(url, queryParams),
            {
                method,
                mode: 'cors',
                headers: this.headers,
                body: JSON.stringify(data),
            },
        );

        const apiResponseObject = await response.json();

        if (response.ok) {
            const parsedResponse = convertKeysToCamelCase(apiResponseObject);
            return parsedResponse;
        } else {
            // handle the graphql errors
            return Promise.reject(createApiError({ response, config, apiResponseObject }));
        }
    }


    // public methods
    public get(url: string, config?: object): Promise<any> {
    // method, url, data, config
        return this.request({ method: 'get', url, data: undefined, config });
    }

    public delete(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'delete', url, data, config });
    }

    public put(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'put', url, data, config });
    }

    public post(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'post', url, data, config });
    }

    public patch(url: string, data?: object, config?: object): Promise<any> {
        return this.request({ method: 'patch', url, data, config });
    }

    public setUserToken(rawJwtToken?: string) {
        this.userJwtToken = rawJwtToken;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        rawJwtToken
            ? this.headers.append('Authorization', `Bearer ${rawJwtToken}`)
            : undefined;
    }

    public clearUserToken() {
        this.userJwtToken = undefined;
        this.headers.delete('Authorization');
    }

    public setSessionUuid(sessionUuid: string) {
        this.headers.append('session-uuid', sessionUuid);
    }

    public storeDispatch(action: Action) {
        return this.dispatch ? this.dispatch(action) : undefined;
    }
}


export const HttpService = new HttpServiceInstance();

export const createHttpService = () => ({
    setUserToken: HttpService.setUserToken.bind(HttpService),
    clearUserToken: HttpService.clearUserToken.bind(HttpService),
    setSessionUuid: HttpService.setSessionUuid.bind(HttpService),
    storeDispatch: HttpService.storeDispatch.bind(HttpService),
});
