import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { applicationReducerName } from 'store/application/reducer';
import { Dispatch } from 'models/meta/action';
import { setColorScheme } from 'store/application/actions';

import ColorSchemeToggler from './ColorSchemeToggler.layout';
import { ColorSchemeDispatchProps, ColorSchemeStateProps } from './ColorSchemeToggler.types';


const mapStateToProps = (state: RootState): ColorSchemeStateProps => ({ colorScheme: state[applicationReducerName].colorScheme });

const mapDispatchToProps = (dispatch: Dispatch): ColorSchemeDispatchProps => ({
    dispatchSetColorScheme: (colorScheme) => dispatch(setColorScheme(colorScheme)),
    //
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ColorSchemeToggler);
