import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { applicationReducerName } from 'store/application/reducer';

import { StandardSelectStateProps, StandardSelectOwnProps } from './StandardSelect.types';
import StandardSelect from './StandardSelect.layout';


const mapStateToProps = (state: RootState): StandardSelectStateProps => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose<ComponentType<StandardSelectOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);


export default decorate(StandardSelect);
