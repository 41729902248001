import React from 'react';
import { Icon as ChakraIcon } from '@chakra-ui/react';

import { TheIconOwnProps } from './TheIcon.types';

function TheIcon({ Icon, colorScheme }: TheIconOwnProps) {
    // TODO remove any
    return <ChakraIcon as={Icon as any} />;
}

export default TheIcon;
