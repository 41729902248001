import { createReducer } from '@reduxjs/toolkit';

import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'store/standardEntityDetails.helpers';
import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS_FAILURE,
    FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    UPDATE_BUSINESS_USER, UPDATE_BUSINESS_USER_FAILURE, UPDATE_BUSINESS_USER_SUCCESS,
} from './action.types';


export const businessUserReducerName = 'businessUser';
export const businessUserDetailsPerLocationStoreKeyName = ' businessUserDetailsPerLocation';


const initialDetailsState = {
    [businessUserDetailsPerLocationStoreKeyName]: {}, // X XX 'pathname': {...standard }

    isLoadingUpdateBusinessUser: false,
};


const initialState = { ...initialDetailsState };

const handlers = {
    [FETCH_BUSINESS_USER_DETAILS]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
            entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_BUSINESS_USER_DETAILS_SUCCESS]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
            entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_BUSINESS_USER_DETAILS_FAILURE]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
            entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [CLEAR_BUSINESS_USER_DETAILS]: (state, action) => {
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
            entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === businessUserReducerName
      && action.payload?.fieldName === businessUserDetailsPerLocationStoreKeyName
        ) {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                entitiesDetailsState: state?.[businessUserDetailsPerLocationStoreKeyName],
                action,
            });
        }
    },

    [UPDATE_BUSINESS_USER]: (state) => {
        state.isLoadingUpdateBusinessUser = true;
    },
    [UPDATE_BUSINESS_USER_SUCCESS]: (state, action) => {
        state.isLoadingUpdateBusinessUser = false;
        state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
            entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [UPDATE_BUSINESS_USER_FAILURE]: (state) => {
        state.isLoadingUpdateBusinessUser = false;
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};


export default createReducer(initialState, handlers);
