import React from 'react';

import { mapTransactionStatusToColorAndLabel } from './TransactionStatusTag.helpers';
import StandardTag from '../index';


function TransactionStatusTag({ transactionStatus, t }) {
    const tagData = mapTransactionStatusToColorAndLabel({ transactionStatus, t });

    if (tagData) {
        return <StandardTag color={tagData.color} value={tagData.label} />;
    }
    return <>-</>;
}


export default TransactionStatusTag;
