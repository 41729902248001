import styled from 'styled-components';


const StandardTableCellWrapper = styled.div`
  display: inline-grid;
  width: 100%;
  
  & > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;


export default { StandardTableCellWrapper };
