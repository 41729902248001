import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import StandardForm from 'components/common/organisms/StandardForm';
import { accountsListRootRoutePath } from 'config/routes';
import { AddAccountPageProps } from './AddAccountPage.types';
import { createAddAccountFormFieldsConfiguration, createAddAccountFormSchema } from './AddAccountPage.helpers';


function AddAccountPage({
    t,
    companyId,
    dispatchAddAccount,
    isLoading,
}: AddAccountPageProps): JSX.Element {


    return (
        <StandardAuthorisedPage
            pageTitle={t('forms.addAccount.title')}
        >
            <StandardForm
                t={t}
                submitButtonText={t('buttons.addAccount.text')}
                formName="add-account-form"
                initialFormState={{ type: 'Business', currency: '', name: '', companyId }}
                validationSchema={createAddAccountFormSchema({ t })}
                createFieldsConfiguration={createAddAccountFormFieldsConfiguration}
                dispatchOnSubmitAction={(requestPayload) => dispatchAddAccount(
                    { requestPayload, locationPathname: accountsListRootRoutePath },
                )}
                isLoading={isLoading}
            />
        </StandardAuthorisedPage>
    );
}


export default AddAccountPage;
