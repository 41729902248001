import styled from 'styled-components';


const TheSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  justify-items: center;
  position: relative;

  .chakra-spinner {
    width: 300px;
    height: 300px;
  }
`;


export default { TheSpinnerWrapper };

