export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS';
export const FETCH_ACCOUNT_DETAILS_SUCCESS = 'FETCH_ACCOUNT_DETAILS_SUCCESS';
export const FETCH_ACCOUNT_DETAILS_FAILURE = 'FETCH_ACCOUNT_DETAILS_FAILURE';
export const CLEAR_ACCOUNT_DETAILS = 'CLEAR_ACCOUNT_DETAILS';


export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';


export const DOWNLOAD_ACCOUNT_STATEMENT_PDF = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE';
