import styled from 'styled-components';


const StandardDataTableHeaderWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: right;
  grid-template-columns: auto auto;
`;

// TODO
const StandardDataTableHeaderSorterWrapper = styled.div`
  display: none;
`;

export default { StandardDataTableHeaderWrapper, StandardDataTableHeaderSorterWrapper };
