export const rootRoutePath = '/';
export const loginRootRoutePath = '/login';
export const passcodeConfirmRootRoutePath = '/confirm';
export const forgotPasswordRootRoutePath = '/forgot-password';
export const setPasswordRootRoutePath = '/set-password';


export const accountRootRoutePath = '/account';

export const genericAddRouteSegmentPath = 'add';
export const genericEditRouteSegmentPath = 'edit';
export const settingsRouteSegmentPath = 'settings';
export const editSettingsRouteSegmentPath = `${genericEditRouteSegmentPath}-${settingsRouteSegmentPath}`;


export const accountsRouteSegmentPath = 'accounts';
export const cardsRouteSegmentPath = 'cards';
export const cardRouteSegmentPath = 'card';
export const beneficiariesRouteSegmentPath = 'beneficiaries';
export const beneficiaryRouteSegmentPath = 'beneficiary';

export const businessUsersRouteSegmentPath = 'business-users';
export const businessUserRouteSegmentPath = 'business-user';

export const transactionsRouteSegmentPath = 'transactions';

export const firstLevelTabsSlugName = 'activeMainTabSegmentURL';
export const secondLevelTabsSlugName = 'activeSubTabSegmentURL';


export const dashboardRootRoutePath = '/dashboard';
export const faqRootRoutePath = '/faq';
export const attributionsRoutePath = '/attributions';
export const myProfileRootRoutePath = '/my-profile';

export const transactionsListRootRoutePath = `/${transactionsRouteSegmentPath}`;
export const cardsListRootRoutePath = `/${cardsRouteSegmentPath}`;
export const addCardRootRoutePath = `/${cardsRouteSegmentPath}/${genericAddRouteSegmentPath}`;
export const cardDetailsRootRoutePath = `/${cardRouteSegmentPath}`;
export const accountsListRootRoutePath = `/${accountsRouteSegmentPath}`;
export const addAccountRootRoutePath = `/${accountsRouteSegmentPath}/${genericAddRouteSegmentPath}`;

export const businessUserRootRoutePath = `/${businessUserRouteSegmentPath}`;
export const businessUsersListRootRoutePath = `/${businessUsersRouteSegmentPath}`;
export const addBusinessUserRootRoutePath = `${genericEditRouteSegmentPath}/${businessUsersRouteSegmentPath}/`;

export const beneficiaryRootRoutePath = `/${beneficiaryRouteSegmentPath}`;
export const beneficiariesListRootRoutePath = `/${beneficiariesRouteSegmentPath}`;
export const addBeneficiaryRootRoutePath = `/${genericEditRouteSegmentPath}/${beneficiariesRouteSegmentPath}`;

export const editBeneficiaryRootRoutePath = `${genericEditRouteSegmentPath}/${beneficiaryRootRoutePath}`;

export const publicRoutes = [
    rootRoutePath,
    loginRootRoutePath,
    passcodeConfirmRootRoutePath,
    forgotPasswordRootRoutePath,
    setPasswordRootRoutePath,
];

export const authorisedRoutesWithoutClientContext = [
    dashboardRootRoutePath,
    myProfileRootRoutePath,
    faqRootRoutePath,
    transactionsListRootRoutePath,
    firstLevelTabsSlugName,
    secondLevelTabsSlugName,
    attributionsRoutePath,
    editBeneficiaryRootRoutePath,
    beneficiaryRootRoutePath,
    beneficiariesListRootRoutePath,
    businessUserRootRoutePath,
    cardDetailsRootRoutePath,
    cardsListRootRoutePath,
    accountsListRootRoutePath,
    businessUsersListRootRoutePath,
];

