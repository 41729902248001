import React from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { MdPalette, MdExpandLess, MdExpandMore } from 'react-icons/md';

import { ColorSchemeTogglerProps } from './ColorSchemeToggler.types';

import TheIcon from '../TheIcon';
import { createColorSchemeTogglerItemsConfig } from './ColorSchemeToggler.helpers';


function ColorSchemeToggler({
    title = 'Change color',
    colorScheme,
    t,
    dispatchSetColorScheme,
}: ColorSchemeTogglerProps): JSX.Element {

    return (
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton
                        as={Button}
                        leftIcon={<TheIcon Icon={MdPalette} />}
                        rightIcon={<TheIcon Icon={isOpen ? MdExpandLess : MdExpandMore} />}

                    >
                        {title}
                    </MenuButton>

                    <MenuList>
                        {createColorSchemeTogglerItemsConfig({
                            t,
                            dispatchSetColorScheme,
                        }).map((menuItemConfiguration) => (
                            <MenuItem
                                alignContent="center"
                                alignItems="center"
                                key={menuItemConfiguration.text}
                                onClick={menuItemConfiguration.onClick}
                            >
                                {menuItemConfiguration.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </>
            )}
        </Menu>
    );
}

export default ColorSchemeToggler;
