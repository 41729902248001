import styled from 'styled-components';


const StandardValueWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  
  &.amount-type-value {
    font-family: Menlo, monospace;
  }
`;


export default { StandardValueWrapper };

