import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS, GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    REQUEST_PASSWORD_RESET, REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS, SET_NEW_PASSWORD, SET_NEW_PASSWORD_FAILURE, SET_NEW_PASSWORD_SUCCESS,
} from './actions.types';

export interface AuthorisationStateType {
    readonly isLoadingSetNewPassword: boolean;
    readonly isLoadingForgetPassword: boolean;
    readonly isLoadingLoginConfirmationForm: boolean;
    readonly isLoadingLoginForm: boolean;

    readonly loginRequestId?: number;
    readonly passwordResetLinkSent?: any;
    readonly submittedUserEmail?: string;
    readonly loginConfirmFormError?: any;
    readonly loginFormError?: any;
    readonly loginFormRememberedUserEmail?: string;
}

export const authorisationReducerName = 'authorisation';

const initialState: AuthorisationStateType = {
    isLoadingLoginForm: false,
    isLoadingLoginConfirmationForm: false,
    isLoadingForgetPassword: false,
    isLoadingSetNewPassword: false,

    loginRequestId: undefined,
    submittedUserEmail: undefined,
    passwordResetLinkSent: undefined,
    loginFormError: undefined,
    loginConfirmFormError: undefined,

    loginFormRememberedUserEmail: undefined,
};


const handlers = {
    [INITIALISE_LOGIN]: (state, action) => {
        state.isLoadingLoginForm = true;
        state.loginFormError = false;
        state.submittedUserEmail = action.payload.email;
    },
    [INITIALISE_LOGIN_SUCCESS]: (state, action) => {
        state.isLoadingLoginForm = false;
        state.loginRequestId = action.payload;
    },
    [INITIALISE_LOGIN_FAILURE]: (state) => {
        state.isLoadingLoginForm = false;
        state.loginFormError = true;
    },


    [CONFIRM_LOGIN]: (state) => {
        state.isLoadingLoginConfirmationForm = true;
    },
    [CONFIRM_LOGIN_SUCCESS]: (state) => {
        state.isLoadingLoginConfirmationForm = false;
        state.loginConfirmFormError = undefined;
    },
    [CONFIRM_LOGIN_FAILURE]: (state) => {
        state.isLoadingLoginConfirmationForm = false;
        state.loginConfirmFormError = true;
    },

    [REQUEST_PASSWORD_RESET]: (state) => {
        state.isLoadingForgetPassword = true;
    },
    [REQUEST_PASSWORD_RESET_SUCCESS]: (state) => {
        state.isLoadingForgetPassword = false;
        state.passwordResetLinkSent = true;
    },
    [REQUEST_PASSWORD_RESET_FAILURE]: (state) => {
        state.isLoadingForgetPassword = false;
    },

    [SET_NEW_PASSWORD]: (state) => {
        state.isLoadingSetNewPassword = true;
    },
    [SET_NEW_PASSWORD_SUCCESS]: (state) => {
        state.isLoadingSetNewPassword = false;
    },
    [SET_NEW_PASSWORD_FAILURE]: (state) => {
        state.isLoadingSetNewPassword = false;
    },

    [GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS]: (state, action) => {
        state.loginFormRememberedUserEmail = action.payload;
    },


    [CLEAR_CURRENT_USER]: (state) => {
        return {
            ...initialState,
            loginFormRememberedUserEmail: state.loginFormRememberedUserEmail,
        };
    },

};

export default createReducer(initialState, handlers);
