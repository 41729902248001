import React from 'react';
import { motion } from 'framer-motion';

// import { NavigationDirectionTypes } from 'models/app/navigationModel';

import { AnimatedPageWrapperProps } from './AnimatedPageWrapper.types';

const animationForward = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
};

// const animationBackward = {
//     initial: { opacity: 0, x: -100 },
//     animate: { opacity: 1, x: 0 },
//     exit: { opacity: 1, x: 100 },
// };

function AnimatedPageWrapper({
    // enhancedCurrentLocation,
    children,
}: AnimatedPageWrapperProps): JSX.Element {

    // console.log('AnimatedPageWrapper', enhancedCurrentLocation?.direction);

    return (
        <motion.div
            // variants={enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD ? animationForward : animationBackward}
            variants={animationForward}
            initial="initial"
            animate="animate"
            exit="exit"
            style={{ height: '100%' }}
            transition={{ duration: 0.45 }}
        >
            {children}
        </motion.div>
    );
}

export default AnimatedPageWrapper;
