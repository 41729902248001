import { FormInputTypes } from '../models/app/formContols';
import { createCountriesOptions } from './options-crators';

export const createAddressFields = ({ t, countries }) => [
    {
        fieldType: FormInputTypes.TEXT,
        id: 'address.address_line_1',
        name: 'address.address_line_1',
        labelText: t('common:addressFields.addressLine1.label'),
        placeholderText: t('common:addressFields.addressLine1.placeholder'),
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.TEXT,
        id: 'address.address_line_2',
        name: 'address.address_line_2',
        labelText: t('common:addressFields.addressLine2.label'),
        placeholderText: t('common:addressFields.addressLine2.placeholder'),
    },
    {
        fieldType: FormInputTypes.TEXT,
        id: 'address.zip_code',
        name: 'address.zip_code',
        labelText: t('common:addressFields.postCode.label'),
        placeholderText: t('common:addressFields.postCode.placeholder'),
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.TEXT,
        id: 'address.city',
        name: 'address.city',
        labelText: t('common:addressFields.city.label'),
        placeholderText: t('common:addressFields.city.placeholder'),
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
        id: 'address.country',
        name: 'address.country',
        labelText: t('common:addressFields.country.label'),
        placeholderText: t('common:addressFields.country.placeholder'),
        optionalConfiguration: { isRequired: true, options: createCountriesOptions({ countries }) },
    },
];

export const createAddressInitialValues = () => ({
    address: {
        address_line_1: '',
        address_line_2: '',
        zip_code: '',
        city: '',
        country: '',
    },
});

