import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { SET_QUERY_PARAMS } from 'store/application/action.types';

import {
    CLEAR_CARD_DETAILS,
    DELETE_CARD, DELETE_CARD_FAILURE, DELETE_CARD_SUCCESS, FETCH_CARD_DETAILS, FETCH_CARD_DETAILS_FAILURE, FETCH_CARD_DETAILS_SUCCESS,
} from './actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from '../standardEntityDetails.helpers';

export const cardReducerName = 'card';
export const cardDetailsPerLocationStoreKeyName = 'cardDetailsPerLocation';


const initialState = {
    [cardDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    isLoadingDeleteCard: false,
    isLoadingUpdateCard: false,
};

const handlers = {

    [FETCH_CARD_DETAILS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
            entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CARD_DETAILS_SUCCESS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
            entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CARD_DETAILS_FAILURE]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
            entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [CLEAR_CARD_DETAILS]: (state, action) => {
        state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
            entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
            action,
        });
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === cardReducerName
            && action.payload?.fieldName === cardDetailsPerLocationStoreKeyName
        ) {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                entitiesDetailsState: state?.[cardDetailsPerLocationStoreKeyName],
                action,
            });
        }
    },

    [DELETE_CARD]: (state) => {
        state.isLoadingDeleteCard = true;
    },
    [DELETE_CARD_SUCCESS]: (state) => {
        state.isLoadingDeleteCard = false;
    },
    [DELETE_CARD_FAILURE]: (state) => {
        state.isLoadingDeleteCard = false;
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
