import { createAction } from '@reduxjs/toolkit';
import { globalLocation } from 'models/app/navigation';

import { FETCH_COUNTRIES, FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_SUCCESS } from './actions.types';


export const fetchCountries = createAction(FETCH_COUNTRIES, (queryParams = {}, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));

export const fetchCountriesSuccess = createAction(FETCH_COUNTRIES_SUCCESS,
    (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCountriesFailure = createAction(FETCH_COUNTRIES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
