export const REQUEST_NAVIGATION = 'REQUEST_NAVIGATION';
export const BLOCK_NAVIGATION = 'BLOCK_NAVIGATION';
export const UNBLOCK_NAVIGATION = 'UNBLOCK_NAVIGATION';
export const NAVIGATION_STOPPED = 'NAVIGATION_STOPPED';
export const SHOW_NAVIGATION_CONFIRMATION = 'SHOW_NAVIGATION_CONFIRMATION';
export const HIDE_NAVIGATION_CONFIRMATION = 'HIDE_NAVIGATION_CONFIRMATION';
export const NAVIGATION_CANCELED = 'NAVIGATION_CANCELED';
export const PREFORM_NAVIGATION = 'PREFORM_NAVIGATION';
export const PREFORM_NAVIGATION_SUCCESS = 'PREFORM_NAVIGATION_SUCCESS';
export const REQUEST_NAVIGATION_BACK = 'REQUEST_NAVIGATION_BACK';
export const NAVIGATION_BACK_CANCELED = 'NAVIGATION_BACK_CANCELED';
export const PREFORM_NAVIGATION_BACK = 'PREFORM_NAVIGATION_BACK';
export const PREFORM_NAVIGATION_BACK_SUCCESS = 'PREFORM_NAVIGATION_BACK_SUCCESS';
