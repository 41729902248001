import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';

import { RootState } from 'store/rootReducer';

import { clearBeneficiaryDetails, fetchBeneficiaryDetails } from 'store/beneficiary/actions';
import { beneficiaryDetailsPerLocationStoreKeyName, beneficiaryReducerName } from 'store/beneficiary/reducer';
import BeneficiaryDetailsPage from './BeneficiaryDetailsPage.layout';
import {
    BeneficiaryDetailsPageDispatchProps,
    BeneficiaryDetailsPageStateProps,
} from './BeneficiaryDetailsPage.types';


const mapStateToProps = (state: RootState): BeneficiaryDetailsPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: beneficiaryReducerName,
        fieldName: beneficiaryDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

});

const mapDispatchToProps = (dispatch): BeneficiaryDetailsPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),

    dispatchFetchBeneficiaryDetails: ({ queryParams, locationPathname }) => dispatch(fetchBeneficiaryDetails({ queryParams, locationPathname })),
    dispatchClearBeneficiaryDetails: ({ locationPathname }) => dispatch(clearBeneficiaryDetails({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: beneficiaryReducerName,
        fieldName: beneficiaryDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BeneficiaryDetailsPage);
