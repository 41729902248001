import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { navigationReducerName } from 'store/navigation/reducer';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { Currency } from 'models/domain/currencies';
import { Transaction } from 'models/domain/transactions';
import { clearTransactionsList, fetchTransactionsList } from 'store/transactions/actions';
import { transactionsListsPerLocationStoreKeyName, transactionsReducerName } from 'store/transactions/reducer';

import TransactionsList from './TransactionsList.layout';
import { TransactionsListDispatchProps, TransactionsListOwnProps, TransactionsListStateProps } from './TransactionsList.types';

const mapStateToProps = (state: RootState): TransactionsListStateProps => ({
    currenciesListData: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<Transaction>({
        state,
        reducerName: transactionsReducerName,
        listsByLocationStoreKeyName: transactionsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): TransactionsListDispatchProps => ({
    dispatchFetchTransactionsList: ({ queryParams, locationPathname }) => dispatch(fetchTransactionsList({ queryParams, locationPathname })),
    dispatchClearTransactionsList: ({ locationPathname }) => dispatch(clearTransactionsList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: transactionsReducerName,
        fieldName: transactionsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

});

const decorate = compose<ComponentType<TransactionsListOwnProps>>(
    withTranslation('transactions'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(TransactionsList);
