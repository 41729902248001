import React from 'react';

import { StandardFiltersProps } from './StandardFilters.types';
import Styled from './StandardFilters.styled';
import SorterFields from './SorterFields';
import SearchField from './SearchField';
import FilterFields from './FilterFields';

function StandardFilters({
    queryParams,
    filtersConfiguration,
    fetchData,
    colorScheme,
    isListReady,
    t,
}: StandardFiltersProps): JSX.Element {

    const handleQueryParamsChange = ({ value, fieldName }) => {
        fetchData({
            ...queryParams,
            [fieldName]: value,
        });
    };

    return (
        <Styled.StandardFiltersWrapper>
            <SearchField
                isListReady={isListReady}
                filtersConfiguration={filtersConfiguration}
                handleQueryParamsChange={handleQueryParamsChange}
                queryParams={queryParams}
                colorScheme={colorScheme}
                t={t}
            />

            <SorterFields
                filtersConfiguration={filtersConfiguration}
                handleQueryParamsChange={handleQueryParamsChange}
                queryParams={queryParams}
                colorScheme={colorScheme}
                isListReady={isListReady}
                t={t}
            />

            <FilterFields
                filtersConfiguration={filtersConfiguration}
                queryParams={queryParams}
                t={t}
                isListReady={isListReady}
                handleQueryParamsChange={handleQueryParamsChange}
            />

        </Styled.StandardFiltersWrapper>
    );
}

export default StandardFilters;
