import styled from 'styled-components';


const gapValue = 2;

const ColumnsRwdWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  gap: ${gapValue}rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));


  @media (min-width: 786px) {
    grid-template-columns: repeat(2, minmax(calc(50% - ${gapValue / 2}rem), 1fr));
  }

  /* Screen larger than 2000? 3 columns */
  @media (min-width: 2000px) {
    grid-template-columns: repeat(3, minmax(calc(33% - ${gapValue}rem), 1fr));
  }
    
`;

export default { ColumnsRwdWrapper };
