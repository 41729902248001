import React from 'react';
import { MdArrowBackIosNew } from 'react-icons/md';

import StandardLink from 'components/common/atoms/StandardLink';
import { loginRootRoutePath } from 'config/routes';
import TheIcon from 'components/common/atoms/TheIcon';
import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';
import StandardForm from 'components/common/organisms/StandardForm';

import { ForgotPasswordPageProps } from './ForgotPasswordPage.types';
import { createForgotPasswordFormFieldsConfiguration, createForgotPasswordFormSchema } from './ForgotPasswordPage.helpers';


function ForgotPasswordPage({
    isLoadingForgetPassword,
    dispatchRequestPasswordReset,
    passwordResetLinkSent,
    t,
}: ForgotPasswordPageProps): JSX.Element {

    return (
        <StandardUnauthorisedPage
            pageTitle={passwordResetLinkSent ? t('actionMessages.requestPasswordResetSuccess') : t('screenTitles.forgotPassword')}
            footerContent={(
                <StandardLink
                    leftIcon={<TheIcon Icon={MdArrowBackIosNew} />}
                    path={loginRootRoutePath}
                    title={t('formsElements.backToLoginLink.text')}
                    dataTestId="back-to-login-link"
                />
            )}
        >
            <StandardForm
                t={t}
                submitButtonText={t('formsElements.resetPassword.text')}
                formName="forgot-password-form"
                initialFormState={{ email: '' }}
                formError={''}
                isLoading={isLoadingForgetPassword}
                validationSchema={createForgotPasswordFormSchema({ t })}
                createFieldsConfiguration={createForgotPasswordFormFieldsConfiguration}
                dispatchOnSubmitAction={dispatchRequestPasswordReset}
            />
        </StandardUnauthorisedPage>
    );
}

export default ForgotPasswordPage;
