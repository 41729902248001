import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { requestPasswordReset } from 'store/authorisation/actions';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';

import ForgotPasswordPage from './ForgotPasswordPage.layout';
import { ForgotPasswordPageDispatchProps, ForgotPasswordPageStateProps } from './ForgotPasswordPage.types';


const mapStateToProps = (state: RootState): ForgotPasswordPageStateProps => ({
    isLoadingForgetPassword: state.authorisation.isLoadingForgetPassword,
    passwordResetLinkSent: state.authorisation.passwordResetLinkSent,
});

const mapDispatchToProps = (dispatch: Dispatch): ForgotPasswordPageDispatchProps => ({
    dispatchRequestPasswordReset: (requestPayload) => dispatch(requestPasswordReset(requestPayload)),
    //
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(ForgotPasswordPage);
