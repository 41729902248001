import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import { AddBusinessUserPageProps } from './AddBusinessUserPage.types';


function AddBusinessUserPage({
    accessControl,
    enhancedCurrentLocation,
    t,
}: AddBusinessUserPageProps): JSX.Element {


    return (
        <StandardAuthorisedPage
            pageTitle={t('Add account')}
        >
            <h1>TODO: add business user multi step form</h1>
        </StandardAuthorisedPage>
    );
}


export default AddBusinessUserPage;
