export const sortingDirectionMapper = (sortingOrder) => {
    if (sortingOrder === 'ascend') {
        return 'asc';
    }

    return 'desc';
};


export const capitalizeFirstLetter = (string) => {
    if (string && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return '';
};


export const removeUnderscores = (string = '') => (string).replace(/_/g, ' ');

export const enumValueToReadableString = (ENUM_VALUE = '') => capitalizeFirstLetter(removeUnderscores(ENUM_VALUE.toLowerCase()));

export const searchCaseInsensitive = (itemValue, searchQuery) => {
    if (itemValue) {
        return itemValue?.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return false;
};

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export default {
    sortingDirectionMapper,
    capitalizeFirstLetter,
    enumValueToReadableString,
    removeUnderscores,
    searchCaseInsensitive,
};
