import React from 'react';

import { ColumnsRwdProps } from './ColumnsRwd.types';

import Styled from './ColumnsRwd.styled';

function ColumnsRwd({ children }: ColumnsRwdProps) {

    return (
        <Styled.ColumnsRwdWrapper>
            {children}
        </Styled.ColumnsRwdWrapper>
    );

}

export default ColumnsRwd;
