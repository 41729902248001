import React, { useMemo } from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import StandardTabs from 'components/common/molecules/StandardTabs';

import { MyProfilePageProps } from './MyProfilePage.types';
import { createMyProfilePageTabsConfig } from './MyProfilePage.helpers';


function MyProfilePage({
    t,
    jwtToken,
    accessTokenExpirationTimeStamp,
    refreshTokenExpirationTimeStamp,
    userData,
    permissions,
    userPreferences,
}: MyProfilePageProps): JSX.Element {

    const myProfilePageTabsConfig = useMemo(
        () => createMyProfilePageTabsConfig({
            t,
            jwtToken,
            accessTokenExpirationTimeStamp,
            refreshTokenExpirationTimeStamp,
            userData,
            permissions,
            userPreferences,
        }),
        [],
    );
    return (
        <StandardAuthorisedPage
            pageTitle={t('My Profile')}
        >
            <StandardTabs
                tabsConfig={myProfilePageTabsConfig}
            />
        </StandardAuthorisedPage>
    );
}


export default MyProfilePage;
