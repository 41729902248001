import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { clearCurrentUser, setCurrentUser } from 'store/current-user/actions';
import { sessionUuidKey } from 'config/config';
import { getColorScheme, getJwtTokenFormStorage, initI18next, setSessionUuid } from 'store/application/actions';
import { getSessionStorageObjectItem, setSessionStorageObjectItem } from 'utils/browser-storage';
import { generateSessionUuid } from 'utils/session-uuid';
import { getTokensForDuplicatedTab } from 'store/authorisation/actions';
import { getJwt, isTokenNotExpired } from 'utils/jwtToken';
import { setPasswordRootRoutePath } from 'config/routes';

export function refreshToken(jwtToken, authorisation) {
    return from(authorisation.extendTokenValidity(jwtToken.refresh_token)).pipe(
        switchMap((response: any) => {
            return of(setCurrentUser(response.data));
        }),
        catchError(() => of(clearCurrentUser())),
    );
}


export function createAppInitActionsAndSideEffects(httpService, history) {
    const existingSessionUuid = getSessionStorageObjectItem(sessionUuidKey);
    const newSessionUuid = generateSessionUuid();

    // @ts-expect-error type is valid!
    const pageDuplicated = window.performance.getEntriesByType('navigation').map((nav) => nav.type.includes('back_forward'));

    const setUuid = (uuid) => {
        httpService.setSessionUuid(uuid); // axios instance
        setSessionStorageObjectItem(sessionUuidKey, uuid); // session storage
    };

    const handlers = [
        { /* sessionUuid from tab that invoked reset (for safety that source tab's uuid was changed on success )  */
            predicate: () => history.location.pathname === setPasswordRootRoutePath,
            handler: () => {
                const params = new URLSearchParams(history.location.search);
                const sessionUuidFromLink = params.get('session-uuid');
                setUuid(sessionUuidFromLink);
                return of(
                    getColorScheme(),
                    setSessionUuid(sessionUuidFromLink as string),
                    initI18next(),
                    // XXX we ignore JWT tokens in session storage in this case
                );
            },
        },
        { /* duplicated tab (except Safari & FF - new tab will be unauthorised) */
            predicate: () => existingSessionUuid && pageDuplicated && pageDuplicated[0],
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    initI18next(),
                    getColorScheme(),
                    setSessionUuid(newSessionUuid),
                    getJwtTokenFormStorage(existingSessionUuid), // XXX with param!
                );
            },
        },
        { /*  tab reloaded or standard navigation caused by URL change */
            predicate: () => existingSessionUuid,
            handler: () => {
                setUuid(existingSessionUuid);
                return of(
                    initI18next(),
                    getColorScheme(),
                    setSessionUuid(existingSessionUuid),
                    getJwtTokenFormStorage(),
                );
            },
        },

        { /* standard new tab */
            predicate: () => true,
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    setSessionUuid(newSessionUuid),
                    initI18next(),
                    getColorScheme(),
                    getJwtTokenFormStorage(),
                );
            },
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function createGetTokenFormStorage(authorisationService, payload) {
    const jwtToken = getJwt();

    const isAccessTokenNotExpired = isTokenNotExpired(jwtToken.expirationTimeOfAccessToken);

    const handlers = [
        { /* authorised, duplicated tab, distinguished by presence of payload */
            predicate: () => payload && jwtToken && isAccessTokenNotExpired,
            handler: () => of(getTokensForDuplicatedTab({
                session_uuid: payload,
                access_token: jwtToken.access_token,
            })),
        },
        { /* authorised tab */
            predicate: () => jwtToken && isAccessTokenNotExpired,
            handler: () => of(setCurrentUser(jwtToken)),
        },
        { /* was authorised, access token expired but refresh token still valid, attempt to refresh  */
            predicate: () => jwtToken && !isAccessTokenNotExpired && isTokenNotExpired(jwtToken.expirationTimeOfRefreshToken),
            handler: () => of(refreshToken(jwtToken, authorisationService)),
        },

        { /* unauthorised */
            predicate: () => true,
            handler: () => {
                return of(clearCurrentUser());
            },
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default {
    refreshToken,
    createAppInitActionsAndSideEffects,
};

