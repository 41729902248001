import React, { useMemo } from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import CardsList from 'components/common/templates/CardsList';

import { MdAdd } from 'react-icons/md';
import { PN } from 'models/domain/permissions';
import { HTMLButtonType } from 'models/app/applicationState';
import TheIcon from 'components/common/atoms/TheIcon';
import { addCardRootRoutePath } from 'config/routes';
import { CardsPageProps } from './CardsPage.types';

function CardsPage({ t, enhancedCurrentLocation, accessControl, dispatchRequestNavigation }: CardsPageProps): JSX.Element {
    const additionalButtons = useMemo(
        () => ([
            ...(accessControl.hasPermission(PN.CARDS.ORDER_SINGLE) ? [{
                id: 'add-card',
                type: HTMLButtonType.button,
                leftIcon: <TheIcon Icon={MdAdd} />,
                label: 'Add card',
                onClick: () => dispatchRequestNavigation({
                    locationPathname: addCardRootRoutePath,
                    meta: { state: { entryLocationPathname: enhancedCurrentLocation.pathname } },
                }),
            },
            ] : []),
        ]),
        [accessControl],
    );


    return (
        <StandardAuthorisedPage
            pageTitle={t('Cards')}
            additionalButtons={additionalButtons}
        >
            <CardsList />
        </StandardAuthorisedPage>
    );
}


export default CardsPage;
