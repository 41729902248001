import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';
import { LocationChangeRequest } from 'models/app/navigation';
import { RootState } from 'store/rootReducer';
import MainMenu from './MainMenu.layout';
import { MainMenuDispatchProps, MainMenuOwnProps } from './MainMenu.types';


const mapStateToProps = (state:RootState) => ({ enhancedCurrentLocation: state.navigation.currentLocation });

const mapDispatchToProps = (dispatch: Dispatch): MainMenuDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) =>
        dispatch(requestNavigation({ locationPathname, meta })),
});

const decorate = compose<ComponentType<MainMenuOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(MainMenu);
