import React from 'react';

import { Route, Routes } from 'react-router-dom';
import { publicRoutes } from 'config/routes';
import { RedirectionToDefaultPathTypes, RouteConfiguration } from 'models/app/navigation';
import DefaultRedirection from '../DefaultRedirection';

export function hasAccessRights({
    routeConfiguration,
    accessControl,
}) {
    const isPublic = publicRoutes.includes(routeConfiguration.path);

    const handlers = [
        // route-config-driven access control checks, if predicate is valid then redirection
        {
            predicate: () => isPublic === true && accessControl.isAuthorised,
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermission && !accessControl.hasPermission(routeConfiguration.hasPermission),
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasPermissions && !accessControl.hasPermissions(routeConfiguration.hasPermissions),
            handler: () => false,
        },
        {
            predicate: () => routeConfiguration.hasOneOfPermissions && !accessControl.hasOneOfPermissions(routeConfiguration.hasOneOfPermissions),
            handler: () => false,
        },
        {
            // default happy-path scenario, render component
            predicate: () => true,
            handler: () => true,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function renderApplicationContent({
    Layout,
    routesConfiguration,
    defaultRedirectionPath,
    enhancedCurrentLocation,
}) {
    return (
        <Layout>
            <Routes
                key={enhancedCurrentLocation.key}
                location={enhancedCurrentLocation}
            >
                {routesConfiguration}

                <Route
                    path="*"
                    key="not-found"
                    element={(
                        <DefaultRedirection
                            defaultRedirectionPath={defaultRedirectionPath}
                            navigationState={{
                                replace: true,
                                type: RedirectionToDefaultPathTypes.ROUTER_REDIRECTION_TO_DEFAULT_PATH,
                                trigger: 'not_found_redirection_to_default_path',
                                defaultRedirectionPath,
                            }}
                        />
                    )}
                />
            </Routes>
        </Layout>
    );
}

function renderRouteElement({ routeConfiguration }: { routeConfiguration: RouteConfiguration }) {
    const childrenRoutes = routeConfiguration.childrenRoutes || [];
    return (
        <Route
            path={routeConfiguration.path}
            key={routeConfiguration.path}
            element={routeConfiguration?.isIndex ? undefined : routeConfiguration.element}
        >
            {routeConfiguration?.isIndex
                ? (
                    <Route
                        index
                        key={`${routeConfiguration.path}-index`}
                        element={routeConfiguration.element}
                    />
                ) : <></>}

            {childrenRoutes.length > 0
                ? childrenRoutes.map((childRouteConfiguration) => renderRouteElement({ routeConfiguration: childRouteConfiguration }))
                : <></>}
        </Route>
    );
}

export function renderRoutes({ routesConfiguration }) {
    return routesConfiguration.map((routeConfiguration) => renderRouteElement({ routeConfiguration }));
}

export default { renderRouteElement };
