import React from 'react';

import { PN } from 'models/domain/permissions';
import { RouteConfiguration } from 'models/app/navigation';
import {
    accountRootRoutePath,
    accountsListRootRoutePath,
    addAccountRootRoutePath, addBeneficiaryRootRoutePath,
    addBusinessUserRootRoutePath,
    addCardRootRoutePath,
    beneficiariesListRootRoutePath,
    beneficiaryRootRoutePath,
    businessUserRootRoutePath,
    businessUsersListRootRoutePath,
    cardDetailsRootRoutePath,
    cardsListRootRoutePath,
    dashboardRootRoutePath,
    faqRootRoutePath,
    firstLevelTabsSlugName,
    forgotPasswordRootRoutePath,
    loginRootRoutePath,
    myProfileRootRoutePath,
    passcodeConfirmRootRoutePath,
    secondLevelTabsSlugName,
    setPasswordRootRoutePath,
    transactionsListRootRoutePath,
} from 'config/routes';


// unauthorised pages
import LoginPage from 'components/pages/unauthorised/LoginPage';
import PasscodeConfirmPage from 'components/pages/unauthorised/PasscodeConfirmPage';
import ForgotPasswordPage from 'components/pages/unauthorised/ForgotPasswordPage';
import SetPasswordPage from 'components/pages/unauthorised/SetPasswordPage';

// authorised pages
import DashboardPage from 'components/pages/authorised/DashboardPage';
import MyProfilePage from 'components/pages/authorised/MyProfilePage';
import FaqPage from 'components/pages/authorised/FaqPage';
import AccountsPage from 'components/pages/authorised/AccountsPage';
import AccountDetailsPage from 'components/pages/authorised/AccountDetailsPage';
import CardsPage from 'components/pages/authorised/CardsPage';
import CardDetailsPage from 'components/pages/authorised/CardDetailsPage';
import TransactionsPage from 'components/pages/authorised/TransactionsPage';
import BusinessUsersPage from 'components/pages/authorised/BusinessUsersPage';
import BusinessUserDetailsPage from 'components/pages/authorised/BusinessUserDetailsPage';
import BeneficiariesPage from 'components/pages/authorised/BeneficiariesPage';
import BeneficiaryDetailsPage from 'components/pages/authorised/BeneficiaryDetailsPage';
import AddAccountPage from 'components/pages/authorised/AddAccountPage';
import AddBusinessUserPage from '../pages/authorised/AddBusinessUserPage';
import AddCardPage from '../pages/authorised/AddCardPage';
import AddBeneficiaryPage from '../pages/authorised/AddBeneficiaryPage';


export const firstLevelTabsParam = `:${firstLevelTabsSlugName}`;
export const secondLevelTabsParam = `:${secondLevelTabsSlugName}`;

export function createAuthorisedRoutesConfiguration(): RouteConfiguration[] {
    return [
        {
            path: accountsListRootRoutePath,
            element: <AccountsPage />,
            hasPermission: PN.ACCOUNTS.READ,
        },
        {
            path: addAccountRootRoutePath,
            element: <AddAccountPage />,
            hasPermission: PN.ACCOUNTS.CREATE,
        },
        {
            path: `${accountRootRoutePath}/:accountId`,
            element: <AccountDetailsPage />,
            hasPermission: PN.ACCOUNTS.READ_BY_ID,
        },

        {
            path: businessUsersListRootRoutePath,
            element: <BusinessUsersPage />,
            hasPermission: PN.EMPLOYEES.READ,
        },
        {
            path: addBusinessUserRootRoutePath,
            element: <AddBusinessUserPage />,
            hasPermission: PN.EMPLOYEES.WRITE,
        },

        {
            path: `${businessUserRootRoutePath}/:businessUserId`,
            element: <BusinessUserDetailsPage />,
            hasPermission: PN.EMPLOYEES.READ,
        },

        {
            path: beneficiariesListRootRoutePath,
            element: <BeneficiariesPage />,
            hasPermission: PN.BENEFICIARIES.SEARCH,
        },
        {
            path: addBeneficiaryRootRoutePath,
            element: <AddBeneficiaryPage />,
            hasPermission: PN.BENEFICIARIES.CREATE,
        },

        {
            path: `${beneficiaryRootRoutePath}/:beneficiaryUuid`,
            element: <BeneficiaryDetailsPage />,
            hasPermission: PN.EMPLOYEES.READ,
        },

        {
            path: cardsListRootRoutePath,
            element: <CardsPage />,
            hasPermission: PN.CARDS.READ,
        },
        {
            path: addCardRootRoutePath,
            element: <AddCardPage />,
            hasPermission: PN.CARDS.ORDER_SINGLE,
        },

        {
            path: `${cardDetailsRootRoutePath}/:cardId`,
            element: <CardDetailsPage />,
            hasPermission: PN.CARDS.READ_BY_ID,
        },

        {
            path: dashboardRootRoutePath,
            element: <DashboardPage />,
        },
        {
            path: faqRootRoutePath,
            element: <FaqPage />,
        },

        {
            path: myProfileRootRoutePath,
            element: <MyProfilePage />,
            hasPermission: PN.EMPLOYEES.READ,
        },

        {
            path: transactionsListRootRoutePath,
            element: <TransactionsPage />,
            hasPermission: PN.TRANSACTIONS.READ_ALL,
        },
    ];
}

export function createPublicRoutesConfiguration(): RouteConfiguration[] {
    return [
        {
            path: forgotPasswordRootRoutePath,
            element: <ForgotPasswordPage />,
        },
        {
            path: loginRootRoutePath,
            element: <LoginPage />,
        },
        {
            path: passcodeConfirmRootRoutePath,
            element: <PasscodeConfirmPage />,
        },
        {
            path: setPasswordRootRoutePath,
            element: <SetPasswordPage />,
        },
    ];
}


export const createDefaultRedirectionPath = ({ isAuthorised }): string => {
    if (isAuthorised) {
        return dashboardRootRoutePath;
    }

    return loginRootRoutePath;
};

export default { createDefaultRedirectionPath };

