import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { applicationReducerName } from 'store/application/reducer';

import StandardTag from './StandardTag.layout';
import { StandardTagStateProps, StandardTagOwnProps } from './StandardTag.types';

const mapStateToProps = (state: RootState): StandardTagStateProps => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose<ComponentType<StandardTagOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(StandardTag);
