import React from 'react';
import {
    Table, Thead, Th, Skeleton, Tbody, Tr, Td, SkeletonText,
} from '@chakra-ui/react';

import { standardPageSize } from 'config/config';

import { SkeletonTableProps } from './SkeletonTable.types';


function SkeletonTable({ columnCount = 5, rowLimit = standardPageSize }: SkeletonTableProps): JSX.Element {
    return (
        <Table>
            <Thead>
                <Tr>
                    {[...Array(columnCount)].map((_, i) => {
                        return (
                            <Th key={`Th-${i}`}>
                                <Skeleton height="35px" />
                            </Th>
                        );
                    })}
                </Tr>
            </Thead>

            <Tbody>
                {[...Array(rowLimit)].map((_, j) =>
                    (
                        <Tr key={`Tr-${j}`}>
                            {[...Array(columnCount)].map((_2, k) => (
                                <Td key={`Td-${k}`}>
                                    <SkeletonText noOfLines={1} skeletonHeight="35px" />
                                </Td>
                            ))}
                        </Tr>
                    ))}
            </Tbody>
        </Table>
    );
}

export default SkeletonTable;

