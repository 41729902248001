import React from 'react';
import { MdEmail, MdKey } from 'react-icons/md';
import * as yup from 'yup';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import TheIcon from 'components/common/atoms/TheIcon';

import { LoginFormFields } from './LoginPage.types';

export const createLoginFormFieldsConfiguration = ({ t }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.EMAIL,
        id: 'email',
        name: 'email',
        labelText: t('User email'),
        placeholderText: t('Enter your email'),
        hintText: t('We never share your email!'),
        inputLeftElement: <TheIcon Icon={MdEmail} />,
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.PASSWORD,
        id: 'password',
        name: 'password',
        labelText: t('User email'),
        placeholderText: t('Enter your password'),
        inputLeftElement: <TheIcon Icon={MdKey} />,
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.SWITCH,
        id: 'remember-me',
        name: 'rememberMe',
        inlineLabel: true,
        labelText: t('Remember me on this device'),
        hintText: t('Your user email will be stored in browser`s localStorage and auto filled next time you will need to log-in.'),
    },

]);


export const createLoginFormValidationSchema = ({ t }) => {
    return yup.object<LoginFormFields>({
        email: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .email(t('common:validationMsg.emailInvalidFormat'))
            .max(100, t('validationMsg.maximumLength', { maxCount: 100 })),

        password: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .max(100, t('common:validationMsg.maximumLength', { maxCount: 100 }))
            .matches(
                /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                'Must contain at least: one letter, one number and one special case character',
            ),
    }).required();
};

export default { createLoginFormFieldsConfiguration, createLoginFormValidationSchema };
