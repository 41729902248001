import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';

import BusinessUsersPage from './BusinessUsersPage.layout';
import { BusinessUsersPageStateProps, BusinessUsersPageDispatchProps } from './BusinessUsersPage.types';


const mapStateToProps = (state: RootState):BusinessUsersPageStateProps => ({ userData: state.currentUser.userData });
const mapDispatchToProps = (dispatch: Dispatch): BusinessUsersPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUsersPage);
