import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { navigationReducerName } from 'store/navigation/reducer';

import { RootState } from 'store/rootReducer';

import AddBeneficiaryPage from './AddBeneficiaryPage.layout';

import { AddBeneficiaryPageDispatchProps, AddBeneficiaryPageStateProps } from './AddBeneficiaryPage.types';


const mapStateToProps = (state: RootState): AddBeneficiaryPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    //
});

const mapDispatchToProps = (dispatch): AddBeneficiaryPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddBeneficiaryPage);
