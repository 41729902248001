import { createAction } from '@reduxjs/toolkit';
import { ColorScheme } from 'models/app/theme';
import { ShowToastPayload } from 'models/app/toast';

import {
    APPLICATION_READY_AUTHORISED_USER,
    APPLICATION_READY_UNAUTHORISED_USER, CLEAR_RAED_ONLY_MODE, GET_COLOR_SCHEME,
    GET_JWT_TOKEN_FORM_STORAGE, HIDE_AUTO_LOGOUT_BANNER,
    INIT_APPLICATION,
    INIT_I18NEXT,
    INIT_I18NEXT_FAILURE,
    INIT_I18NEXT_SUCCESS, REMOVE_JWT_TOKEN_FROM_STORAGE, SET_CLIPBOARD_CONTENT, SET_COLOR_SCHEME, SET_QUERY_PARAMS, SET_RAED_ONLY_MODE,
    SET_SESSION_UUID, SHOW_AUTO_LOGOUT_BANNER, SHOW_TOAST,
} from './action.types';

export const initApplication = createAction(INIT_APPLICATION);
export const getColorScheme = createAction(GET_COLOR_SCHEME);
export const setColorScheme = createAction(SET_COLOR_SCHEME, (colorScheme: ColorScheme) => {
    return { payload: colorScheme };
});

export const applicationReadyUnauthorisedUser = createAction(APPLICATION_READY_UNAUTHORISED_USER);
export const applicationReadyAuthorisedUser = createAction(APPLICATION_READY_AUTHORISED_USER);

export const setSessionUuid = createAction(SET_SESSION_UUID, (sessionUuid: string) => {
    return { payload: sessionUuid };
});

export const initI18next = createAction(INIT_I18NEXT);
export const initI18nextSuccess = createAction(INIT_I18NEXT_SUCCESS);
export const initI18nextFailure = createAction(INIT_I18NEXT_FAILURE);

export const getJwtTokenFormStorage = createAction(GET_JWT_TOKEN_FORM_STORAGE, (originalSessionUuid?: string) => {
    return { payload: originalSessionUuid };
});
export const removeJwtTokenFromStorage = createAction(REMOVE_JWT_TOKEN_FROM_STORAGE);

export const showAutoLogoutBanner = createAction(SHOW_AUTO_LOGOUT_BANNER);
export const hideAutoLogoutBanner = createAction(HIDE_AUTO_LOGOUT_BANNER);

export const setClipboardContent = createAction(SET_CLIPBOARD_CONTENT, (text) => {
    return { payload: text };
});


export const setReadOnlyMode = createAction(SET_RAED_ONLY_MODE, (readOnlyInfo) => {
    return { payload: readOnlyInfo };
});

export const clearReadOnlyMode = createAction(CLEAR_RAED_ONLY_MODE);


export const showToast = createAction(SHOW_TOAST, ({ type, message, description, options }: ShowToastPayload) => {
    return {
        payload: {
            type,
            message,
            description,
            options,
        },
    };
});


export const setQueryParams = createAction(SET_QUERY_PARAMS, ({ reducerName, fieldName, queryParams, locationPathname }) => {
    return {
        payload: {
            reducerName,
            fieldName,
            queryParams,
            locationPathname,
        },
    };
});

export default {
    initApplication,
    applicationReadyUnauthorisedUser,
    applicationReadyAuthorisedUser,
    initI18next,
    initI18nextSuccess,
    setSessionUuid,
    getJwtTokenFormStorage,
    removeJwtTokenFromStorage,
    showAutoLogoutBanner,
    hideAutoLogoutBanner,
    setClipboardContent,
    setReadOnlyMode,
    clearReadOnlyMode,
    setQueryParams,
    showToast,
};
