import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import { confirmLogin } from 'store/authorisation/actions';
import { requestNavigation } from 'store/navigation/actions';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';

import PasscodeConfirmPage from './PasscodeConfirmPage.layout';
import { PasscodeConfirmPageDispatchProps, PasscodeConfirmPageStateProps } from './PasscodeConfirmPage.types';


const mapStateToProps = (state: RootState): PasscodeConfirmPageStateProps => ({
    loginRequestId: state.authorisation.loginRequestId,

    loginConfirmFormError: state.authorisation.loginConfirmFormError,
    isLoadingLoginConfirmationForm: state.authorisation.isLoadingLoginConfirmationForm,
});

const mapDispatchToProps = (dispatch: Dispatch): PasscodeConfirmPageDispatchProps => ({
    dispatchConfirmLogin: (data) => dispatch(confirmLogin(data)),
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
});


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(PasscodeConfirmPage);
