import styled from 'styled-components';


const SpinnerOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
`;

const SpinnerWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .chakra-spinner {
    width: 300px;
    height: 300px;
  }
`;


const SpinnerOverlayContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  z-index: 1;
`;

export default { SpinnerOverlayWrapper, SpinnerWrapper, SpinnerOverlayContentWrapper };

