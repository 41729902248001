import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { applicationReducerName } from 'store/application/reducer';
import { StandardFiltersOwnProps, StandardFiltersStateProps } from './StandardFilters.types';
import StandardFilters from './StandardFilters.layout';


const mapStateToProps = (state: RootState): StandardFiltersStateProps => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose<ComponentType<StandardFiltersOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);


export default decorate(StandardFilters);
