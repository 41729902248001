import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';

import FaqPage from './FaqPage.layout';
import { FaqPageStateProps } from './FaqPage.types';


const mapStateToProps = (state: RootState): FaqPageStateProps => ({ jwtToken: state.currentUser.jwtToken });

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(FaqPage);
