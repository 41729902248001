import { createAction } from '@reduxjs/toolkit';
import {
    FETCH_BUSINESS_USERS_LIST, FETCH_BUSINESS_USERS_LIST_SUCCESS, FETCH_BUSINESS_USERS_LIST_FAILURE, CLEAR_BUSINESS_USERS_LIST,
    ADD_BUSINESS_USER, ADD_BUSINESS_USER_SUCCESS, ADD_BUSINESS_USER_FAILURE,
} from './action.types';

export const fetchBusinessUsersList = createAction(FETCH_BUSINESS_USERS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchBusinessUsersListSuccess = createAction(
    FETCH_BUSINESS_USERS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchBusinessUsersListFailure = createAction(FETCH_BUSINESS_USERS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearBusinessUsersList = createAction(CLEAR_BUSINESS_USERS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));


export const addBusinessUser = createAction(ADD_BUSINESS_USER, ({ formData, locationPathname }) => ({ payload: { formData, locationPathname } }));
export const addBusinessUserSuccess = createAction(ADD_BUSINESS_USER_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const addBusinessUserFailure = createAction(ADD_BUSINESS_USER_FAILURE);
