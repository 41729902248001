import styled from 'styled-components';


const NoResultsPlaceholderWrapper = styled.div`
  height: 100%;
  padding: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  
  & > div {
    text-align: center;
  }
`;


export default { NoResultsPlaceholderWrapper };
