import React from 'react';

import { standardDateFormat } from 'config/config';
import { formatDate, mapTimeFormat } from 'utils/date-time-tools';
import { amountFormatter } from 'utils/number-tools';
import { formatPhoneNumber } from 'utils/phone-number-tools';

import { formatIBAN } from 'utils/account-tools';
import { emptyValue, StandardValueType } from 'models/app/standardValues';
import { UserPreferences, UserPreferencesDecimalSeparators, UserPreferencesThousandsSeparators } from 'models/domain/user';
import TextWithHighlightedSearch from 'components/common/atoms/TextWithHighlightedSearch';
import StandardTooltip from 'components/common/atoms/StandardTooltip';


export function formatValue({
    userPreferences,
    valueType,
    value,
    dateFormat,
    showTime,
    timeFormat,
}: {
    userPreferences: UserPreferences,
    valueType: StandardValueType,
    value: string | number | null | undefined
    timeFormat?: string,
    showTime?: boolean,
    dateFormat?: string,
}) {
    const handlers = [
        {
            predicate: () => value && valueType === StandardValueType.AMOUNT,
            handler: () => amountFormatter({
                value: Number(value) || 0,
                decimalSeparator: userPreferences?.decimalSeparator || UserPreferencesDecimalSeparators.DOT,
                thousandsSeparator: userPreferences?.thousandsSeparator || UserPreferencesThousandsSeparators.SPACE,
            }),
        },
        {
            predicate: () => valueType === StandardValueType.COUNTER,
            handler: () => value || 0,
        },
        {
            predicate: () => value && valueType === StandardValueType.PERCENTAGE,
            handler: () => `${amountFormatter({
                value: Number(value) || 0,
                decimalSeparator: userPreferences?.decimalSeparator || UserPreferencesDecimalSeparators.DOT,
                thousandsSeparator: userPreferences?.thousandsSeparator || UserPreferencesThousandsSeparators.SPACE,
            })} %`,
        },
        {
            predicate: () => value && valueType === StandardValueType.DATE,
            handler: () => formatDate({
                date: value,
                dateFormat: showTime
                    ? `${dateFormat || userPreferences?.dateFormat || standardDateFormat} ${timeFormat || mapTimeFormat(userPreferences?.timeFormat)}`
                    : dateFormat || userPreferences?.dateFormat || standardDateFormat,
            }),
        },
        {
            predicate: () => value && valueType === StandardValueType.IBAN,
            handler: () => formatIBAN(`${value}`),
        },
        {
            predicate: () => value && valueType === StandardValueType.PHONE,
            handler: () => formatPhoneNumber(value),
        },
        {
            predicate: () => value || (valueType === StandardValueType.AMOUNT && value === 0),
            handler: () => value,
        },
        {
            predicate: () => true,
            handler: () => emptyValue,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}

export function decorateValue({
    userPreferences,
    timeFormat,
    dateFormat,
    valueType,
    value,
    formattedValue,
    searchQuery,
    dataTestId,
    showCopyToClipboard,
    isTextEllipsisDisabled,
    isEmpty,
    isAmount,
    showTooltip,
    showTimeInTooltip,
}) {
    const tooltipValue = valueType === StandardValueType.DATE
        ? formatDate({
            date: value,
            dateFormat: showTimeInTooltip
                ? `${dateFormat || userPreferences?.dateFormat || standardDateFormat} ${timeFormat || mapTimeFormat(userPreferences?.timeFormat)}`
                : dateFormat || userPreferences?.dateFormat || standardDateFormat,
        })
        : formattedValue;


    const withTooltip = (content) => (
        <StandardTooltip
            overlayStyle={{ maxWidth: '600px' }}
            title={tooltipValue}
            showCopyToClipboard={showCopyToClipboard}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            isAmount={isAmount}
        >
            {content}
        </StandardTooltip>
    );

    const handlers = [
        {
            predicate: () => isEmpty,
            handler: () => formattedValue,
        },
        {
            predicate: () => searchQuery && searchQuery.length > 0 && showTooltip === false,
            handler: () => <TextWithHighlightedSearch rawValue={formattedValue} filterValue={searchQuery} />,
        },
        {
            predicate: () => searchQuery && searchQuery.length > 0,
            handler: () => withTooltip(<TextWithHighlightedSearch rawValue={formattedValue} filterValue={searchQuery} />),
        },
        {
            predicate: () => showTooltip === false,
            handler: () => formattedValue,
        },
        {
            predicate: () => true,
            handler: () => withTooltip(formattedValue),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default {
    StandardValueType,
    emptyValue,
    formatValue,
    decorateValue,
};
