import { createEpicMiddleware } from 'redux-observable';
import { configureStore } from '@reduxjs/toolkit';
import { isObservable } from 'rxjs';
import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';

import logger from 'redux-logger';
import rootEpics from 'store/rootEpics';
import { developmentMode, ENABLE_DEV_TOOLS, ENABLE_REDUX_LOGGER } from 'config/environment';

import { rootReducer } from './rootReducer';


const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    configureScopeWithState: (scope, state) => {
        if (state.currentUser && state.currentUser.userData) {
            Sentry.setUser({
                sessionUuid: state.application.state,
                id: state.currentUser.userData.id,
                email: state.currentUser.userData.email,
                username: state.currentUser.userData.username,
                clientId: state.currentUser.userData.clientId,
                roleId: state.currentUser.userData.roleId,
            } as User);
        }
    },
});


export function createStore(dependencies) {
    const epicMiddleware = createEpicMiddleware({ dependencies });

    const middleware = [
        ...(developmentMode || ENABLE_REDUX_LOGGER ? [logger] : []),
        epicMiddleware,
    ];

    const storeInstance = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false,
        }).concat(middleware),
        devTools: developmentMode || ENABLE_DEV_TOOLS,
        enhancers: [sentryReduxEnhancer],
    });


    const services = [...Object.values(dependencies)];
    // XXX see `get action$()` in Location Service!
    services
        .map((item: any) => item.action$)
        .filter(isObservable)
        .forEach((action$) => action$.subscribe(storeInstance.dispatch));

    epicMiddleware.run(rootEpics);

    return storeInstance;
}


export default createStore;
