/* eslint-disable max-len */
import React from 'react';
import { Text, Stack } from '@chakra-ui/react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';

import { FaqPagePops } from './FaqPage.types';


function FaqPage({ t }: FaqPagePops): JSX.Element {

    return (
        <StandardAuthorisedPage
            pageTitle={t('F.A.Q')}
        >
            <ScrollableContainer>
                <Stack spacing={3}>
                    <Text as={'kbd'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis pulvinar risus sit amet molestie. Morbi posuere lorem sit amet justo rhoncus cursus ac tristique mauris. Sed non dui ut risus ultrices aliquam in vel neque. Proin vitae posuere tortor. Mauris ultricies facilisis sem, at efficitur justo porta vitae. Aenean quis convallis lectus, in vestibulum enim. Aenean et urna convallis, imperdiet sapien non, mollis enim. Nam scelerisque sed erat sit amet placerat. Etiam congue dictum eleifend. Phasellus ut mi eu arcu mollis blandit.
                    </Text>
                    <Text as={'kbd'}>
                        Nullam eu sodales dui. Donec sit amet laoreet nulla. Aliquam feugiat aliquam posuere. Nullam sodales, erat at pulvinar mattis, enim quam pulvinar ex, sit amet dictum est nisi vitae est. Proin porta pulvinar metus, at fringilla augue porta et. Phasellus blandit lacinia commodo. Fusce gravida pharetra dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam et libero quis dolor cursus finibus ac dignissim tortor. Nullam iaculis vehicula justo sed eleifend. Vivamus quis efficitur eros.
                    </Text>
                    <Text as={'kbd'}>
                        Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse potenti. Proin enim nibh, porta sed aliquet in, suscipit eget risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus nisl nec libero ornare, quis vestibulum nulla rhoncus. Aenean pulvinar nisi nec enim volutpat, vitae feugiat magna venenatis. Donec malesuada mauris in vehicula fringilla. Donec aliquam sapien et ante sodales dapibus.
                    </Text>
                    <Text as={'kbd'}>
                        In porta metus vel nulla tincidunt semper. Quisque ornare ornare ligula quis interdum. Sed nec mattis risus. Fusce tristique ut sem quis lacinia. Pellentesque tristique leo ligula, nec rutrum mi volutpat in. Vestibulum consequat erat nunc, vitae malesuada leo rutrum et. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam placerat aliquam fringilla. Aenean quis malesuada massa. Etiam a neque ullamcorper, pharetra lacus sit amet, congue velit. Maecenas eget iaculis nibh, id egestas nisl. Pellentesque viverra venenatis odio, id elementum dolor consequat et. Nam scelerisque nec elit vel cursus. Proin varius sed orci sit amet dignissim. Suspendisse risus nisi, pretium at magna sit amet, pharetra eleifend dui.
                    </Text>
                    <Text as={'kbd'}>
                        Curabitur ut eros eget quam interdum dictum. Duis nec lectus massa. In accumsan nibh id mi posuere rhoncus. Donec non commodo metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Suspendisse lacinia augue non dolor efficitur scelerisque. Aliquam nisl urna, tincidunt sed eleifend ut, feugiat at erat. Nullam lobortis, quam auctor lobortis placerat, dui felis vulputate lorem, vel elementum magna lorem eu lorem. In hac habitasse platea dictumst. Nullam venenatis et nunc sed ultrices. Proin quis vulputate nisl, non convallis dui. Suspendisse accumsan, risus sit amet sodales consectetur, ipsum mauris accumsan nisi, nec cursus elit ipsum eu ante. Aliquam nec lectus in enim pharetra volutpat. Donec iaculis lorem imperdiet sem facilisis, ac dictum massa placerat. Aenean malesuada at turpis a vulputate.
                    </Text>

                    <Text as={'kbd'}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean facilisis pulvinar risus sit amet molestie. Morbi posuere lorem sit amet justo rhoncus cursus ac tristique mauris. Sed non dui ut risus ultrices aliquam in vel neque. Proin vitae posuere tortor. Mauris ultricies facilisis sem, at efficitur justo porta vitae. Aenean quis convallis lectus, in vestibulum enim. Aenean et urna convallis, imperdiet sapien non, mollis enim. Nam scelerisque sed erat sit amet placerat. Etiam congue dictum eleifend. Phasellus ut mi eu arcu mollis blandit.
                    </Text>
                    <Text as={'kbd'}>
                        Nullam eu sodales dui. Donec sit amet laoreet nulla. Aliquam feugiat aliquam posuere. Nullam sodales, erat at pulvinar mattis, enim quam pulvinar ex, sit amet dictum est nisi vitae est. Proin porta pulvinar metus, at fringilla augue porta et. Phasellus blandit lacinia commodo. Fusce gravida pharetra dapibus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Etiam et libero quis dolor cursus finibus ac dignissim tortor. Nullam iaculis vehicula justo sed eleifend. Vivamus quis efficitur eros.
                    </Text>
                    <Text as={'kbd'}>
                        Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Suspendisse potenti. Proin enim nibh, porta sed aliquet in, suscipit eget risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum rhoncus nisl nec libero ornare, quis vestibulum nulla rhoncus. Aenean pulvinar nisi nec enim volutpat, vitae feugiat magna venenatis. Donec malesuada mauris in vehicula fringilla. Donec aliquam sapien et ante sodales dapibus.
                    </Text>
                    <Text as={'kbd'}>
                        In porta metus vel nulla tincidunt semper. Quisque ornare ornare ligula quis interdum. Sed nec mattis risus. Fusce tristique ut sem quis lacinia. Pellentesque tristique leo ligula, nec rutrum mi volutpat in. Vestibulum consequat erat nunc, vitae malesuada leo rutrum et. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam placerat aliquam fringilla. Aenean quis malesuada massa. Etiam a neque ullamcorper, pharetra lacus sit amet, congue velit. Maecenas eget iaculis nibh, id egestas nisl. Pellentesque viverra venenatis odio, id elementum dolor consequat et. Nam scelerisque nec elit vel cursus. Proin varius sed orci sit amet dignissim. Suspendisse risus nisi, pretium at magna sit amet, pharetra eleifend dui.
                    </Text>
                    <Text as={'kbd'}>
                        Curabitur ut eros eget quam interdum dictum. Duis nec lectus massa. In accumsan nibh id mi posuere rhoncus. Donec non commodo metus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam erat volutpat. Suspendisse lacinia augue non dolor efficitur scelerisque. Aliquam nisl urna, tincidunt sed eleifend ut, feugiat at erat. Nullam lobortis, quam auctor lobortis placerat, dui felis vulputate lorem, vel elementum magna lorem eu lorem. In hac habitasse platea dictumst. Nullam venenatis et nunc sed ultrices. Proin quis vulputate nisl, non convallis dui. Suspendisse accumsan, risus sit amet sodales consectetur, ipsum mauris accumsan nisi, nec cursus elit ipsum eu ante. Aliquam nec lectus in enim pharetra volutpat. Donec iaculis lorem imperdiet sem facilisis, ac dictum massa placerat. Aenean malesuada at turpis a vulputate.
                    </Text>
                </Stack>
            </ScrollableContainer>
        </StandardAuthorisedPage>
    );
}


export default FaqPage;
