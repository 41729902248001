import styled, { css } from 'styled-components';
import { Box } from '@chakra-ui/react';

const AppLoadingPlaceholder = styled(Box)`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  align-items: center;
  justify-items: center;
`;


const BrandAnimatedLoadingPlaceholder = styled.div`
  text-align: center;
  
  img {
    height: auto;
    max-height: 300px;

    width: 100%;
    min-width: 280px;
    max-width: 70%;

    margin: 0 auto;
  }
`;

const LoadingProgressInfoWrapper = styled.div`
  position: absolute;
  bottom: 30px;
`;

const LoadingProgressInfo = styled.div`
  font-family: monospace;
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  grid-column-gap: 20px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;

  .chakra-spinner {
    width: 10px;
    height: 10px;
  }

  
  ${({ isLoaded }) => isLoaded && css`
    color: green;
  `};
`;

const Label = styled.span`
  font-size: 12px;
`;

const LoadedLabelWrapper = styled.span`
  font-size: 12px;
`;
const LoadedLabel = styled.span`
  padding-right: 2ch;
`;

export default {
    AppLoadingPlaceholder,
    BrandAnimatedLoadingPlaceholder,
    LoadingProgressInfoWrapper,
    LoadingProgressInfo,
    Label,
    LoadedLabelWrapper,
    LoadedLabel,
};

