import { createReducer } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

import { userPreferencesKey } from 'config/config';
import { setSessionStorageObjectItem } from 'utils/browser-storage';
import { DecodedJwtTokenData } from 'models/domain/token';

import { RawJwtToken } from 'models/api/auth';
import { Timestamp } from 'models/app/common';
import { UserDataFormToken } from 'models/domain/user';
import { CLEAR_CURRENT_USER, EXTEND_TOKEN_VALIDITY_SUCCESS, SET_CURRENT_USER_SUCCESS } from './actions.types';


export const currentUserReducerName = 'currentUser';

export interface CurrentUserStateType {
    readonly jwtToken?: RawJwtToken;
    readonly accessTokenExpirationTimeStamp?: Timestamp;
    readonly refreshTokenExpirationTimeStamp?: Timestamp;
    readonly userData?: UserDataFormToken;
    readonly clientConfig?: any;
    readonly permissions?: any;

    readonly userPreferences?: any;
}

const initialState: CurrentUserStateType = {
    jwtToken: undefined,
    accessTokenExpirationTimeStamp: undefined,
    refreshTokenExpirationTimeStamp: undefined,

    userData: undefined,
    clientConfig: undefined,
    permissions: undefined,
    userPreferences: undefined,
};


const handleNewToken = (state, data) => {
    const {
        accessToken,
        expirationTimeOfAccessToken,
        expirationTimeOfRefreshToken,
    } = data;

    const decodedUserData: DecodedJwtTokenData = jwtDecode(accessToken);

    state.jwtToken = accessToken;
    state.accessTokenExpirationTimeStamp = new Date(expirationTimeOfAccessToken).getTime();
    state.refreshTokenExpirationTimeStamp = new Date(expirationTimeOfRefreshToken).getTime();

    state.userData = decodedUserData;

    // XXX bit tricky part... you get user preferences on login, you need to store it because of page reload or navigation scenarios
    // ...but you need to update it when successful (self)user edition occurs
    // so although it is a data duplication IMHO it is safer/cleaner to move it to separate state & session object key
    if (decodedUserData?.userPreferences) {
        state.userPreferences = decodedUserData.userPreferences;
    } else {
    // getSessionStorageObjectItem()
    }

    setSessionStorageObjectItem(userPreferencesKey, decodedUserData.userPreferences);
};


const handlers = {
    [SET_CURRENT_USER_SUCCESS]: (state, action) => {
        handleNewToken(state, action.payload);
        state.permissions = action.payload.permissions;

    },
    [EXTEND_TOKEN_VALIDITY_SUCCESS]: (state, action) => {
        handleNewToken(state, action.payload);
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },

};


export default createReducer(initialState, handlers);
