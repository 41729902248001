import React, { useMemo } from 'react';
import { Divider, Heading } from '@chakra-ui/react';

import StandardAccordion from 'components/common/atoms/StandardAccordion';
import { sortObjectKeysAlphabetically } from 'utils/sorters';

import { MyPermissionsProps } from './MyPermissions.types';


function MyPermissions({ permissions, t }: MyPermissionsProps):JSX.Element {
    const topLevelPermissionKeys = useMemo(
        () => Object.keys(sortObjectKeysAlphabetically(permissions)),
        [permissions],
    );

    const accordionConfiguration = useMemo(
        () => {
            return topLevelPermissionKeys.map((permissionKey, index) => {
                return {
                    tabLabel: permissionKey,
                    children: (
                        <pre>
                            {JSON.stringify(permissions[permissionKey], null, 2)}
                            {' '}
                        </pre>
                    ),
                };
            });
        },
        [topLevelPermissionKeys],
    );

    return (
        <div>
            <Heading>{t('My Permissions')}</Heading>
            <Divider />
            <StandardAccordion itemsConfig={accordionConfiguration} />
        </div>
    );
}
export default MyPermissions;
