import React from 'react';

import StandardTag from 'components/common/atoms/StandardTag';

import { mapTransactionScopeToColorAndLabel } from './TransactionScope.helpers';


function TransactionScopeTag({ transactionRecord, t }) {
    const tagData = mapTransactionScopeToColorAndLabel({ transactionRecord, t });
    if (tagData) {
        return (
            <StandardTag
                color={tagData?.color}
                value={tagData.label}
            />
        );
    }
    return <>-</>;
}


export default TransactionScopeTag;
