import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';


import { getStandardListData } from 'store/standardLists.helpers';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'store/countries/reducer';

import CountryRenderer from './CountryRenderer.layout';
import { CountryRendererOwnProps } from './CountryRenderer.types';


const mapStateToProps = (state) => ({
    countriesList: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const decorate = compose<ComponentType<CountryRendererOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(CountryRenderer);
