import React from 'react';
import { useColorMode } from '@chakra-ui/system';
import { useColorModeValue } from '@chakra-ui/react';

import { ScrollableContainerOwnProps } from './ScrollableContainer.types';
import Styled from './ScrollableContainer.styled';


function ScrollableContainer({
    children,
    colorScheme,
}: ScrollableContainerOwnProps): JSX.Element {
    const { colorMode } = useColorMode();
    const bg = useColorModeValue('gray.300', 'gray.200');
    const color = useColorModeValue(`${colorScheme}.500`, `${colorScheme}.200`);

    return (
        <Styled.ScrollableContainer
            className={colorMode}
            sx={{
                '&::-webkit-scrollbar': { backgroundColor: bg },
                '&::-webkit-scrollbar-thumb': { backgroundColor: color },
                '&::-webkit-scrollbar-track': { backgroundColor: bg },
            }}
        >
            <Styled.ScrollableContainerInnerWrapper>
                {children}
            </Styled.ScrollableContainerInnerWrapper>
        </Styled.ScrollableContainer>
    );
}

export default ScrollableContainer;
