import React from 'react';

import { ColorScheme } from 'models/app/theme';


export const createColorSchemeTogglerItemsConfig = ({
    t,
    dispatchSetColorScheme,
}) => {

    return (Object.keys(ColorScheme) as Array<keyof typeof ColorScheme>).map((key) => {
        return {
            text: t(key),
            onClick: () => dispatchSetColorScheme(key),
        };
    });

};

export default { createColorSchemeTogglerItemsConfig };
