export enum ColorScheme {
    // whiteAlpha = 'whiteAlpha',
    // blackAlpha = 'blackAlpha',
    // gray = 'gray',
    red = 'red',
    orange = 'orange',
    // yellow = 'yellow',
    green = 'green',
    teal = 'teal',
    blue = 'blue',
    cyan = 'cyan',
    purple = 'purple',
    // linkedin = 'linkedin',
    // facebook = 'facebook',
    // messenger = 'messenger',
    // whatsapp = 'whatsapp',
    // twitter = 'twitter',
    // telegram = 'telegram',
}
