import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { navigationReducerName } from 'store/navigation/reducer';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { Currency } from 'models/domain/currencies';
import { clearBeneficiariesList, fetchBeneficiariesList } from 'store/beneficiaries/actions';
import { beneficiariesListsPerLocationStoreKeyName, beneficiariesReducerName } from 'store/beneficiaries/reducer';
import { Beneficiary } from 'models/domain/benefciaries';

import { BeneficiariesListStateProps, BeneficiariesListDispatchProps } from './BeneficiariesList.types';
import BusinessUsersList from './BeneficiariesList.layout';

const mapStateToProps = (state: RootState): BeneficiariesListStateProps => ({
    currenciesListData: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<Beneficiary>({
        state,
        reducerName: beneficiariesReducerName,
        listsByLocationStoreKeyName: beneficiariesListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): BeneficiariesListDispatchProps => ({
    dispatchFetchBeneficiariesList: ({ queryParams, locationPathname }) => dispatch(fetchBeneficiariesList({ queryParams, locationPathname })),
    dispatchClearBeneficiariesList: ({ locationPathname }) => dispatch(clearBeneficiariesList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: beneficiariesReducerName,
        fieldName: beneficiariesListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

});

const decorate = compose<ComponentType>(
    withTranslation('beneficiaries'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUsersList);
