import { useEffect, useMemo, useState } from 'react';
import { EnhancedLocation, LocationChangeActionScopeTypes, NavigationDirectionTypes } from 'models/app/navigation';
import { EntityDetailsLocationAndQueryParams, EntityDetailsQueryParams, StandardEntityDetailsData } from '../models/app/standardEntityDetails';

export interface UseStandardDetailsPageCachingLogic <Item> {
    baseUrlWithoutTabsSlugs: string,
    customLocation?: string,
    omitDataFetching?: boolean,

    contextEnforcedQueryParams: EntityDetailsQueryParams,

    enhancedCurrentLocation: EnhancedLocation,
    entityDetailsData: StandardEntityDetailsData<Item>,

    dispatchFetchEntityDetails: ({ queryParams, locationPathname }: EntityDetailsLocationAndQueryParams) => void,
    dispatchClearEntityDetails: ({ locationPathname }: { locationPathname: string }) => void,
    dispatchSetQueryParams:({ queryParams, locationPathname }: EntityDetailsLocationAndQueryParams) => void,
}

export function useStandardDetailsPageCachingLogic<ItemDefinition>({
    enhancedCurrentLocation,
    baseUrlWithoutTabsSlugs,

    contextEnforcedQueryParams,
    entityDetailsData,

    dispatchFetchEntityDetails,
    dispatchClearEntityDetails,
    dispatchSetQueryParams,
}: UseStandardDetailsPageCachingLogic<ItemDefinition>) {
    const [initialLocation, setInitialLocation] = useState(enhancedCurrentLocation.pathname);
    const [areInitialQueryParamsSet, setAreInitialQueryParamsSet] = useState(false);
    const [doesDetailsPageRequiresCleanup, setDoesDetailsPageRequiresCleanup] = useState(false);
    const [isDetailsPageReadyForDisplay, setIsDetailsPageReadyForDisplay] = useState(false);

    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = { ...contextEnforcedQueryParams };

    const hasValidLocation = useMemo(
        () => enhancedCurrentLocation?.pathname?.length > 0
      // && enhancedCurrentLocation?.pathname === initialLocation // XXX unmount case - ignore navigation somewhere else
      && enhancedCurrentLocation?.pathname.includes(baseUrlWithoutTabsSlugs), // XXX entity data stored under baseUrlWithoutTabsSlugs key
        [enhancedCurrentLocation, areInitialQueryParamsSet],
    );


    const isDetailsPagePristineEmpty = useMemo(
        () => !entityDetailsData?.entityDetails,
        [enhancedCurrentLocation],
    );


    const onLocationChangeHandlers = [
    // no location === no details fetching because at least ID/UUID is mandatory (possibly with other optional query params)
    // and it comes form URL params
        {
            predicate: () => !hasValidLocation && !(Object.keys(contextEnforcedQueryParams || {})?.length > 0),
            handler: () => {
                console.log('test 1');
                return undefined;
            },
        },
        // returning to on given location via forward navigation
        // & clear existing data for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && !doesDetailsPageRequiresCleanup
        && enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD
        && enhancedCurrentLocation?.state?.type !== LocationChangeActionScopeTypes.TAB_CHANGE,
            handler: () => {
                console.log('test 2');
                const payload = { queryParams: initialQueryParams, locationPathname: baseUrlWithoutTabsSlugs };
                dispatchClearEntityDetails({ locationPathname: baseUrlWithoutTabsSlugs });
                setDoesDetailsPageRequiresCleanup(true);
                setAreInitialQueryParamsSet(true);
                dispatchSetQueryParams(payload);
                dispatchFetchEntityDetails(payload);
            },
        },
        // returning to on given location via go back navigation, just display what is already there, spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && enhancedCurrentLocation?.direction === NavigationDirectionTypes.BACKWARD,

            handler: () => {
                console.log('test 3');
                setIsDetailsPageReadyForDisplay(true);
            },
        },

        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () => hasValidLocation
        && isDetailsPagePristineEmpty
        && !areInitialQueryParamsSet
        && !doesDetailsPageRequiresCleanup,
            handler: () => {
                console.log('test 4');
                const payload = { queryParams: initialQueryParams, locationPathname: baseUrlWithoutTabsSlugs };
                dispatchSetQueryParams(payload);
                dispatchFetchEntityDetails(payload);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => {
                console.log('test 5');
                return undefined;
            },
        },
    ];

    const onEntityDetailsDataChangeHandlers = [
    // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && areInitialQueryParamsSet
        && !doesDetailsPageRequiresCleanup
        && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                console.log('test I');
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => hasValidLocation
        && !isDetailsPagePristineEmpty
        && areInitialQueryParamsSet
        && doesDetailsPageRequiresCleanup
        && !entityDetailsData?.isLoadingDetails, // XXX important
            handler: () => {
                console.log('test II');
                setDoesDetailsPageRequiresCleanup(false);
                setIsDetailsPageReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => {
                console.log('test III');
            },
        },
    ];

    useEffect(() => onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [enhancedCurrentLocation]);
    useEffect(() => onEntityDetailsDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [entityDetailsData]);

    return isDetailsPageReadyForDisplay;
}

export default useStandardDetailsPageCachingLogic;
