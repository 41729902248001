import styled from 'styled-components';

//  TODO RWD styles
const StandardFiltersWrapper = styled.div`
  position: relative;
  z-index: 1000;
  display: grid;
  grid-template-columns: minmax(0, max-content)  minmax(0,  max-content) minmax(0, max-content);
  grid-column-gap: 10px;
  align-items: center;
  padding: 10px;
  box-shadow: var(--chakra-shadows-md);
  margin-bottom: 10px;
`;

const SortersWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 200px) minmax(100px, 200px);
  grid-column-gap: 10px;
  align-items: center;
`;


const FiltersWrapper = styled.div<{ columnCount: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columnCount }) => (`${columnCount}`)}, minmax(100px, 300px));
  align-items: center;
  grid-column-gap: 10px;
`;


const FilterWrapper = styled.div`
  display: grid;
  grid-auto-columns: minmax(0, auto);
  grid-column-gap: 10px;
  align-items: center;
`;

const FilterLabel = styled.span`
  white-space: nowrap;
`;


export default {
    StandardFiltersWrapper,
    SortersWrapper,
    FiltersWrapper,
    FilterWrapper,
    FilterLabel,
};
