import { extendTheme, ThemeOverride, withDefaultColorScheme } from '@chakra-ui/react';
import { baseThemeConfig, breakpoints, styles } from './styles';

const overrides:ThemeOverride = {
    baseThemeConfig,
    styles,
    breakpoints,
};

export function createTheme({ configuration }) {
    return extendTheme(
        overrides,
        withDefaultColorScheme({ colorScheme: configuration?.colorScheme }),
    );
}

export default createTheme;
