import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';

import BeneficiariesPage from './BeneficiariesPage.layout';
import { BeneficiariesPageDispatchProps, BeneficiariesPageStateProps } from './BeneficiariesPage.types';


const mapStateToProps = (state: RootState):BeneficiariesPageStateProps => ({ userData: state.currentUser.userData });
const mapDispatchToProps = (dispatch: Dispatch): BeneficiariesPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BeneficiariesPage);
