import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import BusinessUsersList from 'components/common/templates/BusinessUsersList';

import { BusinessUsersPageProps } from './BusinessUsersPage.types';


function BusinessUsersPage({ t }: BusinessUsersPageProps): JSX.Element {

    return (
        <StandardAuthorisedPage
            pageTitle={t('Business users')}
        >
            <BusinessUsersList />
        </StandardAuthorisedPage>
    );
}


export default BusinessUsersPage;
