import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

const StandardContentWrapper = styled(Box)`
  padding: 15px 5px;
  height: 100%;
`;


export default { StandardContentWrapper };
