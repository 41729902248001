import React from 'react';

import StandardTag from 'components/common/atoms/StandardTag';

import { createCardTypeHandlers } from './CardTypeTag.helpers';

function CardTypeTag({ cardType, t }) {
    const normalisedCardType = Number(cardType);
    const tagData = createCardTypeHandlers(normalisedCardType, t);

    return <StandardTag color={tagData.color} value={tagData.text} />;
}


export default CardTypeTag;
