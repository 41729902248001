import React from 'react';

import { PN } from 'models/domain/permissions';
import TheIcon from 'components/common/atoms/TheIcon';
import { MdCreditCard, MdHourglassTop, MdSettings, MdPayments, MdAccountBalanceWallet } from 'react-icons/md';
import StandardLink from 'components/common/atoms/StandardLink';
import { createAccountDetailsPath } from 'config/routes.helpers';
import TransactionsList from 'components/common/templates/TransactionsList';


export const createCardDetailsPageTabsConfig = ({
    fixedCardId,
    accessControl,
    entityDetailsData,
    t,
}) => [
    ...(accessControl.hasPermission(PN.CARDS.READ_BY_ID) ? [
        {
            tabLabel: t('Card details'),
            tabIcon: <TheIcon Icon={MdCreditCard} />,
            children: (
                <>
                    <StandardLink
                        leftIcon={<TheIcon Icon={MdAccountBalanceWallet} />}
                        path={createAccountDetailsPath(entityDetailsData?.entityDetails?.account?.id)}
                        title={t('View linked account')}
                        dataTestId="forgot-password-link"
                    />

                    <pre>
                        {JSON.stringify(entityDetailsData?.entityDetails, null, 2)}
                    </pre>
                </>
            ),
        },
    ] : []),

    {
        tabLabel: t('Limits'),
        tabIcon: <TheIcon Icon={MdHourglassTop} />,
        children: (
            <pre>
                {fixedCardId}
            </pre>
        ),
    },

    {
        tabLabel: t('Settings'),
        tabIcon: <TheIcon Icon={MdSettings} />,
        children: (
            <pre>
                {fixedCardId}
            </pre>
        ),
    },
    {
        tabLabel: t('Transactions'),
        tabIcon: <TheIcon Icon={MdPayments} />,
        children: (
            <TransactionsList cardId={fixedCardId} />
        ),
    },
];


export default { createCardDetailsPageTabsConfig };
