import { withTotalCount } from 'services/http/http.helpers';
import { cleanNilValues } from 'utils/dataMappers';

import { SortDirections, SortDirectionsAPI } from 'models/app/applicationState';
import {
    getCardIdDetails,
    updateCardIdDetails,
    bulkCardOrderApiRequest,
    unlinkCardId,
    linkCardId,
    getCardIdPin,
    getCardIdImage,
    getAllCardsApi,
    processCardsOrderApi,
    processOrderNewCardApiRequest,
    getNotLinkedCardsApi,
    deleteCardId,
    unblockCardPinByCardToken,
    updateCardIdLimits,
    getCardIdStatusHistory,
} from './index';


class CardsService {
    public fetchAllCards(rawQuery) {
        const { sortOrder, search, cardStatus, ...rest } = rawQuery;
        const query = {
            ...rest,
            cardStatus: cardStatus?.length > 0 ? cardStatus : undefined,
            ...(search && search?.length > 0 ? { search } : {}),
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };

        return getAllCardsApi({ params: { ...cleanNilValues(query) } });
    }

    public fetchCardDetails(cardId) {
        return getCardIdDetails(cardId);
    }

    public updateCardDetails(cardId, requestPayload) {
        return updateCardIdDetails(cardId, requestPayload);
    }

    public updateCardLimits(cardId, requestPayload) {
        return updateCardIdLimits(cardId, requestPayload);
    }

    public unlinkCard(cardId) {
        return unlinkCardId(cardId);
    }

    public linkCard(cardId, requestPayload) {
        return linkCardId(cardId, requestPayload);
    }

    public fetchCardProducts(cardId, requestPayload) {
        return linkCardId(cardId, requestPayload);
    }

    public getCardPin(cardId) {
        return getCardIdPin(cardId);
    }

    public getCardImage(cardId) {
        return getCardIdImage(cardId);
    }

    public unblockCardPin(cardToken) {
        const requestPayload = { token: cardToken };
        return unblockCardPinByCardToken(requestPayload);
    }

    public processCardsOrder(rawRequestPayload) {
        const { member_id, company_id, business_user_id, ...rest } = rawRequestPayload;

        const requestPayload = {
            ...rest,
            ...(member_id > 0 && { member_id }),
            ...(company_id > 0 && { company_id }),
            ...(business_user_id > 0 && { business_user_id }),
        };
        // XXX usePluralTargetValue - should be temporal and eventually removed (BE issue 'members' vs 'member' on target field)
        return processCardsOrderApi(requestPayload, { params: { usePluralTargetValue: true } });
    }

    public processOrderNewCard(requestPayload) {
        return processOrderNewCardApiRequest(requestPayload);
    }

    public getNotLinkedCards(rawQuery) {
        const { companyId, clientId, memberId, search, ...rest } = rawQuery;

        const query = {
            ...rest,
            ...withTotalCount,
            ...(companyId > 0 && { companyId }),
            ...(clientId > 0 && { clientId }),
            ...(memberId > 0 && { memberId }),
            ...(search && search.length > 0 && { search }),
        };

        return getNotLinkedCardsApi({ params: query });
    }

    public deleteCard(cardId) {
        return deleteCardId(cardId);
    }

    public getCardStatusHistory(rawQueryParams) {
        const { cardId, sortOrder, ...rest } = rawQueryParams;
        const queryParams = {
            ...rest,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };
        return getCardIdStatusHistory(cardId, { params: { ...cleanNilValues(queryParams) } });
    }
}

export default CardsService;

