import React from 'react';
import { TFunction } from 'i18next';

import { Beneficiary } from 'models/domain/benefciaries';
import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import { AccessControlContent } from 'models/domain/accessControl';
import { CountryIsoAlpha3Code, CurrencyIsoCode, QueryParams } from 'models/app/common';
import StandardValue from 'components/common/molecules/StandardValue';
import { PN } from 'models/domain/permissions';
import { createBeneficiaryDetailsPath } from 'config/routes.helpers';
import ViewButton from 'components/common/atoms/ViewButton';
import FlagAndValue from 'components/common/molecules/FlagAndValue';
import { StandardValueType } from 'models/app/standardValues';
import { CountryIdType } from 'models/domain/countries';
import CountryRenderer from 'components/common/molecules/CountryRenderer';


export const createBeneficiariesListColumns = (
    {
        accessControl,
        queryParams,
        t,
    }: {
        accessControl: AccessControlContent,
        queryParams: QueryParams
        t: TFunction
    },
): TableColumnConfiguration<Beneficiary>[] => [
    {
        title: 'ID',
        dataIndex: 'id',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Name',
        dataIndex: '',
        align: TableColumnAlign.right,
        render: (value, record) => (
            <StandardValue
                value={record.beneficiaryType === 'COMPANY'
                    ? record.companyName
                    : `${record.firstName} ${record.lastName}`}
            />
        ),
    },
    {
        title: 'Beneficiary Type',
        dataIndex: 'beneficiaryType',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        align: TableColumnAlign.right,
        render: (value) => <FlagAndValue currencyCode={value as CurrencyIsoCode} text={value} tooltipPlacement="top-start" showTooltip />,
    },
    {
        title: 'Country',
        dataIndex: 'country',
        align: TableColumnAlign.right,
        render: (text) => <CountryRenderer propertyName={CountryIdType.iso31663} value={text as CountryIsoAlpha3Code} showTooltip />,
    },
    {
        title: t('beneficiaryFields.identificationCategory.label'),
        dataIndex: 'accountIdentifications',
        align: TableColumnAlign.right,
        render: (_, record) => <StandardValue value={record.accountIdentifications?.[0]?.identificationCategory} />,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.DATE} />,
    },
    {
        title: 'Updated at',
        dataIndex: 'updatedAt',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.DATE} />,
    },


    ...(accessControl.hasPermission(PN.BENEFICIARIES.READ_BY_ID)
        ? [
            {
                title: t('common:actions'),
                dataIndex: '',
                align: TableColumnAlign.center,
                render: (_, item) => (
                    <>
                        <ViewButton
                            path={createBeneficiaryDetailsPath(item?.id)}
                            title={t('common:viewDetails')}
                            useLinkVersion
                        />
                    </>

                ),
            },
        ]
        : []
    ),
];

export default { createBeneficiariesListColumns };
