import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { SortDirections } from 'models/app/applicationState';
import { withTotalCount } from 'services/http/http.helpers';
import { SET_QUERY_PARAMS } from 'store/application/action.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    CLEAR_TRANSACTIONS_LIST,
    FETCH_TRANSACTIONS_LIST, FETCH_TRANSACTIONS_LIST_FAILURE,
    FETCH_TRANSACTIONS_LIST_SUCCESS,
} from './actions.types';

export const transactionsReducerName = 'transactions';
export const transactionsListsPerLocationStoreKeyName = 'transactionsListsPerLocation';

export const defaultTransactionsListQueryParams = {
    limit: 7,
    offset: 0,
    type: undefined,
    currency: undefined,
    companyId: undefined,
    businessUserId: undefined,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

const initialState = {
    [transactionsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }

    downloadPdfStatement: { },
};

const handlers = {
    [FETCH_TRANSACTIONS_LIST]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[transactionsListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_TRANSACTIONS_LIST_SUCCESS]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[transactionsListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_TRANSACTIONS_LIST_FAILURE]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[transactionsListsPerLocationStoreKeyName],
            action,

        });
    },
    [CLEAR_TRANSACTIONS_LIST]: (state, action) => {
        state[transactionsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[transactionsListsPerLocationStoreKeyName],
            action,
        });
    },
    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === transactionsReducerName
            && action.payload?.fieldName === transactionsListsPerLocationStoreKeyName
        ) {
            state[transactionsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams(
                {
                    listsState: state?.[transactionsListsPerLocationStoreKeyName],
                    action,
                },
            );
        }
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
