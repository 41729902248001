import React, { useMemo } from 'react';
import { MdAdd } from 'react-icons/md';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import AccountsList from 'components/common/templates/AccountsList';
import { PN } from 'models/domain/permissions';
import TheIcon from 'components/common/atoms/TheIcon';
import { HTMLButtonType } from 'models/app/applicationState';
import { addAccountRootRoutePath } from 'config/routes';

import { AccountsPageProps } from './AccountsPage.types';

function AccountsPage({
    t,
    enhancedCurrentLocation,
    accessControl,
    dispatchRequestNavigation,
}: AccountsPageProps): JSX.Element {


    const additionalButtons = useMemo(
        () => ([
            ...(accessControl.hasPermission(PN.ACCOUNTS.CREATE) ? [{
                id: 'add-account',
                type: HTMLButtonType.button,
                leftIcon: <TheIcon Icon={MdAdd} />,
                label: 'Add account',
                onClick: () => dispatchRequestNavigation({
                    locationPathname: addAccountRootRoutePath,
                    meta: { state: { entryLocationPathname: enhancedCurrentLocation.pathname } },
                }),
            },
            ] : []),
        ]),
        [accessControl],
    );


    return (
        <StandardAuthorisedPage
            pageTitle={t('Accounts')}
            additionalButtons={additionalButtons}
        >
            <AccountsList />
        </StandardAuthorisedPage>
    );
}


export default AccountsPage;
