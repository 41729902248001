import * as yup from 'yup';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import { SetPasswordFormFields } from './SetPasswordPage.types';

export const createSetPasswordFormFieldsConfiguration = ({ t }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.PASSWORD,
        id: 'password',
        name: 'password',
        labelText: t('formsElements.passwordNew.label'),
        placeholderText: t('formsElements.passwordNew.placeholder'),
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.PASSWORD,
        id: 'confirmPassword',
        name: 'confirmPassword',
        labelText: t('formsElements.passwordNewConfirm.label'),
        placeholderText: t('formsElements.passwordNewConfirm.placeholder'),
        optionalConfiguration: { isRequired: true },
    },
]);

export const createSetPasswordFormSchema = ({ t }) => {
    return yup.object<SetPasswordFormFields>({
        password: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .max(100, t('common:validationMsg.maximumLength', { maxCount: 100 }))
            .matches(
                /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
                'Must contain at least: one letter, one number and one special case character',
            ),
        confirmPassword: yup.string().oneOf([yup.ref('password')], "Passwords don't match").required('Confirm Password is required'),
    }).required();
};


export default { createSetPasswordFormFieldsConfiguration };
