import { connect } from 'react-redux';
import { ComponentType } from 'react';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import { initialiseLogin } from 'store/authorisation/actions';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';

import LoginPage from './LoginPage.layout';
import { LoginPageDispatchProps, LoginPageStateProps } from './LoginPage.types';


const mapStateToProps = (state: RootState): LoginPageStateProps => ({
    // fieldLoginAttempts: state.authorisation.fieldLoginAttempts,
    loginFormRememberedUserEmail: state.authorisation.loginFormRememberedUserEmail,
    loginFormError: state.authorisation.loginFormError,
    isLoadingLoginForm: state.authorisation.isLoadingLoginForm,
});

const mapDispatchToProps = (dispatch: Dispatch): LoginPageDispatchProps => ({ dispatchInitialiseLogin: (formData) => dispatch(initialiseLogin(formData)) });


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(LoginPage);
