import React, { useMemo } from 'react';

import { Button, ButtonGroup, Stack } from '@chakra-ui/react';
import NavButton from 'components/common/molecules/NavButton';
import { MainMenuProps } from './MainMenu.types';
import { createMainMenuConfig } from './MainMenu.helpers';


function MainMenu({
    isDesktop,
    dispatchRequestNavigation,
    enhancedCurrentLocation,
}: MainMenuProps): JSX.Element {
    const currentPathname = enhancedCurrentLocation?.pathname;

    const Container = useMemo(() => ({
        Component: isDesktop ? ButtonGroup : Stack,
        props: isDesktop ? {
            variant: 'ghost',
            spacing: 2,
        } : { spacing: 1 },
    }), [isDesktop]);

    const ButtonContainer = useMemo(() =>
        ({ Component: isDesktop ? Button : NavButton }), [isDesktop]);

    return (
        <Container.Component {...Container.props}>
            {createMainMenuConfig().map((menuItemConfiguration, index) => {
                return (
                    <ButtonContainer.Component
                        key={index + menuItemConfiguration.pathname}
                        onClick={() => dispatchRequestNavigation({ locationPathname: menuItemConfiguration.pathname })}
                        variant={currentPathname === menuItemConfiguration.pathname ? 'solid' : 'ghost'}
                        leftIcon={menuItemConfiguration.icon}
                    >
                        {menuItemConfiguration.text}
                    </ButtonContainer.Component>
                );
            })}
        </Container.Component>
    );
}

export default MainMenu;
