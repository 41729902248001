import { createAction } from '@reduxjs/toolkit';
import {
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_SUCCESS,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, FETCH_ACCOUNT_DETAILS, FETCH_ACCOUNT_DETAILS_FAILURE, FETCH_ACCOUNT_DETAILS_SUCCESS,
} from './actions.types';

export const fetchAccountDetails = createAction(FETCH_ACCOUNT_DETAILS, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchAccountDetailsSuccess = createAction(FETCH_ACCOUNT_DETAILS_SUCCESS, ({ responsePayload, locationPathname }) => ({
    payload: {
        responsePayload,
        locationPathname,
    },
}));
export const fetchAccountDetailsFailure = createAction(FETCH_ACCOUNT_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearAccountDetails = createAction(CLEAR_ACCOUNT_DETAILS, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const deleteAccount = createAction(DELETE_ACCOUNT, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS, ({ responsePayload }) => ({ payload: responsePayload }));
export const deleteAccountFailure = createAction(DELETE_ACCOUNT_FAILURE);

export const downloadAccountStatementPdf = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF, ({ queryParams }) => ({ payload: queryParams }));
export const downloadAccountStatementPdfSuccess = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, ({ queryParams }) => ({ payload: queryParams }));
export const downloadAccountStatementPdfFailure = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE, ({ queryParams }) => ({ payload: queryParams }));

