import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { debugMode } from 'config/environment';
// eslint-disable-next-line no-restricted-imports
import i18next from 'i18next'; // legit use as epics middleware dependency
import { createBrowserHistory } from 'history';
import { ColorModeScript } from '@chakra-ui/react';

import initializeSentry from 'config/sentry';
import { createStore } from 'store/createStore';
import { initApplication } from 'store/application/actions';
import { createHttpService, HttpService } from 'services/http';
import AuthorisationService from 'services/authorisation/AuthorisationService';
import BeneficiariesService from 'services/beneficiaries/BeneficiariesService';
import BusinessUsersService from 'services/businessUsers/BusinessUsersService';
import LocationService from 'services/location/LocationService';
import FileService from 'services/FileService';
import CountriesService from 'services/countries/CountriesService';
import CurrenciesService from 'services/currencies/CurrenciesService';
import AccountsService from 'services/accounts/AccountsService';
import CardsService from 'services/cards/CardsService';
import CompaniesService from 'services/companies/CompaniesService';
import TransactionsService from 'services/transactions/TransactionsService';
import Main from 'components/App/Main';
import { Dependencies } from 'models/app/store';

import CustomRouter from './components/App/CustomRouter.layout';
import { baseThemeConfig } from './config/theme/styles';


const wrapper = document.getElementById('root') as HTMLDivElement;
const root = createRoot(wrapper!);

const http = createHttpService();


const history = createBrowserHistory({ window });
const location = new LocationService(history, window.location);
// error tracker
initializeSentry(history);
// redux-observables (RxJS) middleware dependencies
const dependencies: Dependencies = {
    document,
    history,
    i18n: i18next,
    http,
    location,

    authorisation: new AuthorisationService(),
    accounts: new AccountsService(),
    cards: new CardsService(),
    companies: new CompaniesService(),
    countries: new CountriesService(),
    currencies: new CurrenciesService(),
    beneficiaries: new BeneficiariesService(),
    businessUsers: new BusinessUsersService(),
    file: new FileService(),
    transactions: new TransactionsService(),

};
// redux store initialisation
const store = createStore(dependencies);

export type StoreDispatch = typeof store.dispatch;

HttpService.configure(store.dispatch);
store.dispatch(initApplication());

const main = debugMode
    ? <React.StrictMode><Main /></React.StrictMode>
    : <Main />;


root.render(
    <>
        <Provider store={store}>
            <CustomRouter history={history}>
                {main}
            </CustomRouter>
        </Provider>
        <ColorModeScript initialColorMode={baseThemeConfig.initialColorMode} />
    </>,

);
