import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { setNewPassword } from 'store/authorisation/actions';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';

import SetPasswordPage from './SetPasswordPage.layout';
import { SetPasswordPageDispatchProps, SetPasswordPageStateProps } from './SetPasswordPage.types';


const mapStateToProps = (state: RootState): SetPasswordPageStateProps => ({ isLoadingSetNewPassword: state.authorisation.isLoadingSetNewPassword });

const mapDispatchToProps = (dispatch: Dispatch): SetPasswordPageDispatchProps => ({ dispatchSetNewPassword: (requestPayload) => dispatch(setNewPassword(requestPayload)) });


const decorate = compose<ComponentType>(
    withTranslation('unauthorised'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(SetPasswordPage);
