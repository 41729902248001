import React from 'react';
import { Skeleton } from '@chakra-ui/react';


import StandardSelect from 'components/common/molecules/StandardSelect';
import { FormInputTypes } from 'models/app/formContols';

import Styled from '../StandardFilters.styled';

function FilterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    isListReady,
    t,
}): JSX.Element {

    // TODO add handlers and render also multi-selects and checkboxes
    return filtersConfiguration.filtersConfig && filtersConfiguration.filtersConfig?.length > 0
        ? (
            <Styled.FiltersWrapper
                columnCount={filtersConfiguration.filtersConfig
                    ? filtersConfiguration.filtersConfig.length
                    : 0}
            >
                { (filtersConfiguration.filtersConfig || []).map((filter) => {
                    return isListReady ? (
                        <Styled.FilterWrapper key={filter.queryParamName}>
                            <Styled.FilterLabel>
                                {t(`${filter.label} filter:`)}
                            </Styled.FilterLabel>

                            <StandardSelect
                                fieldConfiguration={{
                                    placeholderText: filter.placeholderText,
                                    id: filter.queryParamName,
                                    name: filter.queryParamName,
                                    fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                    labelText: '',
                                    optionalConfiguration: {
                                        options: filter.filterOptions,
                                        isClearable: true,
                                        isMultiSelect: filter?.isMultiSelect,
                                    },
                                    value: queryParams?.[filter.queryParamName],
                                    onChange: (value) => handleQueryParamsChange({
                                        fieldName: filter.queryParamName,
                                        value,
                                    }),
                                }}
                            />
                        </Styled.FilterWrapper>
                    ) : <Skeleton height="50px" width="200px" key={filter.queryParamName} />;
                })}
            </Styled.FiltersWrapper>
        )
        : <></>;
}

export default FilterFields;
