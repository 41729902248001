import { capitalizeFirstLetter } from 'utils/string-tools';
import { TransactionStatus } from 'models/domain/transactions';
import { TagData } from 'models/app/tag';

export const mapTransactionStatusToColorAndLabel = ({ transactionStatus, t }): TagData => {
    const normalisedStatus = capitalizeFirstLetter(transactionStatus); // FIXING API output

    const handlers = [
        {
            predicate: () => normalisedStatus === TransactionStatus.Pending,
            handler: () => ({
                color: 'cyan',
                label: t('transactions:transactionStatuses.pending.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Rejected,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.rejected.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Approved,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.approved.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Completed,
            handler: () => ({
                color: 'green',
                label: t('transactions:transactionStatuses.completed.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Reversed,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.reversed.text'),
            }),
        },
        {
            predicate: () => normalisedStatus === TransactionStatus.Failed,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionStatuses.failed.text'),
            }),
        },
        {
            predicate: () => true,
            handler: () => ({
                label: t('transactions:transactionStatuses.unknown'),
                color: 'gray',
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
