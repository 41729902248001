import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { navigationReducerName } from 'store/navigation/reducer';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { Currency } from 'models/domain/currencies';
import { clearCardsList, fetchCardsList } from 'store/cards/actions';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from 'store/cards/reducer';
import { Card } from 'models/domain/cards';

import CardsList from './CardsList.layout';
import { CardsListDispatchProps, CardsListOwnProps, CardsListStateProps } from './CardsList.types';

const mapStateToProps = (state: RootState): CardsListStateProps => ({
    currenciesListData: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<Card>({
        state,
        reducerName: cardsReducerName,
        listsByLocationStoreKeyName: cardsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): CardsListDispatchProps => ({
    dispatchFetchCardsList: ({ queryParams, locationPathname }) => dispatch(fetchCardsList({ queryParams, locationPathname })),
    dispatchClearCardsList: ({ locationPathname }) => dispatch(clearCardsList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: cardsReducerName,
        fieldName: cardsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

});

const decorate = compose<ComponentType<CardsListOwnProps>>(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardsList);
