import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';


import { Epic } from 'models/meta/epic';
import { showToast } from 'store/application/actions';
import { ToastType } from 'models/app/toast';

import {
    addBusinessUserFailure,
    addBusinessUserSuccess, fetchBusinessUsersList, fetchBusinessUsersListFailure, fetchBusinessUsersListSuccess,
} from './actions';
import { ADD_BUSINESS_USER, FETCH_BUSINESS_USERS_LIST } from './action.types';
import { businessUsersReducerName, businessUsersListsPerLocationStoreKeyName } from './reducer';
import { addCompanyIdToQueryParams } from '../common.helpers';


export const onFetchBusinessUsersList: Epic = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_BUSINESS_USERS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(companies.getCompanyEmployees(addCompanyIdToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => of(fetchBusinessUsersListSuccess({
            items: response.data,
            totalCount: response?.count,
            locationPathname,
        }))),
        catchError(() => of(fetchBusinessUsersListFailure({ locationPathname }))),
    )),
);


export const onAddBusinessUser: Epic = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(ADD_BUSINESS_USER),
    switchMap(({ payload }) => from(companies.createEmployeeForCompany(payload.formData))
        .pipe(
            switchMap((response) => {
                const currentEmployeesListQueryParams = state$.value[businessUsersReducerName][businessUsersListsPerLocationStoreKeyName]?.[payload.locationPathname]?.queryParams;
                return of(
                    addBusinessUserSuccess({ responsePayload: response.data, locationPathname: payload.locationPathname }),
                    showToast({ type: ToastType.success, message: i18n.t('companies:actionMessages.addEmployeeSuccess') }),
                    fetchBusinessUsersList({ queryParams: currentEmployeesListQueryParams, locationPathname: payload.locationPathname }),
                    // hideModal(),
                );
            }),
            catchError(() => of(
                addBusinessUserFailure(),
            )),
        )),
);


export default [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];
