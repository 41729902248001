import * as React from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Thead, Tr, Th } from '@chakra-ui/react';

import TheIcon from 'components/common/atoms/TheIcon';
import { SortDirections } from 'models/app/applicationState';

import { StandardDataTableHeadOwnProps } from './StandardDataListTableHead.types';

import Styled from './StandardDataListTableHead.styled';

function StandardDataTableHead<ItemDefinition>({
    columnsConfiguration,
    queryParams,
}: StandardDataTableHeadOwnProps<ItemDefinition>) {


    return (
        <Thead>
            <Tr>
                {columnsConfiguration.map((column) => (
                    <Th
                        key={column.dataIndex}
                    >
                        {column.title}

                        <Styled.StandardDataTableHeaderWrapper>
                            {column.isSortable && queryParams.sortBy === column.dataIndex ? (
                                <Styled.StandardDataTableHeaderSorterWrapper>
                                    <TheIcon
                                        Icon={queryParams.sortOrder === SortDirections.DESCEND ? MdExpandLess : MdExpandMore}
                                        aria-label={`sorted ${queryParams.sortOrder === SortDirections.DESCEND ? 'descending' : 'ascending'}`}
                                    />
                                </Styled.StandardDataTableHeaderSorterWrapper>
                            ) : <></>}

                        </Styled.StandardDataTableHeaderWrapper>
                    </Th>
                ))}
            </Tr>
        </Thead>
    );
}


export default StandardDataTableHead;
