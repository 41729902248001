import {
    CountryIsoAlpha2Code, CountryIsoAlpha3Code, CountryIsoNumericCode, CurrencyIsoCode, DateFullIsoString, NumericId,
} from '../app/common';

export interface Country {
    callingCode: string, // "354"
    capital: string, // "Reykjavik"
    citizenship: string, // "Icelander"
    countryCode: CountryIsoNumericCode, // "352"
    createdAt: DateFullIsoString,
    currency: string,
    currencyCode: CurrencyIsoCode, // "ISK"
    currencySubUnit: string, // ""
    currencySymbol: string,
    deletedAt: DateFullIsoString,
    document: number, // 0
    eea: number, // 0
    enabled: number, // 0
    flag: string, // "IS.png"
    fullName: string, // "Republic of Iceland"
    id: NumericId, // 352 XXX country_code duplicates this
    iso31662: CountryIsoAlpha2Code, // "IS"
    iso31663: CountryIsoAlpha3Code, // "ISL"
    name: string, // "Iceland"
    regionCode: string, // "150"
    riskClients: number, // 2
    riskConsumer: number, // 2
    subRegionCode: string, // 2 "154"
    updatedAt: DateFullIsoString
}

export enum CountryIdType {
    id = 'id',
    iso31663 = 'iso31663',
    iso31662 = 'iso31662',
}
