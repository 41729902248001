import { combineReducers } from '@reduxjs/toolkit';

import accountReducer, { accountReducerName } from 'store/account/reducer';
import accountsReducer, { accountsReducerName } from 'store/accounts/reducer';
import applicationReducer, { applicationReducerName } from 'store/application/reducer';
import authorisationReducer, { authorisationReducerName } from 'store/authorisation/reducer';
import businessUserReducer, { businessUserReducerName } from 'store/business-user/reducer';
import businessUsersReducer, { businessUsersReducerName } from 'store/business-users/reducer';
import beneficiariesReducer, { beneficiariesReducerName } from 'store/beneficiaries/reducer';
import beneficiaryReducer, { beneficiaryReducerName } from 'store/beneficiary/reducer';
import cardReducer, { cardReducerName } from 'store/card/reducer';
import cardsReducer, { cardsReducerName } from 'store/cards/reducer';
import companyReducer, { companyReducerName } from 'store/company/reducer';
import countriesReducer, { countriesReducerName } from 'store/countries/reducer';
import currencyReducer, { currenciesReducerName } from 'store/currencies/reducer';
import currentUserReducer, { currentUserReducerName } from 'store/current-user/reducer';
import navigationReducer, { navigationReducerName } from 'store/navigation/reducer';
import transactionsReducer, { transactionsReducerName } from 'store/transactions/reducer';
import alertReducer, { alertReducerName } from './alert/reducer';

const rootReducer = combineReducers({
    // XXX very, very important reducers
    [applicationReducerName]: applicationReducer,
    [currentUserReducerName]: currentUserReducer,
    [navigationReducerName]: navigationReducer,
    [authorisationReducerName]: authorisationReducer,

    // screen/entity/controller specific reducers
    [accountReducerName]: accountReducer,
    [accountsReducerName]: accountsReducer,
    [alertReducerName]: alertReducer,
    [cardReducerName]: cardReducer,
    [cardsReducerName]: cardsReducer,
    [companyReducerName]: companyReducer,
    [businessUserReducerName]: businessUserReducer,
    [businessUsersReducerName]: businessUsersReducer,
    [beneficiariesReducerName]: beneficiariesReducer,
    [beneficiaryReducerName]: beneficiaryReducer,
    [countriesReducerName]: countriesReducer,
    [currenciesReducerName]: currencyReducer,
    [transactionsReducerName]: transactionsReducer,
});


export type RootState = ReturnType<typeof rootReducer>;


export { rootReducer };
