import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import {
    clearCardDetails,
    fetchCardDetails,
} from 'store/card/actions';

import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';
import { cardDetailsPerLocationStoreKeyName, cardReducerName } from 'store/card/reducer';
import { RootState } from 'store/rootReducer';


import CardDetailsPage from './CardDetailsPage.layout';
import { CardDetailsPageDispatchProps, CardDetailsPageStateProps } from './CardDetailsPage.types';


const mapStateToProps = (state: RootState): CardDetailsPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

    isLoadingUpdateCard: state[cardReducerName].isLoadingUpdateCard,
});

const mapDispatchToProps = (dispatch): CardDetailsPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),

    dispatchFetchCardDetails: ({ queryParams, locationPathname }) => dispatch(fetchCardDetails({ queryParams, locationPathname })),
    dispatchClearCardDetails: ({ locationPathname }) => dispatch(clearCardDetails({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: cardReducerName,
        fieldName: cardDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose<ComponentType>(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(CardDetailsPage);
