import React from 'react';
import { motion } from 'framer-motion';
import {
    HStack,
    Skeleton, Tab, TabList, TabPanels,
} from '@chakra-ui/react';

import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import StandardContentWrapper from 'components/common/atoms//StandardContentWrapper';

import { StandardTabsProps } from './StandardTabs.types';
import Styled from './StandardTabs.styled';

const animationForward = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
};

function StandardTabs({ tabsConfig }: StandardTabsProps): JSX.Element {

    return tabsConfig?.length > 0
        ? (
            <Styled.StyledStandardTabs isLazy>
                <TabList>
                    {tabsConfig.map((tabConfig) => (
                        <Tab
                            key={tabConfig.tabLabel}
                        >
                            <HStack>
                                {tabConfig.tabIcon}
                                <span>{tabConfig.tabLabel}</span>
                            </HStack>

                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {tabsConfig.map((tabConfig) => (
                        <Styled.StyledStandardTabPanel key={tabConfig.tabLabel}>
                            <motion.div
                                variants={animationForward}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                style={{ height: '100%' }}
                                transition={{ duration: 0.45 }}
                            >
                                <ScrollableContainer>
                                    <StandardContentWrapper>
                                        {tabConfig.children}
                                    </StandardContentWrapper>
                                </ScrollableContainer>
                            </motion.div>
                        </Styled.StyledStandardTabPanel>
                    ))}
                </TabPanels>
            </Styled.StyledStandardTabs>
        ) : <Skeleton />;
}

export default StandardTabs;
