import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    Box,
    Button, HStack, VStack,
} from '@chakra-ui/react';
import { MdSend } from 'react-icons/md';
import FormField from 'components/common/molecules/FormFieldWrapper';
import TheIcon from 'components/common/atoms/TheIcon';

import { yupResolver } from '@hookform/resolvers/yup';
import { StandardFormProps } from './StandardForm.types';

function StandardForm({
    formName,
    initialFormState = {},
    formError = '',
    submitButtonText = 'Submit',
    isLoading,
    createFieldsConfiguration,
    dispatchOnSubmitAction,
    validationSchema,
    t,
}: StandardFormProps): JSX.Element {
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useForm<typeof initialFormState>({
        mode: 'all',
        reValidateMode: 'onChange',
        defaultValues: initialFormState,
        resolver: yupResolver(validationSchema),
    });


    const onSubmit = (data) => {
        dispatchOnSubmitAction({ ...data });
    };

    const formFieldsConfiguration = useMemo(
        () => createFieldsConfiguration({ t }),
        [t],
    );


    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            name={formName}
        >
            <VStack
                spacing={10}
                align="stretch"
            >
                {formFieldsConfiguration.map((fieldConfiguration) => (
                    <Box key={fieldConfiguration.name}>
                        <FormField
                            errors={errors}
                            control={control}
                            fieldConfiguration={fieldConfiguration}
                        />
                    </Box>
                ))}


                {formError || ''}

                <HStack justify={'flex-end'}>
                    <Button
                        variant="outline"
                        type="submit"
                        isDisabled={isLoading}
                        isLoading={isLoading || !!isSubmitting}
                        rightIcon={<TheIcon Icon={MdSend} />}
                    >
                        {submitButtonText}
                    </Button>
                </HStack>
            </VStack>
        </form>
    );
}


export default StandardForm;
