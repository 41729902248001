import svgCountries from './svgCountries';

const svgCurrencies = {
    aed: svgCountries.ae,
    afn: svgCountries.af,
    all: svgCountries.al,
    amd: svgCountries.am,
    ang: svgCountries.nl,
    aoa: svgCountries.ao,
    ars: svgCountries.ar,
    aud: svgCountries.au,
    awg: svgCountries.aw,
    azn: svgCountries.az,

    bam: svgCountries.ba,
    bbd: svgCountries.bb,
    bdt: svgCountries.bd,
    bgn: svgCountries.bg,
    bhd: svgCountries.bh,
    bif: svgCountries.bi,
    bmd: svgCountries.bm,
    bnd: svgCountries.bn,
    bob: svgCountries.bo,
    brl: svgCountries.br,
    bsd: svgCountries.bs,
    btn: svgCountries.bt,
    bwp: svgCountries.bw,
    byn: svgCountries.by,
    bzd: svgCountries.bz,

    cad: svgCountries.ca,
    cdf: svgCountries.cd,
    chf: svgCountries.ch,
    clp: svgCountries.cl,
    cny: svgCountries.cn,
    cop: svgCountries.co,
    crc: svgCountries.cr,
    cup: svgCountries.cu,
    cuc: svgCountries.cu,
    czk: svgCountries.cz,
    cve: svgCountries.cv,

    djf: svgCountries.dj,
    dkk: svgCountries.dk,
    dop: svgCountries.do,
    dzd: svgCountries.dz,

    egp: svgCountries.eg,
    ern: svgCountries.er,
    etb: svgCountries.et,
    eur: svgCountries.eu,

    fjd: svgCountries.fj,
    fkp: svgCountries.fk,

    gbp: svgCountries.gb,
    gel: svgCountries.ge,
    ghs: svgCountries.gh,
    gip: svgCountries.gi,
    gmd: svgCountries.gm,
    gnf: svgCountries.gn,
    gtq: svgCountries.gt,
    gyd: svgCountries.gy,

    hkd: svgCountries.hk,
    hnl: svgCountries.hn,
    hrk: svgCountries.hr,
    htg: svgCountries.ht,
    huf: svgCountries.hu,

    idr: svgCountries.id,
    ils: svgCountries.il,
    inr: svgCountries.in,
    iqd: svgCountries.iq,
    irr: svgCountries.ir,
    isk: svgCountries.is,

    jmd: svgCountries.jm,
    jod: svgCountries.jo,
    jpy: svgCountries.jp,

    kes: svgCountries.ke,
    kgs: svgCountries.kg,
    khr: svgCountries.kh,
    kmf: svgCountries.km,
    kpw: svgCountries.kp,
    krw: svgCountries.kr,
    kwd: svgCountries.kw,
    kyd: svgCountries.ky,
    kzt: svgCountries.kz,

    lak: svgCountries.la,
    lbp: svgCountries.lb,
    lkr: svgCountries.lk,
    lrd: svgCountries.lr,
    lsl: svgCountries.ls,
    lyd: svgCountries.ly,

    mad: svgCountries.ma,
    mdl: svgCountries.md,
    mga: svgCountries.mg,
    mkd: svgCountries.mk,
    mmk: svgCountries.mm,
    mnt: svgCountries.mn,
    mop: svgCountries.mo,
    mru: svgCountries.mr,
    mur: svgCountries.mu,
    mvr: svgCountries.mv,
    mwk: svgCountries.mw,
    mxn: svgCountries.mx,
    myr: svgCountries.my,
    mzn: svgCountries.mz,

    nad: svgCountries.na,
    ngn: svgCountries.ng,
    nio: svgCountries.ni,
    nok: svgCountries.no,
    npr: svgCountries.np,
    nzd: svgCountries.nz,

    omr: svgCountries.om,

    pab: svgCountries.pa,
    pen: svgCountries.pe,
    pgk: svgCountries.pg,
    php: svgCountries.ph,
    pkr: svgCountries.pk,
    pln: svgCountries.pl,
    pyg: svgCountries.py,

    qar: svgCountries.qa,

    ron: svgCountries.ro,
    rsd: svgCountries.rs,
    rub: svgCountries.ru,
    rwf: svgCountries.rw,

    sar: svgCountries.sa,
    sbd: svgCountries.sb,
    scr: svgCountries.sc,
    sdg: svgCountries.sd,
    sek: svgCountries.se,
    sgd: svgCountries.sg,
    shp: svgCountries.gb, // XXX
    sll: svgCountries.sl,
    sos: svgCountries.so,
    srd: svgCountries.sr,
    ssp: svgCountries.ss,
    stn: svgCountries.st,
    syp: svgCountries.sy,
    szl: svgCountries.sz,

    thb: svgCountries.th,
    tjs: svgCountries.tj,
    tmt: svgCountries.tm,
    tnd: svgCountries.tn,
    top: svgCountries.to,
    try: svgCountries.tr,
    ttd: svgCountries.tt,
    twd: svgCountries.tw,
    tzs: svgCountries.tz,

    uah: svgCountries.ua,
    ugx: svgCountries.ug,
    usd: svgCountries.us,
    uyu: svgCountries.uy,
    uzs: svgCountries.uz,

    ves: svgCountries.ve,
    vnd: svgCountries.vn,
    vuv: svgCountries.vu,

    wst: svgCountries.ws,
    // xaf: svgCountries.xa,
    // xcd: svgCountries.xc,
    // xof: svgCountries.xo,
    // xpf: svgCountries.xp,

    yer: svgCountries.ye,
    zar: svgCountries.za,
    zmw: svgCountries.zm,

};


export default svgCurrencies;
