import { createReducer } from '@reduxjs/toolkit';

import { standardPageSize } from 'config/config';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';
import { SortDirections } from 'models/app/applicationState';
import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    ADD_BUSINESS_USER,
    ADD_BUSINESS_USER_FAILURE,
    ADD_BUSINESS_USER_SUCCESS, CLEAR_BUSINESS_USERS_LIST,
    FETCH_BUSINESS_USERS_LIST,
    FETCH_BUSINESS_USERS_LIST_FAILURE,
    FETCH_BUSINESS_USERS_LIST_SUCCESS,
} from './action.types';

export const businessUsersReducerName = 'businessUsers';
export const businessUsersListsPerLocationStoreKeyName = 'businessUsersListsPerLocation';


export const defaultBusinessUsersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};

const initialListState = {
    [businessUsersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddBusinessUser: false,
};


const initialState = { ...initialListState };

const handlers = {
    [ADD_BUSINESS_USER]: (state, action) => {
        state.isLoadingAddBusinessUser = true;
    },
    [ADD_BUSINESS_USER_SUCCESS]: (state, action) => {
        state.isLoadingAddBusinessUser = false;
    },
    [ADD_BUSINESS_USER_FAILURE]: (state) => {
        state.isLoadingAddBusinessUser = false;
    },

    [FETCH_BUSINESS_USERS_LIST]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardLists(
            {
                listsState: state[businessUsersListsPerLocationStoreKeyName],
                action,
            },
        );
    },
    [FETCH_BUSINESS_USERS_LIST_SUCCESS]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(
            {
                listsState: state[businessUsersListsPerLocationStoreKeyName],
                action,
            },
        );
    },
    [FETCH_BUSINESS_USERS_LIST_FAILURE]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[businessUsersListsPerLocationStoreKeyName],
            action,
        });
    },
    [CLEAR_BUSINESS_USERS_LIST]: (state, action) => {
        state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[businessUsersListsPerLocationStoreKeyName],
            action,
        });
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === businessUsersReducerName
      && action.payload?.fieldName === businessUsersListsPerLocationStoreKeyName
        ) {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state?.[businessUsersListsPerLocationStoreKeyName],
                action,
            });
        }
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};


export default createReducer(initialState, handlers);
