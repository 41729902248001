import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { navigationReducerName } from 'store/navigation/reducer';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { Currency } from 'models/domain/currencies';
import { BusinessUser } from 'models/domain/businessUsers';
import { businessUsersListsPerLocationStoreKeyName, businessUsersReducerName } from 'store/business-users/reducer';
import { clearBusinessUsersList, fetchBusinessUsersList } from 'store/business-users/actions';

import BusinessUsersList from './BusinessUsersList.layout';
import { BusinessUsersListDispatchProps, BusinessUsersListStateProps } from './BusinessUsersList.types';

const mapStateToProps = (state: RootState): BusinessUsersListStateProps => ({
    currenciesListData: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<BusinessUser>({
        state,
        reducerName: businessUsersReducerName,
        listsByLocationStoreKeyName: businessUsersListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): BusinessUsersListDispatchProps => ({
    dispatchFetchBusinessUsersList: ({ queryParams, locationPathname }) => dispatch(fetchBusinessUsersList({ queryParams, locationPathname })),
    dispatchClearBusinessUsersList: ({ locationPathname }) => dispatch(clearBusinessUsersList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: businessUsersReducerName,
        fieldName: businessUsersListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

});

const decorate = compose<ComponentType>(
    withTranslation('businessUsers'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUsersList);
