import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { addCompanyIdToQueryParams } from 'store/common.helpers';

import {
    fetchBeneficiariesListFailure,
    fetchBeneficiariesListSuccess,
} from './actions';
import { FETCH_BENEFICIARIES_LIST } from './action.types';


export const onFetchBeneficiariesList: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARIES_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(beneficiaries.fetchBeneficiaries(addCompanyIdToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => of(fetchBeneficiariesListSuccess({
            items: response.data.rows,
            totalCount: response?.data.count,
            locationPathname,
        }))),
        catchError(() => of(fetchBeneficiariesListFailure({ locationPathname }))),
    )),
);


export default [
    onFetchBeneficiariesList,
];
