import React, { useEffect } from 'react';
import { DefaultRedirectionProps } from './DefaultRedirection.types';


function DefaultRedirection({
    defaultRedirectionPath,
    dispatchRequestNavigation,
    navigationState,
}: DefaultRedirectionProps): JSX.Element {

    useEffect(() => {
        dispatchRequestNavigation({ locationPathname: defaultRedirectionPath, meta: navigationState });
    }, []);

    return <></>;
}

export default DefaultRedirection;
