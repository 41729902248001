import styled from 'styled-components';


const StandardAuthorisedPageWrapper = styled.div`
  padding: 20px 20px 0 20px;
  height: 100%;
`;
const StandardAuthorisedPageInnerWrapper = styled.div`
  height: 100%;
`;


export default { StandardAuthorisedPageWrapper, StandardAuthorisedPageInnerWrapper };
