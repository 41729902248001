import React, { useMemo } from 'react';
import { ChakraProvider, createStandaloneToast, localStorageManager } from '@chakra-ui/react';

import { GlobalStyles } from 'assets/styles/global/global.styled';
import createTheme from 'config/theme';

import ApplicationContent from '../ApplicationContent';


function Main({ colorScheme }): JSX.Element {
    const theme = useMemo(
        () => createTheme({ configuration: { colorScheme } }),
        [colorScheme],
    );

    const { ToastContainer } = createStandaloneToast();

    return (
        <ChakraProvider
            theme={theme}
            cssVarsRoot="#root"
            colorModeManager={localStorageManager}
        >
            <ApplicationContent />
            <ToastContainer />
            <GlobalStyles />
        </ChakraProvider>
    );
}

export default Main;
