import styled from 'styled-components';
import { Tabs, TabPanel } from '@chakra-ui/react';


const StyledStandardTabsWrapper = styled.div`
    height: 100%;
    width: 100vw;
`;

const StyledStandardTabs = styled(Tabs)`
    height: 100%;
    display: grid !important;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0,auto) minmax(0,1fr);
`;

const StyledStandardTabPanel = styled(TabPanel)`
    height: 100%;
    padding: 0 !important;
`;


export default { StyledStandardTabsWrapper, StyledStandardTabs, StyledStandardTabPanel };
