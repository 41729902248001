import { Currency } from 'models/domain/currencies';
import { CardStatus } from 'models/domain/cards';
import { TransactionStatus } from 'models/domain/transactions';
import { CountryIdType, Country } from 'models/domain/countries';

import { enumValueToReadableString } from './string-tools';

export const createCurrencyOptions = ({
    currencies = [],
    // t,
}: { currencies: Currency[] }) => {
    return currencies.map((currency) => {
        return {
            value: currency.short,
            label: currency.fullName,
        };
    });

};


export const createCountriesOptions = ({
    countries,
    prop = CountryIdType.iso31663,
}:{
        countries: Country[],
        prop?: CountryIdType,
}) => {

    const baseCountries = countries;
    if (!baseCountries.length) return [];

    return baseCountries.map((country) => (
        {
            value: country[prop],
            label: country.name,
        }
    ));
};


export const createCardStatusOptions = () => {
    return Object.keys(CardStatus).map((value) => {
        return {
            value,
            label: enumValueToReadableString(value),
        };
    });

};

export const createTransactionStatusOptions = () => {
    return Object.keys(TransactionStatus).map((value) => {
        return {
            value,
            label: enumValueToReadableString(value),
        };
    });

};
