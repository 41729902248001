import React, { useEffect } from 'react';

import { loginRootRoutePath } from 'config/routes';
import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';
import StandardForm from 'components/common/organisms/StandardForm';

import { PasscodeConfirmPageProps } from './PasscodeConfirmPage.types';
import { createPasscodeConfirmFieldsConfiguration, createPasscodeConfirmValidationSchema } from './PasscodeConfirmPage.helpers';

function PasscodeConfirmPage({
    loginRequestId,
    loginConfirmFormError,
    isLoadingLoginConfirmationForm,
    dispatchConfirmLogin,
    dispatchRequestNavigation,
    t,
}: PasscodeConfirmPageProps): JSX.Element {

    useEffect(() => {
        if (!loginRequestId) {
            dispatchRequestNavigation({ locationPathname: loginRootRoutePath });
        }
    }, []);


    return (
        <StandardUnauthorisedPage
            pageTitle={'Confirm login'}
        >
            <StandardForm
                t={t}
                submitButtonText={t('formsElements.passcode.text')}
                formName="confirm-login-form"
                initialFormState={{
                    passcode: '',
                    id: loginRequestId,
                }}
                validationSchema={createPasscodeConfirmValidationSchema({ t })}
                formError={loginConfirmFormError}
                isLoading={isLoadingLoginConfirmationForm}
                createFieldsConfiguration={createPasscodeConfirmFieldsConfiguration}
                dispatchOnSubmitAction={dispatchConfirmLogin}
            />
        </StandardUnauthorisedPage>
    );
}


export default PasscodeConfirmPage;
