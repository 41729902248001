import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { navigationReducerName } from 'store/navigation/reducer';

import { RootState } from 'store/rootReducer';


import { addCard } from 'store/cards/actions';

import { cardsReducerName } from 'store/cards/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'store/countries/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { AddCardPageStateProps, AddCardPageDispatchProps } from './AddCardPage.types';
import AddCardPage from './AddCardPage.layout';


const mapStateToProps = (state: RootState): AddCardPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    isLoadingAddCard: state[cardsReducerName].isLoadingAddCard,
    countriesList: getStandardListData({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),
});

const mapDispatchToProps = (dispatch): AddCardPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    dispatchOrderCard: ({ requestPayload, locationPathname }) => dispatch(addCard({ requestPayload, locationPathname })),
});


const decorate = compose<ComponentType>(
    withTranslation('cards'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AddCardPage);
