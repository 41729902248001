import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Alert from 'components/common/molecules/Alert';
import Styled from './AuthorisedLayout.styled';
import { AuthorisedLayoutProps } from './AuthorisedLayout.types';
import NavBar from './NavBar';
import AppIdleTimer from './AppIdleTimer';


function AuthorisedLayout({ children }: AuthorisedLayoutProps): JSX.Element {
    return (
        <AppIdleTimer>
            <Styled.AuthorisedLayoutOuterWrapper>
                <NavBar />
                <Styled.AuthorisedLayoutOuterContentWrapper id="route-wrapper">
                    <Styled.AuthorisedLayoutRouteContentWrapper>
                        <Alert />
                        <AnimatePresence mode="wait">
                            {children}
                        </AnimatePresence>
                    </Styled.AuthorisedLayoutRouteContentWrapper>
                </Styled.AuthorisedLayoutOuterContentWrapper>

            </Styled.AuthorisedLayoutOuterWrapper>
        </AppIdleTimer>
    );
}

export default AuthorisedLayout;
