import React from 'react';
import {
    Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Skeleton,
} from '@chakra-ui/react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useColorMode } from '@chakra-ui/system';

import { StandardAccordionProps } from './StandardAccordion.types';
import TheIcon from '../TheIcon';

//
function StandardAccordion({ itemsConfig }: StandardAccordionProps): JSX.Element {
    const { colorMode } = useColorMode();
    return itemsConfig?.length > 0
        ? (
            <Accordion allowMultiple>
                {itemsConfig.map((itemConfig) => (
                    <AccordionItem key={itemConfig.tabLabel}>
                        {({ isExpanded }) => (
                            <Box>
                                <h2>
                                    <AccordionButton _expanded={colorMode === 'light' ? { bg: 'black', color: 'white' } : { bg: 'white', color: 'black' }}>
                                        <Box as="span" flex="1" textAlign="left">
                                            {itemConfig.tabLabel}
                                        </Box>
                                        {isExpanded ? (
                                            <TheIcon Icon={MdExpandLess} />
                                        ) : (
                                            <TheIcon Icon={MdExpandMore} />
                                        )}
                                    </AccordionButton>
                                </h2>

                                <AccordionPanel pb={4}>
                                    {itemConfig.children}
                                </AccordionPanel>
                            </Box>
                        )}
                    </AccordionItem>
                ))}
            </Accordion>
        ) : <Skeleton />;
}

export default StandardAccordion;
