import styled from 'styled-components';
// import { scrollBar } from 'assets/styles/mixins/scrollbar.styled';
// import { SwitchTransition } from 'react-transition-group';

const AuthorisedLayoutOuterWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
`;

const AuthorisedLayoutOuterContentWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: minmax(0, 1fr);
  position: relative;
  overflow: hidden auto;
`;


const AuthorisedLayoutRouteContentWrapper = styled.div`
  padding: 0;
  height: 100%;
  overflow-x: hidden;
`;

// eslint-disable-next-line spellcheck/spell-checker


export default {
    AuthorisedLayoutOuterWrapper,
    AuthorisedLayoutOuterContentWrapper,


    AuthorisedLayoutRouteContentWrapper,
    // TransitionWrapper,
};
