import { TransactionType, TransactionScope } from 'models/domain/transactions';
import { TagData } from 'models/app/tag';


export const mapTransactionScopeToColorAndLabel = ({ transactionRecord, t }): TagData => {
    const { transaction_scope, transaction_type } = transactionRecord;

    const handlers = [
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.IBT,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ibt.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.OBT,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.obt.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.CT,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ct.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.APM,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.apm.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.CV,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.cv.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.EXT && transaction_scope === TransactionScope.CVR,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.cvr.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.IP2P,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ip2p.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.OP2P,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.op2p.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.AC,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ac.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.AD,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ad.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.EXF,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.exf.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.EXT,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.ext.text'),
            }),
        },

        {
            predicate: () => transaction_type === TransactionType.CT && transaction_scope === TransactionScope.ATM,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.atm.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.CT && transaction_scope === TransactionScope.POS,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.pos.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.CT && transaction_scope === TransactionScope.CR,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.cr.text'),
            }),
        },

        {
            predicate: () => transaction_type === TransactionType.FEE && transaction_scope === TransactionScope.C,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.c.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.FEE && transaction_scope === TransactionScope.D,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.d.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.BWC,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.bwc.text'),
            }),
        },
        {
            predicate: () => transaction_type === TransactionType.INT && transaction_scope === TransactionScope.BWD,
            handler: () => ({
                color: 'gray',
                label: t('transactions:transactionScopes.bwd.text'),
            }),
        },

        {
            predicate: () => true,
            handler: () => ({
                color: 'red',
                label: t('transactions:transactionScopes.unknown'),
            }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

