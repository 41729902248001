import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { requestNavigationBack } from 'store/navigation/actions';
import { showToast } from 'store/application/actions';
import {
    deleteBeneficiaryFailure, deleteBeneficiarySuccess,
    fetchBeneficiaryDetailsFailure, fetchBeneficiaryDetailsSuccess,
    updateBeneficiaryFailure, updateBeneficiarySuccess,
} from 'store/beneficiary/actions';
import { Epic } from 'models/meta/epic';
import { ToastType } from 'models/app/toast';

import { DELETE_BENEFICIARY, FETCH_BENEFICIARY_DETAILS, UPDATE_BENEFICIARY } from './actions.types';


export const onFetchBeneficiaryDetails: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARY_DETAILS),
    switchMap(({ payload }) => from(beneficiaries.fetchBeneficiaryDetails(payload?.queryParams?.beneficiaryUuid)).pipe(
        switchMap((response) => of(fetchBeneficiaryDetailsSuccess({
            responsePayload: response.data,
            locationPathname: payload?.locationPathname,
        }))),
        catchError(() => of(fetchBeneficiaryDetailsFailure({ locationPathname: payload?.locationPathname }))),
    )),
);


export const onUpdateBeneficiary: Epic = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(UPDATE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.updateBeneficiary(payload.beneficiaryUuid, payload.formData)).pipe(
        switchMap((response) => of(
            updateBeneficiarySuccess({
                responsePayload: response.data,
                locationPathname: payload?.locationPathname,
            }),
            showToast({
                type: ToastType.success,
                message: i18n.t('beneficiaries:actionMessages.updateBeneficiarySuccess'),
            }),
            requestNavigationBack(),
        )),
        catchError(() => of(updateBeneficiaryFailure({ locationPathname: payload?.locationPathname }))),
    )),
);

export const onDeleteBeneficiary: Epic = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(DELETE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.deleteBeneficiary(payload?.beneficiaryUuid)).pipe(
        switchMap(() => {
            return of(
                showToast({
                    type: ToastType.success,
                    message: i18n.t('beneficiaries:actionMessages.deleteBeneficiarySuccess'),
                }),
                deleteBeneficiarySuccess(payload.beneficiaryUuid),
                requestNavigationBack(),
            );
        }),
        catchError(() => of(deleteBeneficiaryFailure(payload?.locationPathname))),
    )),
);


export default [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];

