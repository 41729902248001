import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { applicationReducerName } from 'store/application/reducer';

import Main from './Main.layout';


const mapStateToProps = (state: RootState) => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(Main);
