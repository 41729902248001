import React from 'react';

import useStandardListCachingLogic from 'hook/useStandardListCachingLogic';
import { Card } from 'models/domain/cards';
import { cardsListQueryParams } from 'store/cards/reducer';
import StandardDataTable from 'components/common/organisms/StandardDataList';
import { createCardStatusOptions } from 'utils/options-crators';

import { createCardsListColumns } from './tableColumns';
import { CardsListProps } from './CardsList.types';
import { createCardsListFiltersConfiguration } from './CardsList.helpers';

function CardsList({
    // own props
    isInReadOnlyMode = false,
    companyId,
    accountId,
    businessUserId,
    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    // currencies,
    dispatchFetchCardsList,
    dispatchClearCardsList,
    dispatchSetQueryParams,

    t,
}: CardsListProps): JSX.Element {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        companyId,
        accountId,
        businessUserId,
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchFetchCardsList({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic<Card>({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: cardsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchCardsList,
        dispatchClearItemsList: dispatchClearCardsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional account list filters & buttons
    const cardStatusOptions = createCardStatusOptions();


    // 5. standard render component
    return (
        <StandardDataTable<Card>
            t={t}
            fetchData={fetchData}
            totalCount={listData.totalCount}
            items={listData.items}
            isLoadingData={listData.isLoadingList}
            queryParams={listData.queryParams}
            isListInitialStateDefined={isListInitialStateDefined}
            columnsConfiguration={createCardsListColumns({
                t,
                accessControl,
                queryParams: listData.queryParams,
            })}
            filtersConfiguration={createCardsListFiltersConfiguration({ t, cardStatusOptions })}
        />
    );
}

export default CardsList;

