import { HttpService } from 'services/http';


export const createEmployeeForCompanyId = (companyId: number, requestPayload: object) => HttpService.post(`/v2/companies/${companyId}/employees/`, requestPayload);

export const getCompanyIdEmployees = (companyId: number, queryParams: object) => HttpService.get(`/v2/companies/${companyId}/employees/`, queryParams);

export const getCompanyIdRiskDetails = (companyId: number, queryParams?: object) => HttpService.get(`/v2/companies/${companyId}/risk-details`, queryParams);

export const getCompaniesApi = (queryParams: object) => HttpService.get('/v2/companies', queryParams);

export const getCompanyIdDetails = (companyId: number) => HttpService.get(`/v2/companies/${companyId}`);

export const updateCompanyId = (companyId: number, requestPayload: object) => HttpService.put(`/v2/companies/${companyId}`, requestPayload);

export const updateCompanyAddressId = (companyId: number, requestPayload: object) => HttpService.put(`/v2/company-addresses/${companyId}`, requestPayload);

