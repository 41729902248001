export interface TableColumnConfiguration<ItemDefinition> {
    title: string,
    dataIndex: string,
    align?: TableColumnAlign,
    isSortable?: boolean,
    render?: (value: string | number | null | undefined, record: ItemDefinition) => JSX.Element,
}


export enum TableColumnAlign {
    center = 'center',
    left = 'left',
    right = 'right',
}
