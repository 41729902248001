import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { businessUsersListsPerLocationStoreKeyName, businessUsersReducerName } from 'store/business-users/reducer';
import { showToast } from 'store/application/actions';
import { fetchBusinessUsersList } from 'store/business-users/actions';
import { Epic } from 'models/meta/epic';
import { ToastType } from 'models/app/toast';

import { FETCH_BUSINESS_USER_DETAILS, UPDATE_BUSINESS_USER } from './action.types';
import { fetchBusinessUserDetailsFailure, fetchBusinessUserDetailsSuccess, updateBusinessUserFailure, updateBusinessUserSuccess } from './actions';


export const onFetchBusinessUserDetails: Epic = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_BUSINESS_USER_DETAILS),
    switchMap(({ payload }) => from(businessUsers.getBusinessUserDetails(payload.queryParams.businessUserId)).pipe(
        switchMap((response) => of(fetchBusinessUserDetailsSuccess({
            responsePayload: response.data,
            locationPathname: payload.locationPathname,
        }))),
        catchError(() => of(fetchBusinessUserDetailsFailure(payload.locationPathname))),
    )),
);

export const onUpdateBusinessUser: Epic = (action$, state$, { businessUsers, i18n }) => action$.pipe(
    ofType(UPDATE_BUSINESS_USER),
    switchMap(({ payload }) => from(businessUsers.updateBusinessUser(payload.formData))
        .pipe(
            switchMap((response) => {
                // eslint-disable-next-line max-len
                const currentEmployeesListQueryParams = state$.value[businessUsersReducerName][businessUsersListsPerLocationStoreKeyName]?.[payload.listOfOriginLocationPathname]?.queryParams;
                return of(
                    updateBusinessUserSuccess({ responsePayload: response.data }),
                    fetchBusinessUsersList({
                        queryParams: currentEmployeesListQueryParams,
                        locationPathname: payload.listOfOriginLocationPathname,
                    }),
                    showToast({ type: ToastType.success, message: i18n.t('companies:actionMessages.updateEmployeeSuccess') }),
                    // hideModal(),
                );
            }),
            catchError(() => of(
                updateBusinessUserFailure(),

            )),
        )),
);


export default [
    onUpdateBusinessUser,
    onFetchBusinessUserDetails,
];
