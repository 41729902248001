import React from 'react';
import { MdCreditCard, MdReceiptLong, MdPerson } from 'react-icons/md';

import { PN } from 'models/domain/permissions';
import TheIcon from 'components/common/atoms/TheIcon';
import TransactionsList from 'components/common/templates/TransactionsList';
import CardsList from 'components/common/templates/CardsList';


export const createBusinessUserDetailsPageTabsConfig = ({
    fixedBusinessUserId,
    accessControl,
    entityDetailsData,
    t,
}) => [
    ...(accessControl.hasPermission(PN.ACCOUNTS.READ_BY_ID) ? [
        {
            tabLabel: t('Business user details'),
            tabIcon: <TheIcon Icon={MdPerson} />,
            children: (
                <pre>
                    {JSON.stringify(entityDetailsData, null, 2)}
                </pre>
            ),
        },
    ] : []),
    {
        tabLabel: t('Transactions'),
        tabIcon: <TheIcon Icon={MdReceiptLong} />,
        children: (
            <TransactionsList businessUserId={fixedBusinessUserId} />
        ),
    },
    {
        tabLabel: t('Cards'),
        tabIcon: <TheIcon Icon={MdCreditCard} />,
        children: (
            <CardsList businessUserId={fixedBusinessUserId} />
        ),
    },
];


export default { createBusinessUserDetailsPageTabsConfig };
