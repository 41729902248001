import React from 'react';
import { MdVisibility, MdArrowForwardIos } from 'react-icons/md';

import StandardLink from 'components/common/atoms/StandardLink';
import TheIcon from 'components/common/atoms/TheIcon';

import { ViewButtonProps } from './ViewButton.types';


function ViewButton({
    title,
    onClick = undefined,
    disabled,
    path,
    icon,
    dataTestId,
    size = 'md',
    iconVariant = 'outline',
    useLinkVersion = false,
}: ViewButtonProps) {
    return (
        <StandardLink
            rightIcon={<TheIcon Icon={MdArrowForwardIos} />}
            disabled={disabled}
            dataTestId={dataTestId}
            path={path}
            size={size}
            title={title}
            onClick={onClick}
            icon={useLinkVersion ? undefined : icon || <TheIcon Icon={MdVisibility} />}
            iconVariant={iconVariant}
            buttonVariant="outline"
        />
    );
}


export default ViewButton;
