import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { showToast } from 'store/application/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'store/accounts/reducer';
import { Epic } from 'models/meta/epic';
import { fetchCardsList } from 'store/cards/actions';
import { ToastType } from 'models/app/toast';

import { DELETE_CARD, FETCH_CARD_DETAILS } from './actions.types';
import { deleteCardFailure, deleteCardSuccess, fetchCardDetailsFailure, fetchCardDetailsSuccess } from './actions';


export const onFetchCardDetails: Epic = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARD_DETAILS),
    switchMap(({ payload }) => from(cards.fetchCardDetails(payload.queryParams.cardId))
        .pipe(
            switchMap((response) => of(fetchCardDetailsSuccess({
                responsePayload: response.data,
                locationPathname: payload.locationPathname,
            }))),
            catchError(() => of(fetchCardDetailsFailure(payload.locationPathname))),
        )),
);

export const onDeleteCard: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(DELETE_CARD),
    switchMap(({ payload }) => from(cards.deleteCard(payload.cardId))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                return of(
                    deleteCardSuccess(response),
                    showToast({ type: ToastType.success, message: i18n.t('accounts:actionMessages.deleteAccountSuccess') }),
                    fetchCardsList({ queryParams: currentAccountListQueryParams, locationPathname: pathname }),
                    // hideDrawer(),
                );
            }),
            catchError(() => of(deleteCardFailure())),
        )),
);


export default [
    onFetchCardDetails,
    onDeleteCard,
];

