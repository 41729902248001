import React, { useEffect, useMemo } from 'react';
import {
    Button, Menu, MenuButton, MenuItem, MenuList, useDisclosure,
} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';

import TheIcon from 'components/common/atoms/TheIcon';
import { MdExpandMore, MdExpandLess, MdAccountBox } from 'react-icons/md';

import Styled from './UserDropdown.styled';
import { UserDropdownProps } from './UserDropdown.types';
import { createUserDropdownItemsConfig } from './UserDropodown.helpers';


function UserDropdown({
    t,
    username,
    dispatchRequestNavigation,
    dispatchClearCurrentUser,
    dispatchShowConfirmation,
}:UserDropdownProps): JSX.Element {
    const { toggleColorMode } = useColorMode();


    return (
        <Styled.UserDropdownWrapper>
            <Menu>
                {({ isOpen }) => (
                    <>
                        <MenuButton
                            as={Button}
                            leftIcon={<TheIcon Icon={MdAccountBox} />}
                            rightIcon={<TheIcon Icon={isOpen ? MdExpandLess : MdExpandMore} />}
                        >
                            {username}
                        </MenuButton>

                        <MenuList>
                            { createUserDropdownItemsConfig({
                                t,
                                dispatchClearCurrentUser,
                                dispatchRequestNavigation,
                                toggleColorMode,
                            }).map((menuItemConfiguration) => (
                                <MenuItem
                                    alignContent="center"
                                    alignItems="center"
                                    icon={menuItemConfiguration.icon}
                                    key={menuItemConfiguration.text}
                                    onClick={() =>
                                        menuItemConfiguration.confirmation
                                            ? dispatchShowConfirmation({ ...menuItemConfiguration.confirmation })
                                            : menuItemConfiguration.onClick()}
                                >

                                    {menuItemConfiguration.text}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </>
                )}
            </Menu>

        </Styled.UserDropdownWrapper>
    );
}

export default UserDropdown;
