export const generateSessionUuid = (
    uint32ArraySize = 10,
    joinWith = '_',
) => {
    const randomArray = new Uint32Array(uint32ArraySize);
    const randomisedNumbers = window.crypto.getRandomValues(randomArray).join(joinWith);

    return [
        Date.now(),
        randomisedNumbers,
    ].join(joinWith);
};


export default { generateSessionUuid };
