import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';

import withAccessControl from 'hoc/withAccessControl';
import { RootState } from 'store/rootReducer';


import ApplicationContent from './ApplicationContent.layout';


const mapStateToProps = (state: RootState) => ({
    applicationState: state.application.applicationState,
    enhancedCurrentLocation: state.navigation.currentLocation,
});


const decorate = compose<ComponentType>(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);

export default decorate(ApplicationContent);
