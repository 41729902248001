import { ofType } from 'redux-observable';
import {
    catchError, switchMap, tap, mapTo, mergeMap, takeUntil,
} from 'rxjs/operators';
import { EMPTY, from, fromEvent, of, timer } from 'rxjs';

import {
    setCurrentUserFailure, setCurrentUserSuccess,
    expireToken,
    extendTokenValidityFailure, extendTokenValiditySuccess, extendTokenValidity, clearCurrentUser, checkTokens,
} from 'store/current-user/actions';
import { hideAutoLogoutBanner, removeJwtTokenFromStorage } from 'store/application/actions';

import { calculateTimeToSilentTokenRefresh, getJwt, isTokenNotExpired, saveJwt } from 'utils/jwtToken';
import { requestNavigation } from 'store/navigation/actions';
import {
    rootRoutePath,
    loginRootRoutePath, dashboardRootRoutePath, publicRoutes,
} from 'config/routes';

import { fetchCountries } from 'store/countries/actions';
import { fetchCurrencies } from 'store/currencies/actions';
import { refreshToken } from 'store/application/epics.helpers';
import { setSessionStorageObjectItem } from 'utils/browser-storage';
import { permissionsKey, clientConfigKey } from 'config/config';
import { RawJwtToken } from 'models/api/auth';
import { Epic } from 'models/meta/epic';
import { APPLICATION_READY_AUTHORISED_USER } from 'store/application/action.types';
import {
    CHECK_TOKENS,
    CLEAR_CURRENT_USER, EXPIRE_TOKEN, EXTEND_TOKEN_VALIDITY, EXTEND_TOKEN_VALIDITY_SUCCESS, SET_CURRENT_USER, SET_CURRENT_USER_FAILURE, SET_CURRENT_USER_SUCCESS, USER_ACTIVITY,
} from './actions.types';

export const onSetCurrentUser: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER),
        tap(({ payload: { permissions, configuration, ...rest } }) => {
            http.setUserToken(rest.accessToken);
            setSessionStorageObjectItem(permissionsKey, permissions);
            setSessionStorageObjectItem(clientConfigKey, configuration);
            saveJwt(rest);
        }),
        mergeMap(({ payload }) => {
            if (payload.accessToken) {
                return of(setCurrentUserSuccess(payload));
            }
            return of(setCurrentUserFailure());
        }),
    );
};

export const onSetCurrentUserSuccess: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER_SUCCESS),
        mergeMap(() => {
            return of(
                fetchCountries(),
                fetchCurrencies(),
            );
        }),
    );
};
export const onApplicationReadyAuthorisedUser: Epic = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(APPLICATION_READY_AUTHORISED_USER),
        switchMap(() => {
            const { pathname } = history.location;
            const isPublic = publicRoutes.includes(pathname);
            const nextLocation = !isPublic
                ? pathname
                : dashboardRootRoutePath;

            return nextLocation !== dashboardRootRoutePath
                ? EMPTY // XXX DO nothing -  all should be fine or if route is not found catch-all redirect to dashboardRootRoutePath will be preformed by router
                : of(requestNavigation({ locationPathname: nextLocation }));
        }),
    );
};


export const onSetCurrentUserFailure: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER_FAILURE),
        switchMap(() => of(requestNavigation({ locationPathname: rootRoutePath }))),
    );
};


export const onClearCurrentUser: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(CLEAR_CURRENT_USER),
        tap(() => {
            http.clearUserToken();
        }),
        switchMap(() => of(
            expireToken(),
            removeJwtTokenFromStorage(),
            requestNavigation({ locationPathname: loginRootRoutePath }),
        )),
    );
};


export const onExtendTokenValidity: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(EXTEND_TOKEN_VALIDITY),
        switchMap(() => {
            const jwtTokens = getJwt();
            if (jwtTokens?.refreshToken) {
                return from(authorisation.extendTokenValidity(jwtTokens.refreshToken)).pipe(
                    switchMap((response) => {
                        return of(extendTokenValiditySuccess(response.data));
                    }),
                    catchError(() => of(extendTokenValidityFailure())),
                );
            }
            return of(extendTokenValidityFailure());
        }),
    );
};

export const onExtendTokenValiditySuccess = (action$, state$, { http }) => {
    return action$.pipe(
        ofType(EXTEND_TOKEN_VALIDITY_SUCCESS),
        tap(({ payload }) => {
            http.setUserToken(payload.accessToken);
            saveJwt(payload);
        }),
        switchMap(() => EMPTY),
    );
};

export const onExpireToken: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(EXPIRE_TOKEN),
        switchMap(() => {
            return from(authorisation.expireToken()).pipe(
                switchMap(() => EMPTY),
                catchError(() => EMPTY),
            );
        }),
    );
};

export const onReceivingToken: Epic = (action$) => {
    return action$.pipe(
        ofType(
            SET_CURRENT_USER,
            EXTEND_TOKEN_VALIDITY_SUCCESS,
        ),
        switchMap(({ payload }) => {
            const delay = calculateTimeToSilentTokenRefresh(payload.expirationTimeOfAccessToken);
            return timer(delay).pipe(
                takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
                mapTo(extendTokenValidity()),
            );
        }),
    );
};

const checkTokensValidity = (state, authorisation) => {
    const jwtToken: RawJwtToken = getJwt();
    const { isAutoLogoutBannerVisible } = state.application;

    if (isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) {
        return isAutoLogoutBannerVisible === true ? of(hideAutoLogoutBanner()) : EMPTY;
    }

    if (jwtToken
    && !isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)
    && isTokenNotExpired(jwtToken.expirationTimeOfRefreshToken)
    ) {
        refreshToken(jwtToken, authorisation);
    }

    return of(clearCurrentUser());
};


export const onUserActivity: Epic = (action$) => {
    return action$.pipe(
        ofType(USER_ACTIVITY),
        switchMap(() => of(checkTokens('userActivityAfterIDLE'))),
    );
};


export const onDocumentVisibilityChange: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CURRENT_USER),
        switchMap(() => {
            return fromEvent(document, 'visibilitychange').pipe(
                takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER))),
                switchMap(() => of(checkTokens('document.visibilitychange'))),
            );
        }),
    );
};

export const onCheckTokens: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(CHECK_TOKENS),
        switchMap(() => checkTokensValidity(state$.value, authorisation)),
    );
};

export default [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
];

