import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import {
    fetchCompanyDetailsFailure,
    fetchCompanyDetailsSuccess,
    updateCompanySuccess,
    updateCompanyFailure,
    updateCompanyAddressSuccess,
    updateCompanyAddressFailure,
} from 'store/company/actions';
import { showToast } from 'store/application/actions';
import { Epic } from 'models/meta/epic';
import { ToastType } from 'models/app/toast';

import { FETCH_COMPANY_DETAILS, UPDATE_COMPANY, UPDATE_COMPANY_ADDRESS } from './actions.types';


export const onFetchCompanyDetails: Epic = (action$, state$, { companies }) => {
    return action$.pipe(
        ofType(FETCH_COMPANY_DETAILS),
        switchMap(({ payload }) => from(companies.getCompanyDetails(payload))
            .pipe(
                switchMap((response) => of(fetchCompanyDetailsSuccess(response.data))),
                catchError(() => of(fetchCompanyDetailsFailure())),
            )),
    );
};

export const onUpdateCompany: Epic = (action$, state$, { companies, i18n }) => {
    return action$.pipe(
        ofType(UPDATE_COMPANY),
        switchMap(({ payload }) => from(companies.updateCompany(payload))
            .pipe(
                switchMap(() => of(
                    // XXX empty success response - this is different from all  updates responses
                    updateCompanySuccess(payload.formData),
                    showToast({ type: ToastType.success, message: i18n.t('companies:actionMessages.updateCompanySuccess') }),
                )),
                catchError(() => of(
                    updateCompanyFailure(),
                )),
            )),
    );
};

export const onUpdateCompanyAddress: Epic = (action$, state$, { companies, i18n }) => {
    return action$.pipe(
        ofType(UPDATE_COMPANY_ADDRESS),
        switchMap(({ payload }) => from(companies.updateCompanyAddress(payload))
            .pipe(
                switchMap((response) => of(
                    updateCompanyAddressSuccess(response.data),
                    showToast({ type: ToastType.success, message: i18n.t('companies:actionMessages.updateCompanyAddressSuccess') }),
                )),
                catchError(() => of(
                    updateCompanyAddressFailure(),
                )),
            )),
    );
};


export default [
    onFetchCompanyDetails,
    onUpdateCompany,
    onUpdateCompanyAddress,
];
