import { createReducer } from '@reduxjs/toolkit';

import { standardPageSize } from 'config/config';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';
import { SortDirections } from 'models/app/applicationState';
import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { CLEAR_BENEFICIARIES_LIST, FETCH_BENEFICIARIES_LIST, FETCH_BENEFICIARIES_LIST_FAILURE, FETCH_BENEFICIARIES_LIST_SUCCESS } from './action.types';


export const beneficiariesReducerName = 'beneficiaries';
export const beneficiariesListsPerLocationStoreKeyName = 'beneficiariesListsPerLocation';


export const defaultBeneficiariesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};

const initialListState = {
    [beneficiariesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    //
};


const initialState = { ...initialListState };

const handlers = {
    [FETCH_BENEFICIARIES_LIST]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardLists(
            {
                listsState: state[beneficiariesListsPerLocationStoreKeyName],
                action,
            },
        );
    },
    [FETCH_BENEFICIARIES_LIST_SUCCESS]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(
            {
                listsState: state[beneficiariesListsPerLocationStoreKeyName],
                action,
            },
        );
    },
    [FETCH_BENEFICIARIES_LIST_FAILURE]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[beneficiariesListsPerLocationStoreKeyName],
            action,
        });
    },
    [CLEAR_BENEFICIARIES_LIST]: (state, action) => {
        state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[beneficiariesListsPerLocationStoreKeyName],
            action,
        });
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === beneficiariesReducerName
      && action.payload?.fieldName === beneficiariesListsPerLocationStoreKeyName
        ) {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state?.[beneficiariesListsPerLocationStoreKeyName],
                action,
            });
        }
    },


    [CLEAR_CURRENT_USER]: () => initialState,
};


export default createReducer(initialState, handlers);
