export const INITIALISE_LOGIN = 'INITIALISE_LOGIN';
export const INITIALISE_LOGIN_SUCCESS = 'INITIALISE_LOGIN_SUCCESS';
export const INITIALISE_LOGIN_FAILURE = 'INITIALISE_LOGIN_FAILURE';


export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const CONFIRM_LOGIN_SUCCESS = 'CONFIRM_LOGIN_SUCCESS';
export const CONFIRM_LOGIN_FAILURE = 'CONFIRM_LOGIN_FAILURE';


export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';


export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';


export const GET_TOKENS_FOR_DUPLICATED_TAB = 'GET_TOKENS_FOR_DUPLICATED_TAB';

export const GET_REMEMBERED_LOGIN_USER_EMAIL = 'GET_REMEMBERED_LOGIN_USER_EMAIL';
export const GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS = 'GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS';
export const GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE = 'GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE';

