import { createReducer } from '@reduxjs/toolkit';

import { Action } from 'history';
import { EnhancedLocation, NavigationDirectionTypes } from 'models/app/navigation';
import {
    BLOCK_NAVIGATION, HIDE_NAVIGATION_CONFIRMATION,
    NAVIGATION_CANCELED,
    PREFORM_NAVIGATION_BACK_SUCCESS,
    PREFORM_NAVIGATION_SUCCESS,
    REQUEST_NAVIGATION, SHOW_NAVIGATION_CONFIRMATION,
    UNBLOCK_NAVIGATION,
} from './actions.types';
import { CLEAR_CURRENT_USER } from '../current-user/actions.types';
import { INIT_APPLICATION } from '../application/action.types';

export const navigationReducerName = 'navigation';

export interface NavigationStateType {
    readonly previousLocation?: EnhancedLocation;
    readonly currentLocation: EnhancedLocation;
    readonly requestedNextLocation?: any;
    readonly blockNavigationReason?: any;

    readonly isNavigationConfirmationVisible: boolean;
}

const initialState: NavigationStateType = {
    previousLocation: undefined,
    currentLocation: {} as EnhancedLocation, // set on INIT_APPLICATION
    requestedNextLocation: undefined,

    blockNavigationReason: undefined,

    isNavigationConfirmationVisible: false,
};

const handlers = {
    [INIT_APPLICATION]: (state) => {
        const pageReloaded = window.performance.getEntriesByType('navigation').map((nav) => nav.entryType.includes('reload'));
        const { location } = window;
        state.currentLocation = {
            hash: location.hash,
            key: pageReloaded ? 'PAGE_RELOAD' : 'INIT',
            pathname: location.pathname,
            search: location.search,
            // state: location.state,
            // Enhance it to match data standard of history.location listener-driven location updates
            action: pageReloaded ? Action.Replace : Action.Push,
            direction: pageReloaded ? NavigationDirectionTypes.SELF : NavigationDirectionTypes.FORWARD,
        };
    },

    [BLOCK_NAVIGATION]: (state, action) => {
        state.blockNavigationReason = action.meta;
    },
    [UNBLOCK_NAVIGATION]: (state) => {
        state.blockNavigationReason = undefined;
    },

    [REQUEST_NAVIGATION]: (state, action) => {
        state.requestedNextLocation = {
            location: action.payload,
            meta: action.meta,
        };
    },

    [NAVIGATION_CANCELED]: (state) => {
        state.requestedNextLocation = undefined;
    },
    [PREFORM_NAVIGATION_SUCCESS]: (state, action) => {
        state.currentLocation = action.payload;
    },
    [PREFORM_NAVIGATION_BACK_SUCCESS]: (state, action) => {
        state.currentLocation = action.payload;
    },
    [SHOW_NAVIGATION_CONFIRMATION]: (state) => {
        state.isNavigationConfirmationVisible = true;
    },
    [HIDE_NAVIGATION_CONFIRMATION]: (state) => {
        state.isNavigationConfirmationVisible = false;
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
