import React from 'react';

import CountryOrCurrencyFlag from 'components/common/molecules/CountryOrCurrencyFlag';


import StandardTooltip from 'components/common/atoms/StandardTooltip';
import StandardValue from 'components/common/molecules/StandardValue';
import Styled from './FlagAndValue.styled';
import { FlagAndValueProps } from './FlagAndValue.types';


function FlagAndValue({
    currencyCode,
    countryCode,
    size = 20,
    text = '-',
    inlineMode = false,
    valueFirst = false,
    isTextEllipsisDisabled = false,
    useCountryISOAlpha3Code = false,
    showTooltip,
    tooltipPlacement,
    showCopyToClipboard,
}:FlagAndValueProps) {
    const flag = (
        <CountryOrCurrencyFlag
            currencyCode={currencyCode}
            countryCode={countryCode}
            useCountryISOAlpha3Code={useCountryISOAlpha3Code}
            size={size}
        />
    );

    const value = isTextEllipsisDisabled ? text : (
        <Styled.Value>
            {text}
        </Styled.Value>
    );
    const flagAndValue = (
        <Styled.FlagAndValueWrapper
            valueFirst={valueFirst}
            inlineMode={inlineMode}
        >
            {valueFirst ? (
                <>
                    <StandardValue value={value} />
                    {flag}
                </>
            ) : (
                <>
                    {flag}
                    <StandardValue value={value} />
                </>
            )}
        </Styled.FlagAndValueWrapper>
    );
    return showTooltip ? (
        <StandardTooltip
            title={text}
            showCopyToClipboard={showCopyToClipboard}
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            placement={tooltipPlacement}
        >
            {flagAndValue}
        </StandardTooltip>
    ) : flagAndValue;


}


export default FlagAndValue;
