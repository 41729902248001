import /* webpackPreload: true */ /* webpackChunkName: "maintenance_cogs" */ maintenanceCogs
    from 'assets/images/maintenance-cogs.svg?url';
import /* webpackPreload: true */ /* webpackChunkName: "paper_money_background" */ paperMoneyBackground
    from 'assets/images/tmp/paper-money-background-template.jpg?url';
import /* webpackPreload: true */ /* webpackChunkName: "modern_hexagonal_grid_design" */ modernHexagonalGridDesign
    from 'assets/images/tmp/modern_hexagonal_grid_design.jpg?url';

const demoImages = { maintenanceCogs, paperMoneyBackground, modernHexagonalGridDesign };


export default demoImages;
