import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import * as yup from 'yup';
import { PasscodeConfirmFormFields } from './PasscodeConfirmPage.types';

export const createPasscodeConfirmFieldsConfiguration = ({ t }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.PIN,
        id: 'passcode',
        name: 'passcode',
        labelText: t('Confirmation code'),
        placeholderText: t('Enter your confirmation code'),
        hintText: t('Check your email! Confirmation code won`t last forever.'),
        optionalConfiguration: { isRequired: true },
    },
]);

export const createPasscodeConfirmValidationSchema = ({ t }) => {
    return yup.object<PasscodeConfirmFormFields>({
        passcode: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .matches(/^[0-9]+$/, 'Must be only digits')
            .length(6),
    }).required();
};

export default { createPasscodeConfirmFieldsConfiguration, createPasscodeConfirmValidationSchema };
