const envs = process.env;

export const BASE_API_URL = envs.BASE_API_URL ?? '';
export const TIME_TO_AUTO_LOGOUT_COUNTDOWN = Number(envs.TIME_TO_AUTO_LOGOUT_COUNTDOWN) ?? '';
export const developmentMode = envs.NODE_ENV === 'development';
export const debugMode = developmentMode && false;
export const MAINTANCE_MODE_ENABLED = envs.MAINTANCE_MODE_ENABLED === 'true' ?? false;
export const ENABLE_REDUX_LOGGER = envs.ENABLE_REDUX_LOGGER === 'true' ?? false;
export const SENTRY_DSN = envs.SENTRY_DSN ?? '';
export const ENABLE_DEV_TOOLS = envs.ENABLE_DEV_TOOLS === 'true' ?? false;
export const APPLICATION_NAME = envs.APPLICATION_NAME ?? 'Business Web Portal';


const wlNames = envs.WL_NAMES;
const wlAssetsURLs = envs.WL_ASSETS_URLS;


export const WL_CONFIG = {
    brandShortName: wlNames?.brandShortName,
    brandFullName: wlNames?.brandFullName,
    applicationName: wlNames?.applicationName,
    brandLandingPageUrl: wlNames?.brandLandingPageUrl,
    brandBaseDomain: wlNames?.brandBaseDomain,

    ...(wlAssetsURLs?.brandFullLogoUrl?.length > 0 ? { brandFullLogoUrl: wlAssetsURLs.brandFullLogoUrl } : {}),
    ...(wlAssetsURLs?.brandSignetLogoUrl?.length > 0 ? { brandSignetLogoUrl: wlAssetsURLs.brandSignetLogoUrl } : {}),
    ...(wlAssetsURLs?.brandAnimatedLoadingPlaceholderUrl?.length > 0 ? { brandAnimatedLoadingPlaceholderUrl: wlAssetsURLs.brandAnimatedLoadingPlaceholderUrl } : {}),
    ...(wlAssetsURLs?.brandFaviconUrl?.length > 0 ? { brandFaviconUrl: wlAssetsURLs.brandFaviconUrl } : {}),

    externalLinks: envs.EXTERNAL_LINKS,
    themeVariables: envs.THEME_VARIABLES,
};

