import * as React from 'react';
import {
    Box, Text,
    ButtonGroup, Card, CardBody, CardFooter, Heading, Skeleton, SkeletonText, Stack,
} from '@chakra-ui/react';

import ColumnsRwd from 'components/common/atoms/ColumnsRwd';

import Styled from './StandardDataListCard.styled';
import { StandardDataListCardOwnProps } from './StandardDataListCard.types';


function StandardDataListCard<ItemDefinition>({
    columnsConfiguration = [],
    columnsCount,
    item,
    itemRowId = 'id',
    cardSkeletonMode = false,
}: StandardDataListCardOwnProps<ItemDefinition>) {

    const standardColumns = cardSkeletonMode
        ? Array(columnsCount).fill(0)
        : columnsConfiguration.slice(0, columnsConfiguration.length - 1);
    const actions = columnsConfiguration.slice(-1)[0];

    const renderValue = (column) => {
        return column.render
            ? column.render(item?.[column.dataIndex], item)
            : item?.[column.dataIndex];
    };

    return (
        <Styled.CardWrapper>
            <Card
                size="lg"
                variant="elevated"
            >
                {/* TODO restore when columns/field configuration issue will be resolved*/}
                {/*<CardHeader>*/}
                {/*    <Box>*/}
                {/*        {cardSkeletonMode ? (*/}
                {/*            <Skeleton height="30px" />*/}
                {/*        ) : (*/}
                {/*            <>*/}
                {/*                <Heading size="sm">Lorem Ipsum</Heading>*/}
                {/*                <Text>Lorem Ipsum ipsum lorem</Text>*/}
                {/*            </>*/}
                {/*        )}*/}
                {/*    </Box>*/}
                {/*</CardHeader>*/}

                <CardBody>
                    <ColumnsRwd>
                        {standardColumns.map((column, index) => (
                            <Box
                                px={{ base: '1', md: '1' }}
                                py={{ base: '2', md: '3' }}
                                bg="bg-surface"
                                borderRadius="lg"
                                boxShadow="sm"
                                key={index}
                            >
                                {cardSkeletonMode
                                    ? (
                                        <Stack>
                                            <SkeletonText noOfLines={1} skeletonHeight="10px" />
                                            <SkeletonText noOfLines={1} skeletonHeight="20px" />
                                        </Stack>
                                    ) : (
                                        <Stack>
                                            <Text fontSize="sm" color="muted">
                                                {column?.title}
                                            </Text>
                                            <Heading size={{ base: 'sm', md: 'md' }}>
                                                {renderValue(column)}
                                            </Heading>
                                        </Stack>
                                    )}
                            </Box>
                        ))}
                    </ColumnsRwd>
                </CardBody>

                <CardFooter>
                    <Styled.CardFooterActionsWrapper>
                        {cardSkeletonMode
                            ? <Skeleton height="30px" width="140px" />
                            : (
                                <ButtonGroup spacing="2">
                                    {renderValue(actions)}
                                </ButtonGroup>
                            )}
                    </Styled.CardFooterActionsWrapper>
                </CardFooter>
            </Card>
        </Styled.CardWrapper>
    );
}
export default StandardDataListCard;
