import * as React from 'react';

import ColumnsRwd from 'components/common/atoms/ColumnsRwd';

import StandardDataListCard from './StandardDataListCard';
import SkeletonCardsList from '../SkeletonCardsList';


function StandardDataListCards<ItemDefinition>({
    items,
    queryParams,
    columnsConfiguration,
    itemRowId = 'id',
    isListReady,
}) {
    return isListReady ? (
        <ColumnsRwd>
            {items.map((item) => (
                <StandardDataListCard<ItemDefinition>
                    key={item[itemRowId]}
                    item={item}
                    itemRowId={itemRowId}
                    queryParams={queryParams}
                    columnsConfiguration={columnsConfiguration}
                />
            ))}
        </ColumnsRwd>
    ) : <SkeletonCardsList cardsCount={queryParams.limit} columnsCount={columnsConfiguration.length - 1} />;
}


export default StandardDataListCards;
