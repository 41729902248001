export const createTransactionsListFiltersConfiguration = ({
    t,
    currencyOptions,
    transactionStatusOptions,
}) => {
    return {
        sortByOptions: [
            {
                value: 'id',
                label: 'ID',
            },
            {
                value: 'currency',
                label: t('Currency'),
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'transactionType',
                label: 'Transaction type',
            },
            {
                value: 'transactionScope',
                label: 'Transaction scope',
            },
        ],
        filtersConfig: [
            {
                label: 'Status',
                queryParamName: 'status',
                filterOptions: transactionStatusOptions,
                placeholderText: 'Select to narrow',
            },

            {
                label: 'Currency',
                queryParamName: 'currency',
                filterOptions: currencyOptions,
                placeholderText: 'Select to narrow',
            },

            // TODO restore composite 'Scope' (in fact 2 query params: type+scope) filter
            // {
            //     label: 'Scope',
            //     queryParamName: 'scope',
            //     filterOptions: [
            //         {
            //             value: '',
            //             label: '',
            //         },
            //     ],
            // },
        ],
    };
};


export default { createTransactionsListFiltersConfiguration };
