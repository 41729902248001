import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';


import {
    onDeleteAccount,
    onFetchAccountDetails,
} from 'store/account/epics';
import {
    onAddAccount,
    onFetchAccountsList,
} from 'store/accounts/epics';
import {
    onInitApplication,
    onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onInitI18next,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent, onShowToast, onApplicationReadyUnauthorisedUser, onGetColorScheme, onSetColorScheme,
} from 'store/application/epics';

import {
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onRequestPasswordReset,
    onGetRememberedUserEmail,
    onSetNewPasswordSuccess,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
} from 'store/authorisation/epics';

import {
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested, onPreformNavigationBack,
    onBlockNavigation,
    onUnblockNavigation, onLocationChanged,
} from 'store/navigation/epic';

import { onfetchCountries } from 'store/countries/epics';

import { onFetchCurrencies } from 'store/currencies/epics';
import {
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onSetCurrentUser,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity, onDocumentVisibilityChange, onCheckTokens,
} from 'store/current-user/epic';

import {
    onFetchCompanyDetails,
    onUpdateCompany,
    onUpdateCompanyAddress,
} from 'store/company/epics';
import { developmentMode } from 'config/environment';
import { onAddBusinessUser, onFetchBusinessUsersList } from './business-users/epics';
import { onFetchBusinessUserDetails, onUpdateBusinessUser } from './business-user/epics';
import { onAddCard, onFetchCardsList } from './cards/epics';
import { onFetchTransactionsList } from './transactions/epics';
import { onFetchBeneficiariesList } from './beneficiaries/epics';
import { onDeleteCard, onFetchCardDetails } from './card/epics';
import { onDeleteBeneficiary, onFetchBeneficiaryDetails, onUpdateBeneficiary } from './beneficiary/epics';


const accountEpics = [
    onFetchAccountDetails,
    onDeleteAccount,
];

const accountsEpics = [
    onAddAccount,
    onFetchAccountsList,
];

const applicationEpics = [
    onInitApplication,
    onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onApplicationReadyUnauthorisedUser,
    onInitI18next,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
    onGetColorScheme,
    onSetColorScheme,
];

const authorisation = [
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onRequestPasswordReset,
    onSetNewPasswordSuccess,
    onGetRememberedUserEmail,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
];

const businessUserEpics = [
    onUpdateBusinessUser,
    onFetchBusinessUserDetails,
];

const beneficiariesEpics = [
    onFetchBeneficiariesList,
];

const beneficiaryEpics = [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];


const businessUsersEpics = [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];

const cardsEpics = [
    onFetchCardsList,
    onAddCard,

];

const cardEpics = [
    onFetchCardDetails,
    onDeleteCard,
];

const companyEpics = [
    onFetchCompanyDetails,
    onUpdateCompany,
    onUpdateCompanyAddress,
];

const countriesEpics = [
    onfetchCountries,
];

const currenciesEpics = [
    onFetchCurrencies,
];

const currentUserEpics = [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
];

const navigationEpics = [
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested,
    onPreformNavigationBack,
    onLocationChanged,
    onBlockNavigation,
    onUnblockNavigation,
];
const transactionsEpics = [
    onFetchTransactionsList,
];


const epics = [
    ...accountEpics,
    ...accountsEpics,
    ...applicationEpics,
    ...authorisation,
    ...beneficiariesEpics,
    ...beneficiaryEpics,
    ...businessUserEpics,
    ...businessUsersEpics,

    ...cardEpics,
    ...cardsEpics,
    ...companyEpics,
    ...countriesEpics,
    ...currenciesEpics,
    ...currentUserEpics,
    ...transactionsEpics,
    ...navigationEpics,
];


export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (areEpicsDoubled) {
    throw Error(`Doubled epics! ${Array.from(new Set(epics)).length} vs ${epics.length}`);
}

const onAnyEpic = combineEpics(...epics);

function errorHandler(error, source) {
    if (developmentMode) {
    // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));


export default onEpic;
