import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { applicationReducerName } from 'store/application/reducer';

import { TheSpinnerOwnProps, TheSpinnerStateProps } from './TheSpinner.types';
import TheSpinner from './TheSpinner.layout';


const mapStateToProps = (state: RootState): TheSpinnerStateProps => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose<ComponentType<TheSpinnerOwnProps>>(
    withTranslation('common'),
    connect(mapStateToProps),
);


export default decorate(TheSpinner);
