import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { fetchCountriesSuccess, fetchCountriesFailure } from 'store/countries/actions';

import { FETCH_COUNTRIES } from './actions.types';


export const onfetchCountries: Epic = (action$, state$, { countries }) => {
    return action$.pipe(
        ofType(FETCH_COUNTRIES),
        switchMap(({ payload }) => from(countries.fetchCountries()).pipe(
            switchMap((response) => of(fetchCountriesSuccess(
                response.data,
                response?.data?.length,
                payload?.locationPathname,
            ))),
            catchError(() => of(fetchCountriesFailure(payload?.locationPathname))),
        )),
    );
};


export default [
    onfetchCountries,
];
