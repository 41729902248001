export const createBeneficiariesListConfiguration = ({ t }) => {

    return {
        searchBy: [],
        sortByOptions: [],
        filtersConfig: [
        ],
    };
};


export default { createBeneficiariesListConfiguration };
