import React from 'react';
import StandardDataListCard from 'components/common/organisms/StandardDataList/StandardDataListCards/StandardDataListCard';
import ColumnsRwd from 'components/common/atoms/ColumnsRwd';
import { SkeletonCardsListProps } from './SkeletonCardsList.types';


function SkeletonCardsList<ItemDefinition>({
    cardsCount = 6,
    columnsCount,
}: SkeletonCardsListProps): JSX.Element {
    return (
        <ColumnsRwd>
            {[...Array(cardsCount).keys()].map((index) => (
                <StandardDataListCard<ItemDefinition>
                    key={index}
                    cardSkeletonMode
                    columnsCount={columnsCount}
                />
            ))}
        </ColumnsRwd>
    );
}

export default SkeletonCardsList;

