import { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';

import { requestNavigation } from 'store/navigation/actions';
import { Dispatch } from 'models/meta/action';

import StandardAuthorisedPage from './StandardAuthorisedPage.layout';
import { StandardAuthorisedPageOwnProps, StandardLinkDispatchProps } from './StandardAuthorisedPage.types';


const mapDispatchToProps = (dispatch: Dispatch): StandardLinkDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose<ComponentType<StandardAuthorisedPageOwnProps>>(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(StandardAuthorisedPage);
