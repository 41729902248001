import React from 'react';
import { MdPerson, MdDarkMode, MdPowerSettingsNew } from 'react-icons/md';

import { myProfileRootRoutePath } from 'config/routes';
import TheIcon from 'components/common/atoms/TheIcon';


export const createUserDropdownItemsConfig = ({
    t,
    dispatchRequestNavigation,
    dispatchClearCurrentUser,
    toggleColorMode,
}) => ([
    {
        icon: <TheIcon Icon={MdDarkMode} />,
        text: t('Toggle color mode'),
        onClick: toggleColorMode,
    },
    {
        icon: <TheIcon Icon={MdPerson} />,
        text: t('User profile'),
        onClick: () => dispatchRequestNavigation({ locationPathname: myProfileRootRoutePath }),
    },
    {
        text: t('Logout'),
        icon: <TheIcon Icon={MdPowerSettingsNew} />,
        onClick: () => dispatchClearCurrentUser(),
        confirmation: {
            confirmationHeader: t('Logout'),
            confirmationQuestion: t('Are you sure you want to logout?'),
            onConfirm: () => dispatchClearCurrentUser(),
        },
    },
]);

export default { createUserDropdownItemsConfig };
