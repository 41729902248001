import styled from 'styled-components';


const StandardTableWrapper = styled.div`
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {

    /* Force table to not be like tables anymore */
    & > table.chakra-table {
      display: block !important;

      thead,
      tbody,
      th,
      tr {
        display: block !important;
      }

      thead {
        display: none !important;
      }


      tbody {
        tr {
          margin-top: 30px;
          border-width: 10px;
        }

        tr + tr {
          margin-top: 30px;
        }

        td {
          display: grid !important;
          grid-template-columns: minmax(0, 50%) minmax(0, 1fr);

          div {
            width: auto;
          }
        }

        td:before {
          display: block;
          padding-right: 10px;
          white-space: nowrap;
          content: attr(data-label);
        }
      }

    }
  }
`;


export default { StandardTableWrapper };
