export const getPaginationSummary = ({
    itemsCount,
    queryParams,
    totalCount,
    t,
}) => {
    const handlers = [
        {
            predicate: totalCount <= queryParams.limit,
            handler: () => t('common:pagination.viewingAllItems', { totalCount }),
        },

        {
            predicate: itemsCount === 1,
            handler: () => t('common:pagination.viewingLastPaginationItem', { totalCount }),

        },
        {
            predicate: itemsCount < queryParams.limit,
            handler: () => t('common:pagination.viewingResultsRange', {
                startIndex: queryParams.offset + 1,
                endIndex: queryParams.offset + itemsCount,
                totalCount,
            }),
        },
        {
            predicate: true,
            handler: () => t('common:pagination.viewingResultsRange', {
                startIndex: queryParams.offset + 1,
                endIndex: queryParams.offset + queryParams.limit,
                totalCount,
            }),
        },
    ];
    return handlers.find(({ predicate }) => predicate)?.handler();
};

export default { getPaginationSummary };

