import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { ADD_CARD, FETCH_CARDS_LIST } from './actions.types';
import { addCardFailure, addCardSuccess, fetchCardsList, fetchCardsListFailure, fetchCardsListSuccess } from './actions';
import { addCompanyIdToQueryParams } from '../common.helpers';
import { showToast } from '../application/actions';
import { ToastType } from '../../models/app/toast';
import { requestNavigation } from '../navigation/actions';
import { cardsListRootRoutePath } from '../../config/routes';
import { cardsListsPerLocationStoreKeyName, cardsReducerName } from './reducer';
import { currentUserReducerName } from '../current-user/reducer';

export const onAddCard: Epic = (action$, state$, { cards, i18n }) => action$.pipe(
    ofType(ADD_CARD),
    switchMap(({ payload }) => from(cards.processOrderNewCard({ ...payload.requestPayload, companyId: state$.value[currentUserReducerName].userData?.companyId }))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentCardListQueryParams = state$.value[cardsReducerName][cardsListsPerLocationStoreKeyName]?.[cardsListRootRoutePath]?.queryParams;

                return of(
                    requestNavigation({ locationPathname: cardsListRootRoutePath }),
                    addCardSuccess({ locationPathname: pathname, requestPayload: payload }),
                    fetchCardsList({ queryParams: currentCardListQueryParams ?? {}, locationPathname: pathname }),
                    showToast({ type: ToastType.success, message: i18n.t('cards:actionMessages.cardsOrderSuccess') }),
                );
            }),
            catchError(({ locationPathname }) => of(addCardFailure({ locationPathname }))),
        )),
);


export const onFetchCardsList: Epic = (action$, state$, { cards }) => action$.pipe(
    ofType(FETCH_CARDS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(
        cards.fetchAllCards(addCompanyIdToQueryParams({ state$, queryParams })),
    ).pipe(
        switchMap((response) => of(fetchCardsListSuccess({
            items: response?.data?.rows,
            totalCount: response?.data?.count,
            locationPathname,
        }))),
        catchError(() => of(fetchCardsListFailure({ locationPathname }))),
    )),
);

export default [
    onFetchCardsList,
    onAddCard,
];

