import React, { useMemo } from 'react';
import {
    FormControl, FormErrorMessage, FormHelperText, FormLabel, InputGroup, InputLeftElement, InputRightElement, ScaleFade,
} from '@chakra-ui/react';

import { Controller } from 'react-hook-form';
import { renderFormInputType } from './FormFieldWrapper.helpers';
import { FormFieldWrapperProps } from './FormField.types';


function FormFieldWrapper({
    fieldConfiguration,
    control,
    errors,
}: FormFieldWrapperProps): JSX.Element {
    const error = errors?.[fieldConfiguration.name];
    return (
        <Controller
            name={fieldConfiguration.name as any}
            control={control}
            render={({ field, fieldState, formState }) => (
                <FormControl
                    isInvalid={!!error}
                    isRequired={!!fieldConfiguration?.optionalConfiguration?.isRequired}
                >
                    {fieldConfiguration.labelText && !fieldConfiguration.inlineLabel ? (
                        <FormLabel htmlFor={fieldConfiguration.id}>
                            {fieldConfiguration.labelText}
                        </FormLabel>
                    ) : (<></>)}


                    <InputGroup>
                        {fieldConfiguration.inputLeftElement ? (
                            <InputLeftElement pointerEvents="none">
                                {fieldConfiguration.inputLeftElement}
                            </InputLeftElement>
                        ) : (<></>)}

                        {renderFormInputType({
                            field,
                            fieldState,
                            formState,
                            fieldConfiguration,
                        })}

                        {fieldConfiguration.inputRightElement ? (
                            <InputRightElement pointerEvents="none">
                                {fieldConfiguration.inputRightElement}
                            </InputRightElement>
                        ) : (<></>)}
                    </InputGroup>
                    <div style={{ position: 'relative' }}>
                        {!error ? (
                            <FormHelperText fontSize="md">{`${fieldConfiguration.hintText || ''}`}</FormHelperText>
                        ) : (
                            <ScaleFade initialScale={0.9} in={!!error?.message}>
                                <FormErrorMessage fontSize="md">{`${error?.message || ''}`}</FormErrorMessage>
                            </ScaleFade>
                        )}
                    </div>
                </FormControl>
            )}
        />
    );
}


export default FormFieldWrapper;

