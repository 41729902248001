import { Button, Heading } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { isUndefined } from 'utils/object-tools';

import Styled from './NoResultsPlaceholder.styled';


function NoResultsPlaceholder({
    t,
    queryParams,
    filtersConfiguration,
    fetchData,
}) {
    const isSearchActive = useMemo(
        () => queryParams?.search?.length > 0,
        [queryParams],
    );

    const filterNames = useMemo(
        () => (filtersConfiguration?.filtersConfig || []).map((filter) => filter.queryParamName),
        [filtersConfiguration],
    );

    const activeFilterNames = useMemo(
        () => (filterNames || []).filter((filterName) => !isUndefined(queryParams[filterName]) ? filterName : false),
        [filtersConfiguration],
    );

    const hasActiveFilters = useMemo(
        () => activeFilterNames.length > 0 || isSearchActive,
        [activeFilterNames],
    );

    const resetDataPayload = useMemo(
        () => filterNames.reduce((acc, filterName) => {
            return {
                ...acc,
                [filterName]: undefined,
            };

        }, { ...(isSearchActive ? { search: '' } : {}) }),
        [hasActiveFilters],
    );

    return (
        <Styled.NoResultsPlaceholderWrapper>
            <div>
                {hasActiveFilters
                    ? (
                        <>
                            <Heading paddingBottom={4}>
                                {t('No results matching selected criteria.')}
                            </Heading>

                            <Button
                                size="md"
                                onClick={() => fetchData({
                                    ...queryParams,
                                    ...resetDataPayload,
                                })}
                                className="reset--active-filters"
                            >
                                {t('Reset active filters')}
                            </Button>
                        </>
                    )
                    : <Heading>{t('No results.')}</Heading>}
            </div>
        </Styled.NoResultsPlaceholderWrapper>
    );
}


export default NoResultsPlaceholder;
