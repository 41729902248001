import React, { useEffect, useMemo } from 'react';
import StandardSelect from 'components/common/molecules/StandardSelect';

import { LazyAutocompleteProps } from './LazyAutocomplete.types';

function LazyAutocomplete({
    enhancedCurrentLocation,
    fieldConfiguration,
    listData,
    dispatchSetQueryParams,
    dispatchSetListData,
}:LazyAutocompleteProps) {
    const isLoadingList = useMemo(() => listData?.isLoadingList, [listData]);

    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const queryParams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };
            dispatchSetQueryParams({ queryParams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchSetListData({ queryParams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    //TODO: add debounce and throttle
    //TODO: after react select has onSearch and onBlur, ammend the needed changes
    const onSearch = (searchTerm) =>
        fetchData({ search: searchTerm });

    useEffect(() => {
        fetchData({ limit: 10, offset: 0 });
    },
    []);

    const amendedFieldConfiguration = {
        ...fieldConfiguration,
        isLoading: isLoadingList,
        optionalConfiguration: {
            ...fieldConfiguration.optionalConfiguration,
            options: listData?.items?.map((item) => ({
                label: item[fieldConfiguration.optionalConfiguration.labelKey],
                value: item[fieldConfiguration.optionalConfiguration.valueKey],
            })),
        },
    };

    return (
        <StandardSelect fieldConfiguration={amendedFieldConfiguration} />
    );
}
export default LazyAutocomplete;
