import { TFunction } from 'i18next';
import React from 'react';

import { Card } from 'models/domain/cards';
import { PN } from 'models/domain/permissions';
import { AccessControlContent } from 'models/domain/accessControl';
import { QueryParams } from 'models/app/common';
import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import StandardValue from 'components/common/molecules/StandardValue';
import { createCardDetailsPath } from 'config/routes.helpers';
import ViewButton from 'components/common/atoms/ViewButton';
import CardTypeTag from 'components/common/atoms/StandardTag/CardTypeTag';
import CardStatusTag from 'components/common/atoms/StandardTag/CardStatusTag';


export const createCardsListColumns = (
    {
        accessControl,
        queryParams,
        t,
    }: {
        accessControl: AccessControlContent,
        queryParams: QueryParams
        t: TFunction
    },
): TableColumnConfiguration<Card>[] => [
    {
        title: 'Emboss Name',
        dataIndex: 'embossName',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Card cover',
        dataIndex: 'imageUrl',
        align: TableColumnAlign.right,
        render: (value) => value !== null ? <img src={`${value}`} alt="" style={{ height: '40px', width: 'auto' }} /> : <span>-</span>,
    },

    {
        title: 'Type',
        dataIndex: 'type',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <CardTypeTag cardType={value} />,
    },
    {
        title: 'Status',
        dataIndex: 'cardStatus',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <CardStatusTag cardStatus={value} />,
    },
    {
        title: 'Token',
        dataIndex: 'token',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Card token',
        dataIndex: 'cardToken',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} searchQuery={queryParams.search} />,
    },

    {
        title: 'Expiry',
        dataIndex: 'expiry',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },


    ...(accessControl.hasPermission(PN.CARDS.READ_BY_ID)
        ? [
            {
                title: t('common:actions'),
                dataIndex: '',
                align: TableColumnAlign.center,
                render: (_, item) => (
                    <>
                        <ViewButton
                            path={createCardDetailsPath(item?.id)}
                            title={t('common:viewDetails')}
                            useLinkVersion
                        />
                    </>

                ),
            },
        ]
        : []
    ),
];

export default { createCardsListColumns };
