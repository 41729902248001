import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useStandardDetailsPageCachingLogic from 'hook/useStandardDetailsPageCachingLogic';
import StandardTabs from 'components/common/molecules/StandardTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/molecules/StandardTabs/StandardTabs.helpers';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import { Account } from 'models/domain/accounts';
import TheSpinner from 'components/common/atoms/TheSpinner';

import { BusinessUserDetailsPageProps } from './BusinessUserDetailsPage.types';
import { createBusinessUserDetailsPageTabsConfig } from './BusinessUserDetailsPage.helpers';


function BusinessUserDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchBusinessUserDetails,
    dispatchClearBusinessUserDetails,
    dispatchSetQueryParams,
    t,
}: BusinessUserDetailsPageProps): JSX.Element {
    const { businessUserId } = useParams();
    const fixedBusinessUserId = Number(businessUserId);

    const contextEnforcedQueryParams = { businessUserId: fixedBusinessUserId };
    const baseURLSegmentsCount = 2; // e.g. /(1) beneficiary /(2) BENEFICIARY_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic<Account>({
        contextEnforcedQueryParams,
        enhancedCurrentLocation,
        entityDetailsData,
        baseUrlWithoutTabsSlugs,
        dispatchFetchEntityDetails: dispatchFetchBusinessUserDetails,
        dispatchClearEntityDetails: dispatchClearBusinessUserDetails,
        dispatchSetQueryParams,
    });

    // console.log('CardDetailsPage entityDetailsData', entityDetailsData, isDetailsPageInitialStateDefined);
    const businessUserDetailsTabsConfig = useMemo(
        () => (fixedBusinessUserId
            ? createBusinessUserDetailsPageTabsConfig({
                fixedBusinessUserId,
                accessControl,
                entityDetailsData,
                t,
            })
            : []),
        [fixedBusinessUserId, accessControl, entityDetailsData],
    );

    return (
        <StandardAuthorisedPage
            pageTitle={t('Business user details')}
        >
            {entityDetailsData?.entityDetails?.id
                ? (
                    <StandardTabs
                        tabsConfig={businessUserDetailsTabsConfig}
                    />
                )
                : <TheSpinner /> }

        </StandardAuthorisedPage>
    );
}


export default BusinessUserDetailsPage;
