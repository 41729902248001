import * as React from 'react';
import { Tbody, Tr, Td } from '@chakra-ui/react';

import { StandardDataTableBodyOwnProps } from './StandardDataListTableBody.types';
import Styled from './StandardDataListTableBody.styled';


function StandardDataTableBody<ItemDefinition>({
    columnsConfiguration,
    items = [],
    itemRowId = 'id',
}: StandardDataTableBodyOwnProps<ItemDefinition>) {
    return (
        <Tbody>
            {items.map((item) => (
                <Tr key={item[itemRowId]}>
                    {columnsConfiguration.map((column) => (
                        <Td
                            data-label={column.title}
                            align={column.align}
                            key={column.dataIndex}
                        >
                            <Styled.StandardTableCellWrapper>
                                {column.render
                                    ? column.render(item[column.dataIndex], item)
                                    : <div>{item[column.dataIndex]}</div>}
                            </Styled.StandardTableCellWrapper>
                        </Td>
                    ))}
                </Tr>
            ))}
        </Tbody>
    );
}
export default StandardDataTableBody;
