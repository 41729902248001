import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

const ScrollableContainer = styled(Box)`
  overflow: auto;
  height: 100%;
  padding-right: 10px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
  

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
   
`;


const ScrollableContainerInnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  
   
`;

export default { ScrollableContainer, ScrollableContainerInnerWrapper };
