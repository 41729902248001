import { Action } from 'models/meta/action';
import { ShowToastPayload } from 'models/app/toast';

export const INIT_APPLICATION = 'INIT_APPLICATION';
export const APPLICATION_READY_UNAUTHORISED_USER = 'APPLICATION_READY_UNAUTHORISED_USER';
export const APPLICATION_READY_AUTHORISED_USER = 'APPLICATION_READY_AUTHORISED_USER';
export const SET_SESSION_UUID = 'SET_SESSION_UUID';
export const INIT_I18NEXT = 'INIT_I18NEXT';
export const INIT_I18NEXT_SUCCESS = 'INIT_I18NEXT_SUCCESS';
export const INIT_I18NEXT_FAILURE = 'INIT_I18NEXT_FAILURE';
export const GET_JWT_TOKEN_FORM_STORAGE = 'GET_JWT_TOKEN_FORM_STORAGE';
export const REMOVE_JWT_TOKEN_FROM_STORAGE = 'REMOVE_JWT_TOKEN_FROM_STORAGE';
export const SHOW_AUTO_LOGOUT_BANNER = 'SHOW_AUTO_LOGOUT_BANNER';
export const HIDE_AUTO_LOGOUT_BANNER = 'HIDE_AUTO_LOGOUT_BANNER';
export const SET_CLIPBOARD_CONTENT = 'SET_CLIPBOARD_CONTENT';
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';

export const SET_RAED_ONLY_MODE = 'SET_RAED_ONLY_MODE';
export const CLEAR_RAED_ONLY_MODE = 'CLEAR_RAED_ONLY_MODE';
export const SHOW_TOAST = 'SHOW_TOAST';

export interface ShowToastType extends Action {
    type: typeof SHOW_TOAST;
    payload: ShowToastPayload
}

export interface InitApplicationType extends Action {
    type: typeof INIT_APPLICATION;
}
export interface ApplicationReadyUnauthorisedUserType extends Action {
    type: typeof APPLICATION_READY_UNAUTHORISED_USER;
}
export interface SetSessionUuidType extends Action {
    type: typeof SET_SESSION_UUID;
    payload
}
export interface ApplicationReadyAuthorisedUserType extends Action {
    type: typeof APPLICATION_READY_AUTHORISED_USER;
}

export const GET_COLOR_SCHEME = 'GET_COLOR_SCHEME';
export const SET_COLOR_SCHEME = 'SET_COLOR_SCHEME';

export type ApplicationActions =
    InitApplicationType |
    ApplicationReadyUnauthorisedUserType |
    // SetSessionUuidType |
    ApplicationReadyAuthorisedUserType;
