export const createCardsListFiltersConfiguration = ({ t, cardStatusOptions }) => {

    return {
        searchBy: [t('Card token')],
        sortByOptions: [
            {
                value: 'id',
                label: 'ID',
            },
            {
                value: 'type',
                label: t('Card type'),
            },
            {
                value: 'cardStatus',
                label: 'Card status',
            },
            {
                value: 'token',
                label: 'Token',
            },
        ],
        filtersConfig: [
            {
                label: 'Card status',
                queryParamName: 'cardStatus',
                filterOptions: cardStatusOptions,
                placeholderText: 'Select to narrow',
                // isMultiSelect: true, // TODO!
            },
        ],
    };
};


export default { createCardsListFiltersConfiguration };
