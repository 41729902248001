import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';

import CopyrightNotice from 'components/common/atoms/CopyrightNotice/CopyrightNotice.layout';


const decorate = compose(
    withTranslation('common'),
);

export default decorate(CopyrightNotice);

