import { HttpService } from 'services/http';

export const loginInitAPI = (requestPayload: object) => HttpService.post('/v2/bwp/auth/login/init', requestPayload);

export const loginConfirmAPI = (requestPayload: object) => HttpService.post('/v2/bwp/auth/login/confirm', requestPayload);

export const setNewPasswordAPI = (requestPayload: object) => HttpService.post('/v2/bwp/auth/password/reset', requestPayload);

export const requestPasswordResetAPI = (requestPayload: object) => HttpService.post('/v2/bwp/auth/password/forgot', requestPayload);

export const extendTokenValidityAPI = (requestPayload: object) => HttpService.put('/v2/bwp/auth/session-refresh', requestPayload);

export const getTokensForDuplicatedSessionAPI = (requestPayload: object) => HttpService.put('/v2/bwp/auth/session-duplicate', requestPayload);

export const expireTokenAPI = () => HttpService.put('/v2/auth/session-logout', undefined);
