import React from 'react';
import { Tag, TagLabel } from '@chakra-ui/react';
import { StandardTagProps } from './StandardTag.types';

function StandardTag({
    t,
    value,
    size,
    color,
    colorScheme,
}: StandardTagProps) {
    return (
        <Tag
            size={size}
            colorScheme={color || colorScheme}
        >
            <TagLabel>{t(value)}</TagLabel>
        </Tag>
    );
}

StandardTag.defaultProps = { size: 'sm' };

export default StandardTag;

