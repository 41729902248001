export const setSessionStorageObjectItem = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const getSessionStorageObjectItem = (key) => {
    const item = sessionStorage.getItem(key);
    return item && item !== 'undefined' ? JSON.parse(item) : undefined;
};

export const removeSessionStorageObjectItem = (key) => {
    sessionStorage.removeItem(key);
};


export const setLocalStorageObjectItem = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const getLocalStorageObjectItem = (key) => {
    const item = localStorage.getItem(key);
    return item && item !== 'undefined' ? JSON.parse(item) : undefined;
};

export const removeLocalStorageObjectItem = (key) => {
    localStorage.removeItem(key);
};

