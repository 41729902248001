import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import TransactionsList from 'components/common/templates/TransactionsList';

import { TransactionsPageProps } from './TransactionsPage.types';

function TransactionsPage({ t }: TransactionsPageProps): JSX.Element {

    return (
        <StandardAuthorisedPage
            pageTitle={t('Transactions')}
        >
            <TransactionsList />
        </StandardAuthorisedPage>
    );
}


export default TransactionsPage;
