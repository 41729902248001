import React, { useEffect } from 'react';
import {
    useBreakpointValue, useDisclosure,
    Box, Flex, HStack, Drawer, DrawerOverlay, DrawerContent, Image,
} from '@chakra-ui/react';

import { dashboardRootRoutePath } from 'config/routes';
import StandardLink from 'components/common/atoms/StandardLink';
import useUpdateEffect from 'hook/useUpdateEffect';
import { WL_CONFIG } from 'config/environment';

import ToggleButton from './ToggleButton';
import MainMenu from './MainMenu';
import UserDropdown from './UserDropdown';
import Sidebar from './Sidebar';


function NavBar() {
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    const [minWidth, setMinWidth] = React.useState(0);
    const [trigger, setTrigger] = React.useState(false);

    const { isOpen, onToggle, onClose } = useDisclosure();
    const isDesktop = useBreakpointValue({ base: false, lg: true }) && !isOverflowing;

    const navRef = React.useRef<HTMLDivElement>(null);


    useEffect(() => {
        setTrigger(true);
    }, []);

    useUpdateEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            const navElement = navRef.current;
            if (!navElement) return;

            const handlers = [
                {
                    predicate: () => !isOverflowing && navElement?.offsetWidth > window.innerWidth,
                    handler: () => { setIsOverflowing(true); setMinWidth(window.innerWidth); },
                },
                {
                    predicate: () => isOverflowing && navElement?.offsetWidth < window.innerWidth,
                    handler: () => { setIsOverflowing(false); },
                },
                {
                    predicate: () => isOverflowing && window.innerWidth > minWidth,
                    handler: () => { setIsOverflowing(false); },
                },
            ];

            handlers.forEach(({ predicate, handler }) => predicate() && handler());
        });

        resizeObserver.observe(document.body);
        return () => {
            resizeObserver.disconnect();
        };
    }, [isOverflowing, minWidth, trigger]);

    return (
        <Box as="nav" position="sticky" zIndex="sticky" boxShadow="md" ref={navRef}>
            <Box p={{ base: 3, lg: 4 }}>
                <Flex justify="space-between" align={'center'} gap="4">
                    <StandardLink path={dashboardRootRoutePath} title={'Dashboard'}>
                        {WL_CONFIG?.brandSignetLogoUrl
                            ? (
                                <Image
                                    src={WL_CONFIG?.brandSignetLogoUrl}
                                    boxSize="12"
                                    objectFit="contain"
                                    alt={`${WL_CONFIG?.brandFullName} logo`}
                                />
                            )
                            : <></>}
                    </StandardLink>

                    <Flex justify="center" align="center">
                        {isDesktop && (
                            <MainMenu isDesktop={isDesktop} />
                        )}
                    </Flex>

                    {isDesktop ? (
                        <HStack spacing="4">
                            <UserDropdown />
                        </HStack>
                    ) : (
                        <>
                            <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
                            <Drawer
                                isOpen={isOpen}
                                placement="left"
                                onClose={onClose}
                                isFullHeight
                                preserveScrollBarGap
                                trapFocus={false}
                                portalProps={{ appendToParentPortal: true }}
                            >
                                <DrawerOverlay />
                                <DrawerContent>
                                    <Sidebar />
                                </DrawerContent>
                            </Drawer>
                        </>
                    )}
                </Flex>
            </Box>
        </Box>

    );
}

export default NavBar;
