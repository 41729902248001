import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';

import { RootState } from 'store/rootReducer';

import { businessUserDetailsPerLocationStoreKeyName, businessUserReducerName } from 'store/business-user/reducer';
import { clearBusinessUserDetails, fetchBusinessUserDetails } from 'store/business-user/actions';
import BusinessUserDetailsPage from './BusinessUserDetailsPage.layout';
import {
    BusinessUserDetailsPageDispatchProps,
    BusinessUserDetailsPageStateProps,
} from './BusinessUserDetailsPage.types';


const mapStateToProps = (state: RootState): BusinessUserDetailsPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: businessUserReducerName,
        fieldName: businessUserDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

});

const mapDispatchToProps = (dispatch): BusinessUserDetailsPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),

    dispatchFetchBusinessUserDetails: ({ queryParams, locationPathname }) => dispatch(fetchBusinessUserDetails({ queryParams, locationPathname })),
    dispatchClearBusinessUserDetails: ({ locationPathname }) => dispatch(clearBusinessUserDetails({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: businessUserReducerName,
        fieldName: businessUserDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(BusinessUserDetailsPage);
