import React from 'react';

import { Button, IconButton } from '@chakra-ui/react';
import StandardTooltip from 'components/common/atoms/StandardTooltip';

import { StandardLinkProps } from './StandardLink.types';


function StandardLink({
    title,
    path,
    dispatchRequestNavigation,
    navigationState,
    children,
    onClick,
    dataTestId,
    leftIcon,
    rightIcon,
    icon,
    size,
    buttonVariant,
    iconVariant = 'outline',
}: StandardLinkProps): JSX.Element {
    // TODO rewrite this
    // eslint-disable-next-line no-nested-ternary
    const handleOnclick = () => (onClick && !path
        ? onClick()
        : path
            ? dispatchRequestNavigation({ locationPathname: path, meta: navigationState })
            : undefined
    );

    return (
        <StandardTooltip title={title}>
            {icon ? (
                <IconButton
                    aria-label={title}
                    size={size}
                    icon={icon}
                    data-test-id={dataTestId || 'standard-icon-link'}
                    variant={iconVariant}
                    onClick={handleOnclick}
                />
            ) : (
                <Button
                    size={size}
                    variant={buttonVariant || 'link'}
                    type="button"
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    data-test-id={dataTestId || 'standard-link'}
                    onClick={handleOnclick}
                >
                    {children || title}
                </Button>
            )}
        </StandardTooltip>

    );
}

export default StandardLink;
