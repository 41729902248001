import React from 'react';
import {
    MdReceiptLong, MdCreditCard, MdAccountBalanceWallet, MdSelfImprovement, MdHail,
    MdSupport,
} from 'react-icons/md';

import TheIcon from 'components/common/atoms/TheIcon';
import {
    accountsListRootRoutePath,
    beneficiariesListRootRoutePath,
    businessUsersListRootRoutePath,
    cardsListRootRoutePath,
    faqRootRoutePath,
    transactionsListRootRoutePath,
} from 'config/routes';

import { MainMenuItemConfig } from './MainMenu.types';


export const createMainMenuConfig = (): MainMenuItemConfig[] => ([
    // TODO: make link over WL-aware-logo to Dashboard instead
    // {
    //     icon: <TheIcon Icon={MdDeck} />,
    //     text: 'Dashboard',
    //     pathname: dashboardRootRoutePath,
    // },
    {
        icon: <TheIcon Icon={MdAccountBalanceWallet} />,
        text: 'Accounts',
        pathname: accountsListRootRoutePath,
    },
    {
        icon: <TheIcon Icon={MdCreditCard} />,
        text: 'Cards',
        pathname: cardsListRootRoutePath,
    },
    {
        icon: <TheIcon Icon={MdReceiptLong} />,
        text: 'Transactions',
        pathname: transactionsListRootRoutePath,
    },
    {
        icon: <TheIcon Icon={MdSelfImprovement} />,
        text: 'Users',
        pathname: businessUsersListRootRoutePath,
    },
    {
        icon: <TheIcon Icon={MdHail} />,
        text: 'Beneficiaries',
        pathname: beneficiariesListRootRoutePath,
    },
    {
        icon: <TheIcon Icon={MdSupport} />,
        text: 'F.A.Q',
        pathname: faqRootRoutePath,
    },
]);

export default { createMainMenuConfig };
