import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useStandardDetailsPageCachingLogic from 'hook/useStandardDetailsPageCachingLogic';
import StandardTabs from 'components/common/molecules/StandardTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/molecules/StandardTabs/StandardTabs.helpers';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import { Account } from 'models/domain/accounts';
import TheSpinner from 'components/common/atoms/TheSpinner';

import { BeneficiaryDetailsPageProps } from './BeneficiaryDetailsPage.types';
import { createBusinessUserDetailsPageTabsConfig } from './BeneficiaryDetailsPage.helpers';


function BeneficiaryDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchBeneficiaryDetails,
    dispatchClearBeneficiaryDetails,
    dispatchSetQueryParams,
    t,
}: BeneficiaryDetailsPageProps): JSX.Element {
    const { beneficiaryUuid } = useParams();

    const contextEnforcedQueryParams = { beneficiaryUuid };
    const baseURLSegmentsCount = 2; // e.g. /(1) beneficiary /(2) BENEFICIARY_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic<Account>({
        contextEnforcedQueryParams,
        enhancedCurrentLocation,
        entityDetailsData,
        baseUrlWithoutTabsSlugs,
        dispatchFetchEntityDetails: dispatchFetchBeneficiaryDetails,
        dispatchClearEntityDetails: dispatchClearBeneficiaryDetails,
        dispatchSetQueryParams,
    });

    const beneficiaryDetailsTabsConfig = useMemo(
        () => (beneficiaryUuid
            ? createBusinessUserDetailsPageTabsConfig({
                beneficiaryUuid,
                accessControl,
                entityDetailsData,
                t,
            })
            : []),
        [beneficiaryUuid, accessControl, entityDetailsData],
    );

    return (
        <StandardAuthorisedPage
            pageTitle={t('Beneficiary details')}
        >
            {entityDetailsData?.entityDetails?.id
                ? (
                    <StandardTabs
                        tabsConfig={beneficiaryDetailsTabsConfig}
                    />
                )
                : <TheSpinner /> }

        </StandardAuthorisedPage>
    );
}


export default BeneficiaryDetailsPage;
