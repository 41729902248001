import React from 'react';
import { MdReceiptLong, MdPerson } from 'react-icons/md';

import { PN } from 'models/domain/permissions';
import TheIcon from 'components/common/atoms/TheIcon';
import TransactionsList from 'components/common/templates/TransactionsList';


export const createBusinessUserDetailsPageTabsConfig = ({
    beneficiaryUuid,
    accessControl,
    entityDetailsData,
    t,
}) => [
    ...(accessControl.hasPermission(PN.ACCOUNTS.READ_BY_ID) ? [
        {
            tabLabel: t('Business user details'),
            tabIcon: <TheIcon Icon={MdPerson} />,
            children: (
                <pre>
                    {JSON.stringify(entityDetailsData, null, 2)}
                </pre>
            ),
        },
    ] : []),

    {
        tabLabel: t('Transactions'),
        tabIcon: <TheIcon Icon={MdReceiptLong} />,
        children: (
            <TransactionsList beneficiaryUuid={beneficiaryUuid} />
        ),
    },

];


export default { createBusinessUserDetailsPageTabsConfig };
