import {
    loginInitAPI,
    loginConfirmAPI,
    setNewPasswordAPI,
    requestPasswordResetAPI,
    extendTokenValidityAPI,
    expireTokenAPI, getTokensForDuplicatedSessionAPI,
} from './index';

class AuthorisationService {
    public loginInit(rawRequestPayload) {
        const { email, password } = rawRequestPayload;
        return loginInitAPI({ email, password });
    }

    public loginConfirm(payload) {
        const requestPayload = { ...payload, groupPermissions: true };
        return loginConfirmAPI(requestPayload);
    }

    public setNewPassword(requestPayload) {
        return setNewPasswordAPI(requestPayload);
    }

    public requestPasswordReset(requestPayload) {
        return requestPasswordResetAPI(requestPayload);
    }

    public extendTokenValidity(refresh_token): Promise<any> {
        return extendTokenValidityAPI({ refresh_token });
    }

    public getTokensForDuplicatedSession(requestPayload) {
        return getTokensForDuplicatedSessionAPI(requestPayload);
    }

    public expireToken() {
        return expireTokenAPI();
    }
}

export default AuthorisationService;

