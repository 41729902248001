import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';
import { setClipboardContent } from 'store/application/actions';
import { connect } from 'react-redux';
import { applicationReducerName } from 'store/application/reducer';
import StandardTooltip from './StandardTooltip.layout';
import { StandardTooltipDispatchProps, StandardTooltipOwnProps, StandardTooltipStateProps } from './StandardTooltip.types';


const mapStateToProps = (state, ownProps):StandardTooltipStateProps => {
    const props = ownProps
        .showCopyToClipboard
        ? { clipboardContent: state.application.clipboardContent }
        : {};
    return { ...props, colorScheme: state[applicationReducerName].colorScheme };
};
const mapDispatchToProps = (dispatch, ownProps) =>
    <StandardTooltipDispatchProps> (ownProps.showCopyToClipboard ? { dispatchSetClipboardContent: (text) => dispatch(setClipboardContent(text)) } : {});


const decorate = compose<ComponentType<StandardTooltipOwnProps>>(withTranslation('common'), connect(mapStateToProps, mapDispatchToProps));

export default decorate(StandardTooltip);
