import styled from 'styled-components';


const RefreshButtonWrapper = styled.div`
  padding: 0;
  margin-bottom: 15px;
`;


export default { RefreshButtonWrapper };
