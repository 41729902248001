import React, { useMemo } from 'react';

import { accountListQueryParams } from 'store/accounts/reducer';
import useStandardListCachingLogic from 'hook/useStandardListCachingLogic';
import { Account } from 'models/domain/accounts';
import StandardDataTable from 'components/common/organisms/StandardDataList';
import { createCurrencyOptions } from 'utils/options-crators';

import { AccountsListProps } from './AccountsList.types';
import { createAccountsListColumns } from './tableColumns';
import { createAccountsListFiltersConfiguration } from './AccountsList.helpers';


function AccountsList({
    // own props
    isInReadOnlyMode = false,
    companyId,
    businessUserId,

    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    currenciesListData,
    dispatchFetchAccountsList,
    dispatchClearAccountsList,
    dispatchSetQueryParams,
    t,
}: AccountsListProps): JSX.Element {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        companyId,
        businessUserId,
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchFetchAccountsList({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic<Account>({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: accountListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchAccountsList,
        dispatchClearItemsList: dispatchClearAccountsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional account list filters & buttons
    const currencyOptions = useMemo(
        () => currenciesListData?.items?.length > 0
            ? createCurrencyOptions({ currencies: currenciesListData.items })
            : [],
        [currenciesListData],
    );


    // 5. standard render component
    return (
        <StandardDataTable<Account>
            t={t}
            fetchData={fetchData}
            totalCount={listData.totalCount}
            items={listData.items}
            isLoadingData={listData.isLoadingList}
            queryParams={listData.queryParams}
            isListInitialStateDefined={isListInitialStateDefined}
            columnsConfiguration={createAccountsListColumns({
                t,
                accessControl,
                queryParams: listData.queryParams,
            })}
            filtersConfiguration={createAccountsListFiltersConfiguration({ t, currencyOptions })}
        />
    );
}


export default AccountsList;

