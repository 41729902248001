import React from 'react';
import { WL_CONFIG } from 'config/environment';
import Styled from './AppLoadingPlaceholder.styled';
import { renderLoadingDetails } from './AppLoadingPlaceholder.helpers';

function AppLoadingPlaceholder({
    currencies,
    countries,
    t,
    accessControl,
}): JSX.Element {
    return (
        <Styled.AppLoadingPlaceholder>
            <Styled.BrandAnimatedLoadingPlaceholder>
                <img
                    src={WL_CONFIG.brandAnimatedLoadingPlaceholderUrl || '/default-animated-loading-placeholder.svg'}
                    alt="animated placeholder"
                />
            </Styled.BrandAnimatedLoadingPlaceholder>

            {renderLoadingDetails({
                currencies,
                countries,
                accessControl,
                t,
            })}
        </Styled.AppLoadingPlaceholder>
    );
}

export default AppLoadingPlaceholder;

