import React from 'react';
import {
    Card, CardBody, CardFooter, CardHeader, Divider, Heading,
} from '@chakra-ui/react';

import AnimatedPageWrapper from 'components/App/AnimatedPageWrapper';

import { StandardUnauthorisedPageProps } from './StandardUnauthorisedPage.types';

function StandardUnauthorisedPage({
    children,
    pageTitle,
    footerContent,
}: StandardUnauthorisedPageProps): JSX.Element {
    return (
        <AnimatedPageWrapper>
            <Card>
                <CardHeader>
                    <Heading size="md">{pageTitle}</Heading>
                </CardHeader>

                <Divider />

                <CardBody>
                    {children}
                </CardBody>

                {footerContent
                    ? (
                        <>
                            <Divider />

                            <CardFooter>
                                {footerContent}
                            </CardFooter>
                        </>
                    ) : <></>}


            </Card>
        </AnimatedPageWrapper>
    );
}

export default StandardUnauthorisedPage;
