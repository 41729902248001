import { Amount, CurrencyIsoCode, DateFullIsoString, ExchangeRate, NumericId } from 'models/app/common';
import { Currency } from './currencies';

export enum TransactionStatus {
    Pending = 'Pending',
    Approved = 'Approved',
    Completed = 'Completed',
    Rejected = 'Rejected',
    Reversed = 'Reversed',
    Failed = 'Failed',
    Unknown = 'Unknown',
}
export enum TransactionType {
    EXT = 'EXT', // External
    INT = 'INT', // Internal
    CT = 'CT', // Card Transactions
    FEE = 'FEE',
}
export enum TransactionScope {
    IBT = 'IBT', // INCOMING BANK TRANSFER
    OBT = 'OBT', // OUTGOING BANK TRANSFER

    CT = 'CT', // CARD TOP-UP
    APM = 'APM', // APM TOP-UP
    CV = 'CV', // Top-up Card Verification
    CVR = 'CVR', // Top-up Card Verification Refund

    IP2P = 'IP2P', // INCOMING P2P
    OP2P = 'OP2P', // OUTGOING P2P
    AC = 'AC', // ADMIN CREDIT
    AD = 'AD', // ADMIN CREDIT,
    EXF = 'EXF', // EXCHANGE FROM
    EXT = 'EXT', // EXCHANGE TO

    ATM = 'ATM', // ATM WITHDRAWAL
    POS = 'POS', // point of sale
    CR = 'CR', // REFUND
    C = 'C', // (FEE) CREDIT
    D = 'D', // (FEE) DEBIT,

    BWC = 'BWC', // BALANCE WITHDRAW CREDIT (negative balance)
    BWD = 'BWD', // BALANCE WITHDRAW DEBIT (positive balance)
}


export interface TransactionParty {
    name: string,
    type: string,
    accountId: NumericId,
    clientId: NumericId,
    businessUserid: NumericId,
    companyId: NumericId,
    memberId: NumericId,
    userId: NumericId,
}
export interface LinkedTransaction {
    id: NumericId | boolean,
    isExchangeTransaction: boolean,
    isFraudTransaction: boolean,
    isP2pTransaction: boolean,
    isRevertedTransaction: boolean,
}
export interface ExchangeOffer {
    fee: string,
    id: NumericId,
    rate: ExchangeRate, // TODO different type, decimal precision is grater than standard 2
    receiveAmount: Amount,
    receiveCurrency: CurrencyIsoCode,
    sendAmount: Amount,
    sendCurrency: CurrencyIsoCode,
}
export interface Transaction {
    // businessUserId: NumericId,
    clientId: NumericId,
    companyId: NumericId,
    id: NumericId,
    status: TransactionStatus,
    transactionType: TransactionType,
    transactionScope: TransactionScope,
    createdAt: DateFullIsoString,
    amount: Amount,
    currency: CurrencyIsoCode,
}

export interface TransactionDetails extends Transaction {
    // businessUserId: NumericId,
    hvtFlag: boolean,
    reference: string,

    receiver: TransactionParty,
    sender: TransactionParty,
    systemDescription: string,
    amlReview: boolean,
    authType: any, // TODO
    updatedAt: DateFullIsoString,
    deletedAt: DateFullIsoString,
    reason?: string,
    processedCurrency: Currency,
    processedAmount: Amount,
    convertedCurrency: Currency,
    convertedAmount: Amount,
    linkedTransaction: LinkedTransaction,
    exchangeOffer: ExchangeOffer
}
