import styled from 'styled-components';
import { Tooltip } from '@chakra-ui/react';


const StandardTooltipWithOverriddenStyles = styled(Tooltip)`
  outline: 1px solid transparent;
  max-width: 700px;
`;

const CopyTransparentOverlay = styled.span`
  cursor: pointer;
  color: inherit;
`;
const StandardOverlay = styled.span`
  cursor: help !important;
`;

const TooltipCopy2Clipboard = styled.div`
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid white;
  text-align: center;
  font-size: 11px;
`;

const TooltipValue = styled.div<{ isAmount:boolean }>`
  text-align: center;
  font-weight: 600;
 
`;

export default {
    StandardTooltipWithOverriddenStyles,
    CopyTransparentOverlay,
    StandardOverlay,
    TooltipCopy2Clipboard,
    TooltipValue,
};
