import { APPLICATION_STATE } from 'models/app/applicationState';
import { createReducer } from '@reduxjs/toolkit';

import { ColorScheme } from 'models/app/theme';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    APPLICATION_READY_AUTHORISED_USER,
    APPLICATION_READY_UNAUTHORISED_USER,
    HIDE_AUTO_LOGOUT_BANNER,
    INIT_APPLICATION,
    INIT_I18NEXT_SUCCESS,
    SET_CLIPBOARD_CONTENT, SET_COLOR_SCHEME,
    SET_SESSION_UUID,
    SHOW_AUTO_LOGOUT_BANNER,
} from './action.types';

export interface ApplicationStateType {
    readonly sessionUuid?: string;
    readonly isI18nReady: boolean;
    readonly applicationState;
    readonly isAutoLogoutBannerVisible: boolean;

    readonly clipboardContent: string;
    readonly colorScheme: ColorScheme;
}
const initialState: ApplicationStateType = {
    sessionUuid: undefined,
    isI18nReady: false,
    applicationState: undefined,
    isAutoLogoutBannerVisible: false,
    clipboardContent: '',
    colorScheme: ColorScheme.orange,
};
export const applicationReducerName = 'application';

const handlers = {
    [INIT_APPLICATION]: (state) => {
        state.applicationState = APPLICATION_STATE.APPLICATION_STARTED;
    },
    [SET_COLOR_SCHEME]: (state, action) => {
        state.colorScheme = action.payload;
    },
    [APPLICATION_READY_AUTHORISED_USER]: (state) => {
        state.applicationState = APPLICATION_STATE.AUTHORISED_READY;
    },
    [APPLICATION_READY_UNAUTHORISED_USER]: (state) => {
        state.applicationState = APPLICATION_STATE.UNAUTHORISED_READY;
    },
    [SET_SESSION_UUID]: (state, action) => {
        state.sessionUuid = action.payload;
    },
    [INIT_I18NEXT_SUCCESS]: (state) => {
        state.isI18nReady = true;
    },
    [SHOW_AUTO_LOGOUT_BANNER]: (state) => {
        state.isAutoLogoutBannerVisible = true;
    },
    [HIDE_AUTO_LOGOUT_BANNER]: (state) => {
        state.isAutoLogoutBannerVisible = false;
    },
    [SET_CLIPBOARD_CONTENT]: (state, action) => {
        state.clipboardContent = action.payload;
    },
    [CLEAR_CURRENT_USER]: (state) => {
        state.isAutoLogoutBannerVisible = false;
        state.clipboardContent = '';
        state.applicationState = APPLICATION_STATE.UNAUTHORISED_READY;
    },

};

export default createReducer(initialState, handlers);
