import { createReducer } from '@reduxjs/toolkit';

import {
    CLEAR_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS_FAILURE,
    FETCH_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_ADDRESS, UPDATE_COMPANY_ADDRESS_FAILURE,
    UPDATE_COMPANY_ADDRESS_SUCCESS,
    UPDATE_COMPANY_FAILURE,
    UPDATE_COMPANY_SUCCESS,
} from './actions.types';
import { CLEAR_CURRENT_USER } from '../current-user/actions.types';

export const companyReducerName = 'company';
export const companyDetailsPerLocationStoreKeyName = 'companyDetailsPerLocation';

const initialState = {
    companyDetails: {},
    isLoadingCompanyDetails: false,
    isLoadingCompanyUpdate: false,
    isLoadingCompanyAddressUpdate: false,
};

const handlers = {
    [FETCH_COMPANY_DETAILS]: (state) => {
        state.isLoadingCompanyDetails = true;
    },
    [FETCH_COMPANY_DETAILS_SUCCESS]: (state, action) => {
        state.isLoadingCompanyDetails = false;
        state.companyDetails = action.payload;
    },
    [FETCH_COMPANY_DETAILS_FAILURE]: (state) => {
        state.isLoadingCompanyDetails = false;
    },


    [UPDATE_COMPANY]: (state) => {
        state.isLoadingCompanyUpdate = true;
    },
    [UPDATE_COMPANY_SUCCESS]: (state, action) => {
        state.isLoadingCompanyUpdate = false;
        state.companyDetails = {
            ...state.companyDetails,
            ...action.payload,
        };
    },
    [UPDATE_COMPANY_FAILURE]: (state) => {
        state.isLoadingCompanyUpdate = false;
    },


    [UPDATE_COMPANY_ADDRESS]: (state) => {
        state.isLoadingCompanyAddressUpdate = true;
    },
    [UPDATE_COMPANY_ADDRESS_SUCCESS]: (state, action) => {
        state.isLoadingCompanyAddressUpdate = false;
        state.companyDetails = {
            ...state.companyDetails,
            address: action.payload,
        };
    },
    [UPDATE_COMPANY_ADDRESS_FAILURE]: (state) => {
        state.isLoadingCompanyAddressUpdate = false;
    },


    [CLEAR_COMPANY_DETAILS]: () => {
        return initialState;
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
