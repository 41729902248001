import React from 'react';
import { TFunction } from 'i18next';

import { PN } from 'models/domain/permissions';
import StandardValue from 'components/common/molecules/StandardValue';
import { StandardValueType } from 'models/app/standardValues';
import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import { QueryParams } from 'models/app/common';
import { Account } from 'models/domain/accounts';
import { AccessControlContent } from 'models/domain/accessControl';
import { createAccountDetailsPath } from 'config/routes.helpers';
import FlagAndValue from 'components/common/molecules/FlagAndValue';
import ViewButton from 'components/common/atoms/ViewButton';


const createIdColumn = ({ queryParams }: { queryParams: QueryParams }): TableColumnConfiguration<Account>[] => ([{
    title: 'ID',
    dataIndex: 'id',
    align: TableColumnAlign.right,
    isSortable: true,
    render: (value) => <StandardValue value={value} />,
}]);

const createCommonColumns = (
    {
        accessControl,
        queryParams,
        t,
    }: {
        accessControl: AccessControlContent,
        queryParams: QueryParams
        t: TFunction
    },
): TableColumnConfiguration<Account>[] => [
    {
        title: 'Account Type',
        dataIndex: 'type',
        align: TableColumnAlign.center,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
        // render: (type) => <AccountTypeTag accountType={type} showTooltip />,
    },
    {
        title: 'Primary',
        align: TableColumnAlign.center,
        dataIndex: 'is_primary',
        // render: (text, record) => booleanAsIconsRenderer(record, 'is_primary', t),
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Account Balance',
        align: TableColumnAlign.right,
        dataIndex: 'balance',
        render: (balance) => <StandardValue value={balance} valueType={StandardValueType.AMOUNT} />,
    },
    {
        title: 'Blocked balance',
        align: TableColumnAlign.right,
        dataIndex: 'blocked_balance',
        render: (blockedBalance) => <StandardValue value={blockedBalance} valueType={StandardValueType.AMOUNT} />,
    },
    {
        title: 'Available balance',
        align: TableColumnAlign.right,
        dataIndex: 'available_balance',
        render: (availableBalance) => <StandardValue value={availableBalance} valueType={StandardValueType.AMOUNT} />,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        isSortable: true,
        render: (text, record) => <FlagAndValue currencyCode={record.currency} text={record.currency} showTooltip />,
    },
    ...(accessControl.hasOneOfPermissions([
        PN.ACCOUNTS.READ_BY_ID,
        PN.TRANSACTIONS.READ_ALL,
        PN.TRANSACTIONS.WITHDRAW_BALANCE,
    ])
        ? [
            {
                title: t('common:actions'),
                dataIndex: '',
                align: TableColumnAlign.center,
                render: (_, item) => (
                    <>
                        <ViewButton
                            path={createAccountDetailsPath(item?.id)}
                            title={t('common:viewDetails')}
                            useLinkVersion
                        />
                    </>

                ),
            },
        ]
        : []
    ),

];

const createNameColumn = () => [{
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <StandardValue value={text} />,
}];


export const createAccountsListColumns = ({ t, queryParams, accessControl }): TableColumnConfiguration<Account>[] => {


    return [
        ...createIdColumn(queryParams),
        ...createNameColumn(),
        ...createCommonColumns({
            accessControl,
            queryParams,
            t,
        }),
    ];
};

export default { createAccountsListColumns };
