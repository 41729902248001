class FileService {
    public static saveBlobFile({
        blob,
        fileName,
        mimeType,
        document,
    }) {
        const blobObject = new Blob([blob], { type: mimeType || 'application/octet-stream' });
        const blobURL = window.URL.createObjectURL(blobObject);
        const htmlAnchorElement = document.createElement('a');

        htmlAnchorElement.style.display = 'none';
        htmlAnchorElement.href = blobURL;
        htmlAnchorElement.download = fileName;
        htmlAnchorElement.href = blobURL;
        htmlAnchorElement.dataset.downloadurl = [mimeType, htmlAnchorElement.download, htmlAnchorElement.href].join(':');

        document.body.appendChild(htmlAnchorElement);
        htmlAnchorElement.click(); // XXX actual file save process
        document.body.removeChild(htmlAnchorElement);
        setTimeout(() => {
            // For FireFox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }
}

export default FileService;
