import { createAction } from '@reduxjs/toolkit';
import {
    ADD_CARD, ADD_CARD_SUCCESS, ADD_CARD_FAILURE, CLEAR_CARDS_LIST, FETCH_CARDS_LIST, FETCH_CARDS_LIST_FAILURE, FETCH_CARDS_LIST_SUCCESS,
} from './actions.types';

export const fetchCardsList = createAction(FETCH_CARDS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardsListSuccess = createAction(
    FETCH_CARDS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchCardsListFailure = createAction(FETCH_CARDS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearCardsList = createAction(CLEAR_CARDS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const addCard = createAction(ADD_CARD, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const addCardSuccess = createAction(ADD_CARD_SUCCESS, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const addCardFailure = createAction(ADD_CARD_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
