export enum FormInputTypes {
    TEXT = 'TEXT',
    EMAIL = 'EMAIL',
    TEXT_MULTI_LINE = 'TEXT_MULTI_LINE',
    PASSWORD = 'PASSWORD',
    COLOR = 'COLOR',
    LAZY_AUTOCOMPLETE_SINGLE_VALUE = 'LAZY_AUTOCOMPLETE_SINGLE_VALUE',


    PIN = 'PIN',
    INTEGER = 'INTEGER',
    DECIMAL = 'DECIMAL',

    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',

    SWITCH = 'SWITCH',
    CHECK_BOX = 'CHECK_BOX',
    RADIO = 'RADIO',
    FANCY_RADIO = 'FANCY_RADIO',

    SELECT_SINGLE_VALUE = 'SELECT_SINGLE_VALUE',
    SELECT_MULTI_VALUES = 'SELECT_MULTI_VALUES',

    CUSTOM = 'CUSTOM',

}
export interface SelectOption {
    value: string | number;
    label: string;
    variant?: string,
    hintText?: string,
}

export enum SelectVariant {
    outline = 'outline',
    filled = 'filled',
    flushed = 'flushed',
    unstyled = 'unstyled',
}


export interface CommonFormFieldConfigItem {
    fieldType: FormInputTypes,
    name: string,
    id: string,
    labelText: string,
    placeholderText?: string,
    hintText?: string,
    errorText?: string,
    value?: string | number,
    dataTestId?: string,

    inlineLabel?: boolean,
    isLoading?: boolean,
    children?: JSX.Element,
    inputLeftElement?: JSX.Element,
    inputRightElement?: JSX.Element,
    optionalConfiguration?: {
        labelKey?: string,
        valueKey?: string,
        reducerName?: string,
        listsByLocationStoreKeyName?: string,
        reduxSetter?: (value: any) => void,
        isRequired?: boolean,
        options?: SelectOption[],
        isMultiSelect?: boolean,
        isInvalid?: boolean,
        isReadOnly?: boolean,
        isClearable?: boolean,
        isDisabled?: boolean,
        isSearchable?: boolean,
        variant?: SelectVariant,
        size?: 'sm' | 'md' | 'lg'
    },
    onChange?: (eventOrValue: any) => void,
    onBlur?: (eventOrValue: any) => void,
    onFocus?: (eventOrValue: any) => void,
    onKeyDown?: (eventOrValue: any) => void,
    onSearch?: (option:any, inputValue:string) => boolean,
}
