import {
    combineLatest, EMPTY, from, map, of, take,
} from 'rxjs';
import { createStandaloneToast } from '@chakra-ui/react';
import { ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { initReactI18next } from 'react-i18next';

import { i18nFormat, namespaces, resources } from 'config/i18next.helpers';
import { clientConfigKey, colorSchemeKey, defaultLocale, permissionsKey, userPreferencesKey } from 'config/config';
import { clearJwt, getJwt, isTokenNotExpired } from 'utils/jwtToken';
import copyToClipboard from 'utils/copy-to-clipboard';
import {
    applicationReadyAuthorisedUser,
    applicationReadyUnauthorisedUser,
    initI18nextFailure,
    initI18nextSuccess,
    removeJwtTokenFromStorage, setColorScheme,
} from 'store/application/actions';
import { getRememberedUserEmail, getTokensForDuplicatedTab } from 'store/authorisation/actions';
import { setCurrentUser } from 'store/current-user/actions';
import { createAppInitActionsAndSideEffects, refreshToken } from 'store/application/epics.helpers';
import { getLocalStorageObjectItem, getSessionStorageObjectItem, removeSessionStorageObjectItem, setLocalStorageObjectItem } from 'utils/browser-storage';
import { debugMode } from 'config/environment';
import { Epic } from 'models/meta/epic';
import { loginRootRoutePath, rootRoutePath } from 'config/routes';
import { SET_CURRENT_USER_SUCCESS } from 'store/current-user/actions.types';
import { FETCH_COUNTRIES_SUCCESS } from 'store/countries/actions.types';
import { FETCH_CURRENCIES_SUCCESS } from 'store/currencies/actions.types';
import { requestNavigation } from 'store/navigation/actions';

import { ColorScheme } from 'models/app/theme';
import { ToastPosition, ToastVariant } from 'models/app/toast';

import {
    APPLICATION_READY_UNAUTHORISED_USER, GET_COLOR_SCHEME,
    GET_JWT_TOKEN_FORM_STORAGE,
    INIT_APPLICATION,
    INIT_I18NEXT,
    INIT_I18NEXT_SUCCESS,
    REMOVE_JWT_TOKEN_FROM_STORAGE,
    SET_CLIPBOARD_CONTENT, SET_COLOR_SCHEME,
    SET_SESSION_UUID, SHOW_TOAST,
} from './action.types';


export const onInitApplication: Epic = (action$, _, { http, history }) => {
    return action$.pipe(
        ofType(INIT_APPLICATION),
        mergeMap(() => {
            return createAppInitActionsAndSideEffects(http, history);
        }),
    );
};

export const onInitI18next:Epic = (action$, _, { i18n }) => {
    return action$.pipe(
        ofType(INIT_I18NEXT),
        switchMap(() => {
            return from(
                i18n.use(initReactI18next)
                    .init({
                        debug: debugMode,
                        lng: defaultLocale,
                        // TODO simplest way to have this working, eventually should be loaded by http backend plugin
                        // https://github.com/i18next/i18next-http-backend
                        resources,
                        ns: namespaces,
                        interpolation: {
                            format: i18nFormat,
                            escapeValue: false,
                        },
                        load: 'currentOnly',
                        fallbackLng: defaultLocale,
                        react: { useSuspense: false },
                        saveMissing: debugMode,
                        /* eslint-disable-next-line max-params */ // XXX this is an external lib, this one has to be ignored by eslintcc
                        missingKeyHandler: (lngs, ns, key, fallbackValue) => {
                            if (debugMode) {
                                // eslint-disable-next-line no-console
                                console.warn(`Missing ‘${lngs.join('’, ’')}’ translation key\n  key: ‘${ns}:${key}’\n  fallback: ‘${fallbackValue}’\n`);
                            }
                        },
                    }),
            ).pipe(
                switchMap(() => of(initI18nextSuccess())),
                catchError(() => of(initI18nextFailure())),
            );
        }),
    );
};


export const onGetJwtTokenFormStorage: Epic = (action$, _, { authorisation }) => {
    return action$.pipe(
        ofType(GET_JWT_TOKEN_FORM_STORAGE),
        switchMap(({ payload }) => {
            const jwtToken = getJwt();
            const permissions = getSessionStorageObjectItem(permissionsKey);
            const client_config = getSessionStorageObjectItem(clientConfigKey);
            const userPreferences = getSessionStorageObjectItem(userPreferencesKey);

            // XXX special case - payload is present
            if (payload && jwtToken) {
                return of(
                    getTokensForDuplicatedTab({
                        session_uuid: payload,
                        access_token: jwtToken.access_token,
                    },
                    { permissions, client_config, userPreferences }),
                );
            }

            if (jwtToken && isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) {
                return of(setCurrentUser({ ...jwtToken, permissions, client_config, userPreferences }));
            }

            if (jwtToken
        && !isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)
        && isTokenNotExpired(jwtToken.expirationTimeOfRefreshToken)
            ) {
                refreshToken(jwtToken, authorisation);
            }

            if (jwtToken) {
                return of(removeJwtTokenFromStorage());
            }

            return EMPTY;

        }),
    );
};

export const onGetColorScheme: Epic = (action$) => {
    return action$.pipe(
        ofType(GET_COLOR_SCHEME),
        switchMap(() => {
            const colorScheme = getLocalStorageObjectItem(colorSchemeKey) as ColorScheme || ColorScheme.orange;
            return of(setColorScheme(colorScheme));
        }),
    );
};
export const onSetColorScheme: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_COLOR_SCHEME),
        tap(({ payload }) => {
            setLocalStorageObjectItem(colorSchemeKey, payload);
        }),
        switchMap(() => EMPTY),
    );
};

export const onRemoveJwtTokenFromStorage: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(REMOVE_JWT_TOKEN_FROM_STORAGE),
        tap(
            () => {
                clearJwt();
                removeSessionStorageObjectItem(permissionsKey);
                removeSessionStorageObjectItem(clientConfigKey);
                removeSessionStorageObjectItem(userPreferencesKey);
            },
        ),
        switchMap(() => EMPTY),
    );
};

export const onSetClipboardContent: Epic = (action$) => {
    return action$.pipe(
        ofType(SET_CLIPBOARD_CONTENT),
        tap(({ payload }) => copyToClipboard(payload)),
        switchMap(() => EMPTY),
    );
};

export const onInitApplicationUnauthorisedFlow: Epic = (action$) => combineLatest([
    action$.pipe(ofType(INIT_APPLICATION)),
    action$.pipe(ofType(SET_SESSION_UUID)),
    action$.pipe(ofType(INIT_I18NEXT_SUCCESS)),
]).pipe(
    take(1),
    takeUntil(action$.pipe(ofType(SET_CURRENT_USER_SUCCESS))),
    map(() => {
        const jwtToken = getJwt();
        // XXX user is logged-in -> skip applicationReadyUnauthorisedUser to avoid routes ability changes and possible unwanted redirection
        if (jwtToken && isTokenNotExpired(jwtToken.expirationTimeOfAccessToken)) {
            return EMPTY;
        }
        return applicationReadyUnauthorisedUser();
    }),
    catchError(() => EMPTY),
);

export const onInitApplicationAuthorisedFlow: Epic = (action$) => combineLatest([
    // afterLogin initial data fetching
    action$.pipe(ofType(SET_CURRENT_USER_SUCCESS)),
    action$.pipe(ofType(FETCH_COUNTRIES_SUCCESS)),
    action$.pipe(ofType(FETCH_CURRENCIES_SUCCESS)),
]).pipe(
    // takeUntil(action$.pipe(ofType(APPLICATION_READY_AUTHORISED_USER))),
    map(() => applicationReadyAuthorisedUser()),
);

//const onEndUiLoading: Epic = action$ =>
//     combineLatest([
//         action$.pipe(ofType(GET_LOCALES_MANIFEST_SUCCESS, GET_LOCALES_MANIFEST_FAILURE)),
//         action$.pipe(ofType(GET_COUNTRIES_INFO_SUCCESS, GET_COUNTRIES_INFO_FAILURE)),
//         action$.pipe(ofType(GET_NACE_INFO_SUCCESS, GET_NACE_INFO_FAILURE)),
//     ]).pipe(
//         map(endUiLoading)
//     );
//            // TODO TS issue with observable types
// eslint-disable-next-line max-len
//             // @ts-expect-error Argument of type 'MonoTypeOperatorFunction<[Action<any, any, any>, Action<any, any, any>]>' is not assignable to parameter of type 'OperatorFunction<[Action<any, any, any>, Action<any, any, any>], void>'.
//

export const onApplicationReadyUnauthorisedUser: Epic = (action$, state$, { history }) => {
    return action$.pipe(
        ofType(APPLICATION_READY_UNAUTHORISED_USER),
        switchMap(() => {
            const { pathname } = history.location;
            const nextLocation = pathname === rootRoutePath
                ? loginRootRoutePath
                : undefined;

            if (nextLocation) {
                return of(
                    getRememberedUserEmail(),
                    requestNavigation({ locationPathname: nextLocation, meta: { replace: true } }),
                );
            }


            return of(getRememberedUserEmail());
        }),
    );
};


export const onShowToast: Epic = (action$) => {
    return action$.pipe(
        ofType(SHOW_TOAST),
        switchMap(({
            payload: {
                type,
                message,
                description,
                options,
            },
        }) => {
            const { toast } = createStandaloneToast();

            toast({
                title: message,
                description,
                status: type,
                variant: ToastVariant.subtle,
                position: ToastPosition.bottomLeft,
                duration: 8000,
                isClosable: true,
            });

            return EMPTY;
        }),
    );
};

export default [
    onInitApplication,
    onInitApplicationUnauthorisedFlow,
    onInitApplicationAuthorisedFlow,
    onApplicationReadyUnauthorisedUser,
    onInitI18next,
    onGetJwtTokenFormStorage,
    onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
    onGetColorScheme,
    onSetColorScheme,
];
