import { useEffect, useMemo, useState } from 'react';
import { EnhancedLocation, globalLocation, LocationChangeActionScopeTypes, NavigationDirectionTypes } from 'models/app/navigation';
import { StandardListData } from 'models/app/standardLists';
import { IdQueryParams, QueryParams } from 'models/app/common';


export interface UseStandardListCachingLogic <Items> {
        useGlobalLocation?: boolean,
        customLocation?: string,
        omitDataFetching?: boolean,

        contextEnforcedQueryParams?: IdQueryParams,
        defaultItemsListQueryParams: QueryParams,

        enhancedCurrentLocation: EnhancedLocation,
        listData: StandardListData<Items>,

        dispatchFetchItemsList: ({ queryParams, locationPathname }: { queryParams: QueryParams, locationPathname: string }) => void,
        dispatchClearItemsList: ({ locationPathname }: { locationPathname: string }) => void,
        dispatchSetQueryParams:({ queryParams, locationPathname }: { queryParams: QueryParams, locationPathname: string }) => void,
}


export function useStandardListCachingLogic<Items>({
    useGlobalLocation = false,
    customLocation,
    omitDataFetching = false,

    contextEnforcedQueryParams,
    defaultItemsListQueryParams,

    enhancedCurrentLocation,
    listData,

    dispatchFetchItemsList,
    dispatchClearItemsList,
    dispatchSetQueryParams,
}: UseStandardListCachingLogic<Items>) {
    const [areInitialQueryParamsSet, setAreInitialQueryParamsSet] = useState(false);
    const [doesListRequiresCleanup, setDoesListRequiresCleanup] = useState(false);
    const [isListReadyForDisplay, setIsListReadyForDisplay] = useState(false);

    // InitialQuery === default query params + query params enforced by context/props
    const initialQueryParams = {
        ...defaultItemsListQueryParams,
        ...contextEnforcedQueryParams,
    };

    // helper variables for ease of reading
    const currentPathname = useMemo(
        () => enhancedCurrentLocation.pathname,
        [enhancedCurrentLocation],
    );

    const hasValidLocation = useMemo(
        () => enhancedCurrentLocation?.pathname?.length > 0,
        [enhancedCurrentLocation],
    );

    const isListPristineEmpty = useMemo(
        () => !listData?.totalCount && !listData?.items,
        [enhancedCurrentLocation],
    );

    // XXX very, very important
    const onLocationChangeHandlers = [
    // no location (or current location doesn't match location form list data)-> do nothing
        {
            predicate: () => !hasValidLocation,
            handler: () => undefined,
        },
        // 1st time on given location, set queryParams and fetch data, spinner ON
        {
            predicate: () => hasValidLocation
        && isListPristineEmpty
        && !areInitialQueryParamsSet
        && !doesListRequiresCleanup
        && enhancedCurrentLocation.direction !== NavigationDirectionTypes.BACKWARD,
            handler: () => {
                setAreInitialQueryParamsSet(true);
                dispatchSetQueryParams({
                    queryParams: initialQueryParams,
                    locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
                });
                if (!(omitDataFetching || useGlobalLocation)) {
                    dispatchFetchItemsList({
                        queryParams: initialQueryParams,
                        locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
                    });
                }
            },
        },

        // returning to on given location via forward navigation !!unless it is a TAB_CHANGE!!
        // & clear existing query params and list results (except global lists) for given location and fetch new with initialQueryParams, spinner ON
        {
            predicate: () => hasValidLocation
        && !isListPristineEmpty
        && !doesListRequiresCleanup
        && enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD
        && enhancedCurrentLocation?.state?.type !== LocationChangeActionScopeTypes.TAB_CHANGE,

            handler: () => {
                setAreInitialQueryParamsSet(true);

                if (omitDataFetching || useGlobalLocation) {
                    setDoesListRequiresCleanup(false);
                    setIsListReadyForDisplay(true);
                    dispatchSetQueryParams({
                        queryParams: initialQueryParams,
                        locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
                    });
                } else {
                    setDoesListRequiresCleanup(true);
                    dispatchClearItemsList({ locationPathname: currentPathname });
                    dispatchSetQueryParams({
                        queryParams: initialQueryParams,
                        locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
                    });
                    dispatchFetchItemsList({
                        queryParams: initialQueryParams,
                        locationPathname: useGlobalLocation ? globalLocation : customLocation || currentPathname,
                    });
                }
            },
        },

        // returning to on given location via TAB_CHANGE or go back, just display what is already there, spinner OFF
        {
            predicate: () => hasValidLocation
        && !isListPristineEmpty
        && !areInitialQueryParamsSet
        && !doesListRequiresCleanup
        && (enhancedCurrentLocation?.direction !== NavigationDirectionTypes.FORWARD
          || (enhancedCurrentLocation?.direction === NavigationDirectionTypes.FORWARD
            && enhancedCurrentLocation?.state?.type === LocationChangeActionScopeTypes.TAB_CHANGE)
        ),
            handler: () => {
                setAreInitialQueryParamsSet(true);
                setDoesListRequiresCleanup(false);
                setIsListReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    const onListDataChangeHandlers = [
    // 1st time on given location - PART TWO, data was fetched, spinner OFF
        {
            predicate: () => hasValidLocation
        && isListPristineEmpty
        && areInitialQueryParamsSet
        && !doesListRequiresCleanup
        && !isListReadyForDisplay
        && !listData?.isLoadingList, // XXX important
            handler: () => {
                setIsListReadyForDisplay(true);
            },
        },
        // returning to on given location via forward navigation - PART II (after clearing, new data has arrived), spinner OFF
        {
            predicate: () => hasValidLocation
        && !isListPristineEmpty
        && areInitialQueryParamsSet
        && doesListRequiresCleanup
        && !listData?.isLoadingList, // XXX important
            handler: () => {
                setDoesListRequiresCleanup(false);
                setIsListReadyForDisplay(true);
            },
        },
        // default fallback
        {
            predicate: () => true,
            handler: () => undefined,
        },
    ];


    useEffect(() => onLocationChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [enhancedCurrentLocation]);

    useEffect(() => onListDataChangeHandlers.filter(({ predicate }) => predicate())[0].handler(), [listData]);


    return isListReadyForDisplay;
}

export default useStandardListCachingLogic;
