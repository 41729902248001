import React from 'react';

import TheSpinner from 'components/common/atoms/TheSpinner';

import { SpinnerOverlayProps } from './SpinnerOverlay.types';
import Styled from './SpinnerOverlay.styled';


function SpinnerOverlay({
    children,
    isLoading,
}: SpinnerOverlayProps): JSX.Element {
    return (
        <Styled.SpinnerOverlayWrapper>
            {isLoading
                ? (
                    <Styled.SpinnerWrapper>
                        <TheSpinner />
                    </Styled.SpinnerWrapper>
                )
                : (<></>)}

            <Styled.SpinnerOverlayContentWrapper>
                {children}
            </Styled.SpinnerOverlayContentWrapper>
        </Styled.SpinnerOverlayWrapper>
    );
}

export default SpinnerOverlay;

