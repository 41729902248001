import React from 'react';

import { Button } from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/system';
import { MdDarkMode } from 'react-icons/md';
import { ColorModeTogglerProps } from './ColorModeToggler.types';
import TheIcon from '../TheIcon';


function ColorModeToggler({ title = 'Toggle color mode' }: ColorModeTogglerProps): JSX.Element {
    const { toggleColorMode } = useColorMode();

    return (
        <Button
            type="button"
            leftIcon={<TheIcon Icon={MdDarkMode} />}
            data-test-id={'standard-link'}
            onClick={toggleColorMode}
            title={title}
        >
            {title}
        </Button>
    );
}

export default ColorModeToggler;
