import React from 'react';

import { Button } from '@chakra-ui/react';

import Styled from './RefreshButton.styled';
import { RefreshButtonOwnProps } from './RefreshButton.types';


function RefreshButton({ onClick, label = 'Refresh list' }: RefreshButtonOwnProps): JSX.Element {
    return (
        <Styled.RefreshButtonWrapper>
            <Button
                onClick={() => onClick()}
                size="xs"
            >
                {label}
            </Button>
        </Styled.RefreshButtonWrapper>
    );
}


export default RefreshButton;
