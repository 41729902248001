import React, { useMemo } from 'react';

import StandardFilters from 'components/common/molecules/StandardFilters';
import SpinnerOverlay from 'components/common/atoms/SpinnerOverlay';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import { isUndefined } from 'utils/object-tools';

import { StandardDataListProps } from './StandardDataList.types';
import StandardDataListFooter from './StandardDataListFooter';
import Styled from './StandardDataList.styled';
import StandardDataListTable from './StandardDataListTable';
import StandardDataListCards from './StandardDataListCards';
import NoResultsPlaceholder from './NoResultsPlaceholder';


function StandardDataList<ItemDefinition extends object>({
    items,
    totalCount,
    queryParams,
    fetchData,
    isListInitialStateDefined,
    isLoadingData,
    columnsConfiguration = [],
    filtersConfiguration,
    tableMode = false,
    t,
}: StandardDataListProps<ItemDefinition>) {

    const isListReady = useMemo(
        () => isListInitialStateDefined && !isUndefined(totalCount),
        [isListInitialStateDefined, totalCount],
    );

    return (
        <Styled.StandardDataListOuterWrapper>
            <StandardFilters
                isListReady={isListReady}
                queryParams={queryParams}
                filtersConfiguration={filtersConfiguration}
                fetchData={fetchData}
            />

            <Styled.StandardDataListWrapper>
                {totalCount === 0 && !isLoadingData ? (
                    <NoResultsPlaceholder
                        queryParams={queryParams}
                        filtersConfiguration={filtersConfiguration}
                        fetchData={fetchData}
                        t={t}
                    />
                ) : (
                    <SpinnerOverlay isLoading={isLoadingData && isListReady}>
                        <ScrollableContainer>
                            <>
                                {tableMode ? (
                                    <StandardDataListTable<ItemDefinition>
                                        items={items}
                                        isListReady={isListReady}
                                        queryParams={queryParams}
                                        columnsConfiguration={columnsConfiguration}
                                    />
                                ) : (
                                    <StandardDataListCards<ItemDefinition>
                                        items={items}
                                        isListReady={isListReady}
                                        queryParams={queryParams}
                                        columnsConfiguration={columnsConfiguration}
                                    />
                                )}
                            </>
                        </ScrollableContainer>
                    </SpinnerOverlay>
                )}
            </Styled.StandardDataListWrapper>

            <StandardDataListFooter
                t={t}
                isListReady={isListReady}
                totalCount={totalCount}
                queryParams={queryParams}
                fetchData={fetchData}
                itemsCount={items?.length}
            />
        </Styled.StandardDataListOuterWrapper>
    );
}


export default StandardDataList;
