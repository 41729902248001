import React from 'react';

import { SelectVariant } from 'models/app/formContols';

import { Select } from './chakra-react-select';
import { StandardSelectProps } from './StandardSelect.types';
import Styled from './StandardSelect.styled';


function StandardSelect({
    colorScheme,
    fieldConfiguration,
}: StandardSelectProps): JSX.Element {
    const handleOnChange = (selectedOption) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        fieldConfiguration?.onChange ? fieldConfiguration.onChange(selectedOption.value) : undefined;
    };

    // TODO get rid of 'as any' bellow & error underneath it
    return (
        <Styled.StandardSelectWrapper>
            <Select
                placeholder={fieldConfiguration.placeholderText || ''}
                value={(fieldConfiguration?.optionalConfiguration?.options || []).find((option) => option.value === fieldConfiguration.value) || ''}
                size={fieldConfiguration?.optionalConfiguration?.size || 'sm'}
                id={fieldConfiguration.id}
                name={fieldConfiguration.name}
                onChange={handleOnChange}
                isMulti={Boolean(fieldConfiguration?.optionalConfiguration?.isMultiSelect)}
                variant={fieldConfiguration.optionalConfiguration?.variant || SelectVariant.outline}
                colorScheme={colorScheme}
                options={fieldConfiguration?.optionalConfiguration?.options as any}
                isClearable={!!fieldConfiguration?.optionalConfiguration?.isClearable}
                isSearchable={!!fieldConfiguration?.optionalConfiguration?.isSearchable}
                selectedOptionStyle="check"
                isLoading={fieldConfiguration?.isLoading}
            />

        </Styled.StandardSelectWrapper>
    );
}

export default StandardSelect;
