export const FETCH_CARD_DETAILS = 'FETCH_CARD_DETAILS';
export const FETCH_CARD_DETAILS_SUCCESS = 'FETCH_CARD_DETAILS_SUCCESS';
export const FETCH_CARD_DETAILS_FAILURE = 'FETCH_CARD_DETAILS_FAILURE';
export const CLEAR_CARD_DETAILS = 'CLEAR_CARD_DETAILS';

export const UPDATE_CARD_LIMITS = 'UPDATE_CARD_LIMITS';
export const UPDATE_CARD_LIMITS_SUCCESS = 'UPDATE_CARD_LIMITS_SUCCESS';
export const UPDATE_CARD_LIMITS_FAILURE = 'UPDATE_CARD_LIMITS_FAILURE';

export const DELETE_CARD = 'DELETE_CARD';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';
