export const FETCH_BUSINESS_USERS_LIST = 'FETCH_BUSINESS_USERS_LIST';
export const FETCH_BUSINESS_USERS_LIST_SUCCESS = 'FETCH_BUSINESS_USERS_LIST_SUCCESS';
export const FETCH_BUSINESS_USERS_LIST_FAILURE = 'FETCH_BUSINESS_USERS_LIST_FAILURE';
export const CLEAR_BUSINESS_USERS_LIST = 'CLEAR_BUSINESS_USERS_LIST';


export const ADD_BUSINESS_USER = 'ADD_BUSINESS_USER';
export const ADD_BUSINESS_USER_SUCCESS = 'ADD_BUSINESS_USER_SUCCESS';
export const ADD_BUSINESS_USER_FAILURE = 'ADD_BUSINESS_USER_FAILURE';

