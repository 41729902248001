import { ThemeConfig, StyleFunctionProps } from '@chakra-ui/react';

const breakpoints = {
    base: '0em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em',
};

const styles = {
    global: (props: StyleFunctionProps) => ({
        body: {
            fontFamily: 'body',
            lineHeight: 'base',
        },
    }),
};

const baseThemeConfig: ThemeConfig = {
    initialColorMode: 'system',
    useSystemColorMode: true,
    cssVarPrefix: 'manigo',
};

export { breakpoints, baseThemeConfig, styles };
