import { createAction } from '@reduxjs/toolkit';
import { CLEAR_BENEFICIARIES_LIST, FETCH_BENEFICIARIES_LIST, FETCH_BENEFICIARIES_LIST_FAILURE, FETCH_BENEFICIARIES_LIST_SUCCESS } from './action.types';

export const fetchBeneficiariesList = createAction(FETCH_BENEFICIARIES_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchBeneficiariesListSuccess = createAction(
    FETCH_BENEFICIARIES_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchBeneficiariesListFailure = createAction(FETCH_BENEFICIARIES_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearBeneficiariesList = createAction(CLEAR_BENEFICIARIES_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));

