import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';

import DashboardPage from './DashboardPage.layout';
import { DashboardPageStateProps } from './DashboardPage.types';


const mapStateToProps = (state: RootState): DashboardPageStateProps => ({
    jwtToken: state.currentUser.jwtToken,
    accessTokenExpirationTimeStamp: state.currentUser.accessTokenExpirationTimeStamp,
    refreshTokenExpirationTimeStamp: state.currentUser.refreshTokenExpirationTimeStamp,
    userData: state.currentUser.userData,
    permissions: state.currentUser.permissions,
    userPreferences: state.currentUser.userPreferences,
});

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(mapStateToProps),
);

export default decorate(DashboardPage);
