import { createReducer } from '@reduxjs/toolkit';
import { HIDE_CONFIRMATION_ALERT, SHOW_CONFIRMATION_ALERT } from './actions.types';

export const alertReducerName = 'alert';
export interface AlertStateType {
        readonly confirmationHeader: string;
        readonly confirmationQuestion: string;
        readonly showConfirmation: boolean;
        readonly onConfirm?: () => void;
}

const initialState: AlertStateType = {
    confirmationHeader: 'confirmation',
    confirmationQuestion: '',
    showConfirmation: false,
    onConfirm: undefined,
};


const handlers = {
    [SHOW_CONFIRMATION_ALERT]: (state, { payload }) => {
        return { ...initialState, ...payload, showConfirmation: true };
    },
    [HIDE_CONFIRMATION_ALERT]: () => {
        return { ...initialState };
    },
};

export default createReducer(initialState, handlers);
