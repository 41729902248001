import commonEN from 'assets/locales/en-GB/common.json';
import transactionsEN from 'assets/locales/en-GB/transactions.json';
import unauthorisedEN from 'assets/locales/en-GB/unauthorised.json';
import companiesEN from 'assets/locales/en-GB/companies.json';
import accountsEN from 'assets/locales/en-GB/accounts.json';
import beneficiariesEN from 'assets/locales/en-GB/beneficiaries.json';
import cardsEn from 'assets/locales/en-GB/cards.json';
import { formatIBAN } from 'utils/account-tools';

// the translations
export const resources = {
    'en-GB': {
        accounts: accountsEN,
        beneficiaries: beneficiariesEN,
        cards: cardsEn,
        common: commonEN,
        companies: companiesEN,
        transactions: transactionsEN,
        unauthorised: unauthorisedEN,
    },
};

export const namespaces = [
    'accounts',
    'beneficiaries',
    'cards',
    'common',
    'companies',
    'transactions',
    'unauthorised',
];

export const i18nFormat = (value, format) => {
    if (!value) {
        return '';
    }

    if (format === 'iban') {
        return formatIBAN(value);
    }

    return value;
};
