/* eslint-disable max-len */
import React from 'react';
import demoImages from 'assets/images/demoImages';

import Styled from './AppMaintenancePlaceholder.styled';


function AppMaintenancePlaceholder() {
    return (
        <Styled.MaintenancePlaceholder>
            <Styled.MaintenanceGears src={demoImages.maintenanceCogs} />

            <Styled.MaintenanceCopyWrapper>
                <h1>Manigo Business Web Portal v2</h1>
                <h2>Under construction</h2>
            </Styled.MaintenanceCopyWrapper>

        </Styled.MaintenancePlaceholder>
    );
}

export default AppMaintenancePlaceholder;
