import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import { RootState } from 'store/rootReducer';
import { applicationReducerName } from 'store/application/reducer';

import ScrollableContainer from './ScrollableContainer.layout';


const mapStateToProps = (state: RootState) => ({ colorScheme: state[applicationReducerName].colorScheme });


const decorate = compose(
    connect(mapStateToProps),
);

export default decorate(ScrollableContainer);
