import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useStandardDetailsPageCachingLogic from 'hook/useStandardDetailsPageCachingLogic';
import StandardTabs from 'components/common/molecules/StandardTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/molecules/StandardTabs/StandardTabs.helpers';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import { Account } from 'models/domain/accounts';
import TheSpinner from 'components/common/atoms/TheSpinner';

import { createAccountDetailsPageTabsConfig } from './AccountDetailsPage.helpers';
import { AccountDetailsPageProps } from './AccountDetailsPage.types';


function AccountDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchAccountDetails,
    dispatchClearAccountDetails,
    dispatchSetQueryParams,
    t,
}: AccountDetailsPageProps): JSX.Element {
    const { accountId } = useParams();
    const fixedAccountId = Number(accountId);

    const contextEnforcedQueryParams = { accountId: fixedAccountId };
    const baseURLSegmentsCount = 2; // e.g. /(1) beneficiary /(2) BENEFICIARY_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic<Account>({
        contextEnforcedQueryParams,
        enhancedCurrentLocation,
        entityDetailsData,
        baseUrlWithoutTabsSlugs,
        dispatchFetchEntityDetails: dispatchFetchAccountDetails,
        dispatchClearEntityDetails: dispatchClearAccountDetails,
        dispatchSetQueryParams,
    });

    // console.log('CardDetailsPage entityDetailsData', entityDetailsData, isDetailsPageInitialStateDefined);
    const accountDetailsTabsConfig = useMemo(
        () => (fixedAccountId
            ? createAccountDetailsPageTabsConfig({
                fixedAccountId,
                accessControl,
                entityDetailsData,
                t,
            })
            : []),
        [fixedAccountId, accessControl, entityDetailsData],
    );

    return (
        <StandardAuthorisedPage
            pageTitle={t('Account Details')}
        >
            {entityDetailsData?.entityDetails?.id
                ? (
                    <StandardTabs
                        tabsConfig={accountDetailsTabsConfig}
                    />
                )
                : <TheSpinner />}

        </StandardAuthorisedPage>
    );
}


export default AccountDetailsPage;
