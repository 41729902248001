import * as yup from 'yup';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';

import { ForgotPasswordFormFields } from './ForgotPasswordPage.types';


export const createForgotPasswordFormFieldsConfiguration = ({ t }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.EMAIL,
        id: 'email',
        name: 'email',
        labelText: t('User email'),
        placeholderText: t('Enter your email'),
        hintText: t('Check your email! Link will be valid for 15 min.'),
        optionalConfiguration: { isRequired: true },
    },
]);

export const createForgotPasswordFormSchema = ({ t }) => {
    return yup.object<ForgotPasswordFormFields>({
        email: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .email(t('common:validationMsg.emailInvalidFormat'))
            .max(100, t('validationMsg.maximumLength', { maxCount: 100 })),
    }).required();
};

export default { createForgotPasswordFormFieldsConfiguration, createForgotPasswordFormSchema };
