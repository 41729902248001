import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { Epic } from 'models/meta/epic';
import { showToast } from 'store/application/actions';
import { ToastType } from 'models/app/toast';

import { ADD_ACCOUNT, ADD_ACCOUNT_SUCCESS, FETCH_ACCOUNTS_LIST } from './actions.types';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from './reducer';
import {
    addAccountFailure,
    addAccountSuccess,
    fetchAccountsListSuccess,
    fetchAccountsListFailure,
    fetchAccountsList,
} from './actions';
import { addCompanyIdToQueryParams } from '../common.helpers';
import { requestNavigationBack } from '../navigation/actions';


export const onAddAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(ADD_ACCOUNT),
    switchMap(({ payload }) => from(accounts.createNewAccount(payload.requestPayload))
        .pipe(
            switchMap((response) => {
                const pathname = payload.locationPathname;
                const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams;
                const responsePayload = response?.data;

                return of(
                    requestNavigationBack(),
                    addAccountSuccess(),
                    fetchAccountsList({ queryParams: currentAccountListQueryParams, locationPathname: pathname }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('accounts:actionMessages.addNewSuccess', {
                            type: responsePayload.type,
                            currency: responsePayload.currency,
                        }),
                    }),
                );
            }),
            catchError(() => of(addAccountFailure())),
        )),
);


export const onFetchAccountsList: Epic = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNTS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(accounts.getAccountsList(addCompanyIdToQueryParams({ state$, queryParams })))
        .pipe(
            switchMap((response) => (
                queryParams?.withTotal
                && response?.data?.count >= 0
                    ? of(fetchAccountsListSuccess({
                        items: response.data.rows,
                        totalCount: response?.data.count,
                        locationPathname,
                    }))
                    : of(fetchAccountsListSuccess({
                        items: response.data,
                        totalCount: response?.data?.length,
                        locationPathname,
                    })))),
            catchError(() => of(fetchAccountsListFailure(locationPathname))),
        )),
);


export default [
    onFetchAccountsList,
    onAddAccount,
];

