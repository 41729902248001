import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';


import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { clearReadOnlyMode, setQueryParams, setReadOnlyMode } from 'store/application/actions';
import { navigationReducerName } from 'store/navigation/reducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';
import { RootState } from 'store/rootReducer';
import { clearAccountDetails, fetchAccountDetails } from 'store/account/actions';
import { accountDetailsPerLocationStoreKeyName, accountReducerName } from 'store/account/reducer';

import AccountDetailsPage from './AccountDetailsPage.layout';
import { AccountDetailsPageDispatchProps, AccountDetailsPageStateProps } from './AccountDetailsPage.types';


const mapStateToProps = (state: RootState): AccountDetailsPageStateProps => ({
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    entityDetailsData: getStandardEntityDetailsData({
        state,
        reducerName: accountReducerName,
        fieldName: accountDetailsPerLocationStoreKeyName,
        baseURLSegmentsCount: 2,
    }),

});

const mapDispatchToProps = (dispatch): AccountDetailsPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),

    dispatchFetchAccountDetails: ({ queryParams, locationPathname }) => dispatch(fetchAccountDetails({ queryParams, locationPathname })),
    dispatchClearAccountDetails: ({ locationPathname }) => dispatch(clearAccountDetails({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: accountReducerName,
        fieldName: accountDetailsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),

    dispatchSetReadOnlyMode: (readOnlyInfo) => dispatch(setReadOnlyMode(readOnlyInfo)),
    dispatchClearReadOnlyMode: () => dispatch(clearReadOnlyMode()),
});


const decorate = compose<ComponentType>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountDetailsPage);
