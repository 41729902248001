import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';
import StandardForm from 'components/common/organisms/StandardForm';

import { SetPasswordPageProps } from './SetPasswordPage.types';
import { createSetPasswordFormFieldsConfiguration, createSetPasswordFormSchema } from './SetPasswordPage.helpers';

function SetPassword({
    isLoadingSetNewPassword,
    dispatchSetNewPassword,
    t,
}: SetPasswordPageProps): JSX.Element {
    const location = useLocation();

    const token = useMemo(
        () => {
            if (location?.search && location?.search?.length > 0) {
                const params = new URLSearchParams(location?.search);
                return params.get('token');
            }
            return undefined;
        },
        [location.search],
    );

    return (
        <StandardUnauthorisedPage
            pageTitle={'Set New Password'}
        >
            <StandardForm
                t={t}
                submitButtonText={t('formsElements.resetPassword.text')}
                formName="set-password-form"
                initialFormState={{
                    password: '',
                    confirmPassword: '',
                    token,
                }}
                isLoading={isLoadingSetNewPassword}
                validationSchema={createSetPasswordFormSchema({ t })}
                createFieldsConfiguration={createSetPasswordFormFieldsConfiguration}
                dispatchOnSubmitAction={dispatchSetNewPassword}
            />
        </StandardUnauthorisedPage>
    );
}


export default SetPassword;
