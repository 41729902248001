import { createAction } from '@reduxjs/toolkit';
import {
    ADD_ACCOUNT,
    ADD_ACCOUNT_FAILURE,
    ADD_ACCOUNT_SUCCESS,
    CLEAR_ACCOUNTS_LIST,
    FETCH_ACCOUNTS_LIST,
    FETCH_ACCOUNTS_LIST_FAILURE,
    FETCH_ACCOUNTS_LIST_SUCCESS,
} from './actions.types';

export const addAccount = createAction(ADD_ACCOUNT, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const addAccountSuccess = createAction(ADD_ACCOUNT_SUCCESS);
export const addAccountFailure = createAction(ADD_ACCOUNT_FAILURE);


export const fetchAccountsList = createAction(FETCH_ACCOUNTS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchAccountsListSuccess = createAction(
    FETCH_ACCOUNTS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchAccountsListFailure = createAction(FETCH_ACCOUNTS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearAccountsList = createAction(CLEAR_ACCOUNTS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
