import styled, { css } from 'styled-components';

const AppWrapper = styled.div<{ enableOverflowHidden?: boolean }>`
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;


  ${({ enableOverflowHidden = false }) => enableOverflowHidden && css`
    overflow: hidden;
    z-index: 10000;
  `};
`;

const AppBlurOverlay = styled.div<{ activeBlur?: boolean }>`
  transition: filter 200ms ease;
  height: 100%;
  
  ${({ activeBlur = false }) => activeBlur && css`
    filter: blur(10px);
    pointer-events: none;
    user-select: none;
    z-index: 100;
  `};
`;

export default {
    AppWrapper,
    AppBlurOverlay,
};
