import React from 'react';

import useStandardListCachingLogic from 'hook/useStandardListCachingLogic';
import { BusinessUser } from 'models/domain/businessUsers';
import { defaultBusinessUsersListQueryParams } from 'store/business-users/reducer';
import StandardDataTable from 'components/common/organisms/StandardDataList';

import { BusinessUsersListProps } from './BusinessUsersList.types';
import { createBusinessUsersListColumns } from './tableColumns';
import { createBusinessUsersListFiltersConfiguration } from './BusinessUsersList.helpers';


function BusinessUsersList({
    // own props
    isInReadOnlyMode = false,
    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    dispatchFetchBusinessUsersList,
    dispatchClearBusinessUsersList,
    dispatchSetQueryParams,

    t,
}: BusinessUsersListProps): JSX.Element {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {};

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchFetchBusinessUsersList({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic<BusinessUser>({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultBusinessUsersListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchBusinessUsersList,
        dispatchClearItemsList: dispatchClearBusinessUsersList,
        dispatchSetQueryParams,
    });


    // 5. standard render component
    return (
        <StandardDataTable<BusinessUser>
            t={t}
            fetchData={fetchData}
            totalCount={listData.totalCount}
            items={listData.items}
            isLoadingData={listData.isLoadingList}
            queryParams={listData.queryParams}
            isListInitialStateDefined={isListInitialStateDefined}
            columnsConfiguration={createBusinessUsersListColumns({
                t,
                accessControl,
                queryParams: listData.queryParams,
            })}
            filtersConfiguration={createBusinessUsersListFiltersConfiguration({ t })}
        />
    );
}

export default BusinessUsersList;

