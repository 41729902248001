import {
    accountRootRoutePath,
    accountsListRootRoutePath,
    beneficiaryRootRoutePath,
    businessUserRootRoutePath,
    businessUsersListRootRoutePath, cardDetailsRootRoutePath,
    cardsListRootRoutePath,
    dashboardRootRoutePath,
    editBeneficiaryRootRoutePath,
    genericAddRouteSegmentPath, transactionsListRootRoutePath,
    transactionsRouteSegmentPath,
} from 'config/routes';


// directly to ATM one and only tab - transactions
export const createDashboardPath = () => `${dashboardRootRoutePath}`;
export const createBeneficiariesListPath = () => `${beneficiaryRootRoutePath}`;
export const createBeneficiaryDetailsPath = (beneficiaryUuid) => `${beneficiaryRootRoutePath}/${beneficiaryUuid}`;
export const createBeneficiaryDetailsTransactionsListPath = (beneficiaryUuid) => `${createBeneficiaryDetailsPath(beneficiaryUuid)}/${transactionsRouteSegmentPath}`;
export const createAddBeneficiaryPath = () => `${beneficiaryRootRoutePath}/${genericAddRouteSegmentPath}`;
export const createEditBeneficiaryPath = (beneficiaryUuid) => `${editBeneficiaryRootRoutePath}/${beneficiaryUuid}`;

export const createAccountsListPath = () => `${accountsListRootRoutePath}`;
export const createAccountDetailsPath = (accountId) => `${accountRootRoutePath}/${accountId}`;
export const createAccountTransactionsPath = (accountId) => `${accountRootRoutePath}/${accountId}/${transactionsRouteSegmentPath}`;


export const createCardsListPath = () => `${cardsListRootRoutePath}`;
export const createCardDetailsPath = (cardId) => `${cardDetailsRootRoutePath}/${cardId}`;

export const createBusinessUsersListPath = () => `${businessUsersListRootRoutePath}`;
export const createBusinessUserDetailsPath = (businessUserId) => `${businessUserRootRoutePath}/${businessUserId}`;

export const createTransactionsListPath = () => `${transactionsListRootRoutePath}`;

export default {
    createDashboardPath,
    createBeneficiariesListPath,
    createAddBeneficiaryPath,
    createBeneficiaryDetailsPath,
    createBeneficiaryDetailsTransactionsListPath,
    createEditBeneficiaryPath,

    createAccountsListPath,
    createAccountDetailsPath,
    createAccountTransactionsPath,
    createCardsListPath,
    businessUsersListPath: createBusinessUsersListPath,
    businessUserDetailsPath: createBusinessUserDetailsPath,

    createTransactionsListPath,
};
