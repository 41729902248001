import React from 'react';
import { Input, InputGroup, InputLeftElement, Skeleton } from '@chakra-ui/react';
import { MdInfo, MdSearch } from 'react-icons/md';

import TheIcon from 'components/common/atoms/TheIcon';
import StandardTooltip from 'components/common/atoms/StandardTooltip';


function SorterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    colorScheme,
    isListReady,
    t,
}): JSX.Element {

    // TODO add debounce or throttling over onChange
    return filtersConfiguration?.searchBy && filtersConfiguration?.searchBy?.length > 0
        ? (
            <>
                {isListReady ? (
                    <InputGroup
                        colorScheme={colorScheme}
                        maxW="xs"
                        marginTop="18px"
                    >
                        <InputLeftElement pointerEvents="none">
                            <TheIcon Icon={MdSearch} />
                        </InputLeftElement>

                        <Input
                            colorScheme={colorScheme}
                            id="standard-list-search"
                            name="search"
                            onChange={(event) => handleQueryParamsChange({
                                fieldName: 'search',
                                value: event.target.value,
                            })}
                            placeholder={t('Type to search...')}
                            value={queryParams.search || ''}
                            marginRight="5px"
                        />
                        <StandardTooltip title={t(`You can search over: \n ${filtersConfiguration?.searchBy.join(', ')}`)}>
                            <TheIcon Icon={MdInfo} />
                        </StandardTooltip>
                    </InputGroup>
                ) : (
                    <Skeleton height="50px" width="200px" />
                )}
            </>

        ) : (<></>);
}

export default SorterFields;
