import { createAction } from '@reduxjs/toolkit';
import {
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS, GET_REMEMBERED_LOGIN_USER_EMAIL, GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE, GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS, GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS, REQUEST_PASSWORD_RESET, REQUEST_PASSWORD_RESET_FAILURE, REQUEST_PASSWORD_RESET_SUCCESS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS,
} from './actions.types';


export const initialiseLogin = createAction(INITIALISE_LOGIN, (requestPayload) => {
    return { payload: requestPayload };
});
export const initialiseLoginSuccess = createAction(INITIALISE_LOGIN_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const initialiseLoginFailure = createAction(INITIALISE_LOGIN_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});

export const confirmLogin = createAction(CONFIRM_LOGIN, (requestPayload) => {
    return { payload: requestPayload };
});
export const confirmLoginSuccess = createAction(CONFIRM_LOGIN_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const confirmLoginFailure = createAction(CONFIRM_LOGIN_FAILURE);


export const setNewPassword = createAction(SET_NEW_PASSWORD, (requestPayload) => {
    return { payload: requestPayload };
});
export const setNewPasswordSuccess = createAction(SET_NEW_PASSWORD_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const setNewPasswordFailure = createAction(SET_NEW_PASSWORD_FAILURE);


export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET, (requestPayload) => {
    return { payload: requestPayload };
});
export const requestPasswordResetSuccess = createAction(REQUEST_PASSWORD_RESET_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const requestPasswordResetFailure = createAction(REQUEST_PASSWORD_RESET_FAILURE);

export const getTokensForDuplicatedTab = createAction(GET_TOKENS_FOR_DUPLICATED_TAB, (requestPayload: object, sessionStorageData?: any) => {
    return { payload: { requestPayload, sessionStorageData } };
});

export const getRememberedUserEmail = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL);
export const getRememberedUserEmailSuccess = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS, (userEmail: string) => ({ payload: userEmail }));
export const getRememberedUserEmailFailure = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE);
