import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import StandardForm from 'components/common/organisms/StandardForm';

import { CardType } from 'models/domain/cards';
import { createAddressInitialValues } from 'utils/addressFields-tools';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';
import { createAddCardFormFieldsConfiguration, createAddCardFormSchema } from './AddCardPage.helpers';
import { AddCardPageProps } from './AddCardPage.types';

function AddCardPage({
    enhancedCurrentLocation,
    dispatchOrderCard,
    isLoadingAddCard,
    countriesList,
    t,
}: AddCardPageProps): JSX.Element {

    const initialFormState = {
        type: CardType.physical.toString(),
        business_user_id: null,
        account_id: null,
        ...createAddressInitialValues(),
    };

    const onSubmit = (data) => {
        const payload = {
            business_user_id: Number(data.business_user_id),
            account_id: Number(data.account_id),
            type: Number(data.type),
            address: data.address,
        };
        if (data?.type === CardType.virtual) delete payload.address;
        dispatchOrderCard({ requestPayload: payload, enhancedCurrentLocation });
    };

    const fieldConfiguration = createAddCardFormFieldsConfiguration({
        t,
        countries: countriesList?.items,
    });

    return (
        <StandardAuthorisedPage
            pageTitle={t('orderCardsScreenTitle')}
        >
            <>
                <ScrollableContainer>
                    <StandardForm
                        t={t}
                        submitButtonText={t('buttons.orderCard.text')}
                        formName="add-card-form"
                        initialFormState={initialFormState}
                        validationSchema={createAddCardFormSchema({ t })}
                        createFieldsConfiguration={() => fieldConfiguration}
                        dispatchOnSubmitAction={onSubmit}
                        isLoading={isLoadingAddCard}
                    />
                </ScrollableContainer>
            </>
        </StandardAuthorisedPage>
    );
}


export default AddCardPage;
