import { Trans } from 'react-i18next';
import React from 'react';


import {
    TranslationInlineWrap,

    Anchor,
    Code,
    Emphasis,
    ListItem,
    OrderedList,
    Paragraph,
    Quotation,
    Underline,
    UnorderedList,
} from 'components/common/atoms/StandardTranslationWithMarkup/StandardTranslationWithMarkup.styled';
import StandardLink from 'components/common/atoms/StandardLink';
import { StandardTranslationWithMarkupProps } from './StandardTranslationWithMarkup.types';


const defaultComponents: Record<string, React.ReactElement> = {
    p: <Paragraph />,
    strong: <Emphasis />,
    u: <Underline />,
    q: <Quotation />,
    code: <Code />,
    ol: <OrderedList />,
    ul: <UnorderedList />,
    li: <ListItem />,
    a: <Anchor target="_blank" />,
    ref: <StandardLink title={''} />,
};

function StandardTranslationWithMarkup({
    translationKey,
    components,
    values,
    namespace,
    t,
    parent,
}: StandardTranslationWithMarkupProps) {
    return (
        <TranslationInlineWrap>
            <Trans
                i18nKey={translationKey}
                ns={namespace}
                t={t}
                values={{ ...values }}
                components={{ ...defaultComponents, ...components }}
                parent={parent}
            />
        </TranslationInlineWrap>
    );
}


export default StandardTranslationWithMarkup;
