import React from 'react';
import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';
import {
    FormLabel, HStack, Input, PinInput, PinInputField, Radio, RadioGroup, Stack, Switch,
} from '@chakra-ui/react';

import StandardSelect from 'components/common/molecules/StandardSelect';

import LazyAutocomplete from 'components/common/organisms/LazyAutocomplete';
import { FancyRadio } from '../FancyRadio';

export interface RenderFormInputTypeProps {
    fieldConfiguration: CommonFormFieldConfigItem,
    field: any,
    formState: any,
    fieldState: any,
}

export const renderFormInputType = ({
    field,
    fieldState,
    formState,
    fieldConfiguration,
}: RenderFormInputTypeProps): JSX.Element => {
    const { name } = fieldConfiguration;
    const handlers = [
        // XXX an escape hatch... render any children as input
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.CUSTOM,
            handler: () => fieldConfiguration.children as JSX.Element,
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.TEXT,
            handler: () => (
                <Input
                    type="text"
                    onBlur={field.onBlur}
                    value={field.value}
                    onChange={((event) => field.onChange(event.target.value))}
                    id={fieldConfiguration.id}
                    name={name}
                    placeholder={fieldConfiguration.placeholderText || ''}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.PASSWORD,
            handler: () => (
                <Input
                    type="password"
                    onBlur={field.onBlur}
                    value={field.value}
                    onChange={((event) => field.onChange(event.target.value))}
                    id={fieldConfiguration.id}
                    name={name}
                    placeholder={fieldConfiguration.placeholderText || ''}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.EMAIL,
            handler: () => (
                <Input
                    type="email"
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={((event) => field.onChange(event.target.value))}
                    id={fieldConfiguration.id}
                    name={name}
                    placeholder={fieldConfiguration.placeholderText || ''}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.COLOR,
            handler: () => (
                <Input
                    type="color"
                    value={field.value}
                    onBlur={field.onBlur}
                    id={fieldConfiguration.id}
                    onChange={((event) => field.onChange(event.target.value))}
                    name={name}
                    placeholder={fieldConfiguration.placeholderText || ''}
                />
            ),
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.RADIO,
            handler: () => (
                <RadioGroup
                    onChange={((value) => field.onChange(value))}
                    value={field.value}
                >
                    <Stack direction="row">
                        {
                            fieldConfiguration.optionalConfiguration?.options?.map((option) => (
                                <Radio
                                    key={option.value}
                                    value={typeof option.value === 'string' ? option.value : JSON.stringify(option.value)}
                                >
                                    {option.label}
                                </Radio>

                            ))
                        }
                    </Stack>
                </RadioGroup>
            ),

        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.SWITCH,
            handler: () => {
                return (
                    <HStack alignItems={'center'}>
                        <Switch
                            name={name}
                            id={fieldConfiguration.id}
                            isChecked={!!field.value}
                            onChange={(event) => field.onChange(event.target.checked)}
                            size="lg"

                        />

                        <FormLabel htmlFor={fieldConfiguration.id}>
                            {fieldConfiguration.labelText}
                        </FormLabel>
                    </HStack>
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.SELECT_SINGLE_VALUE,
            handler: () => {
                return (
                    <StandardSelect
                        fieldConfiguration={{
                            ...fieldConfiguration,
                            onBlur: field.onBlur,
                            onChange: ((value) => {
                                field.onChange(value);
                            }),
                            value: field.value,
                        }}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.LAZY_AUTOCOMPLETE_SINGLE_VALUE,
            handler: () => {
                return (
                    <LazyAutocomplete
                        fieldConfiguration={{
                            ...fieldConfiguration,
                            onBlur: field.onBlur,
                            onChange: ((value) => {
                                field.onChange(value);
                            }),
                            value: field.value,
                        }}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.FANCY_RADIO,
            handler: () => {
                return (
                    <FancyRadio
                        fieldConfiguration={{ ...fieldConfiguration }}
                    />
                );
            },
        },
        {
            predicate: () => fieldConfiguration.fieldType === FormInputTypes.PIN,
            handler: () => (
                <div style={{ display: 'flex', margin: '0 auto', justifyContent: 'center' }}>
                    <HStack>
                        <PinInput
                            value={field.value}
                            onChange={(pinInputValue) => field.onChange(pinInputValue)}
                            onComplete={(pinInputValue) => field.onChange(pinInputValue)}
                            otp
                            autoFocus
                            size="lg"
                            placeholder={'\u{25C8}'}
                        >

                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                        </PinInput>
                    </HStack>
                </div>
            ),
        },
        {
            predicate: () => true,
            handler: () => <div />,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { renderFormInputType };

