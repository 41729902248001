import { Button, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import { NavButtonProps } from './NavButton.types';


const NavButton = (props: NavButtonProps) => {
    const { leftIcon, children, ...buttonProps } = props;
    return (
        <Button variant="ghost" justifyContent="start" leftIcon={leftIcon} {...buttonProps}>
            <HStack spacing="3">
                <Text>{children}</Text>
            </HStack>
        </Button>
    );
};

export default NavButton;
