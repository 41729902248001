import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { clearCurrentUser } from 'store/current-user/actions';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import AutoLogoutBanner from './AutoLogoutBanner.layout';
import { AutoLogoutBannerDispatchProps } from './AutoLogoutBanner.types';

const mapDispatchToProps = (dispatch) => <AutoLogoutBannerDispatchProps>({ dispatchClearCurrentUser: () => dispatch(clearCurrentUser()) });

const decorate = compose<ComponentType>(
    withTranslation('common'),
    connect(null, mapDispatchToProps),
);

export default decorate(AutoLogoutBanner);
