export const createBusinessUsersListFiltersConfiguration = ({ t }) => {

    return {
        sortByOptions: [
            {
                value: 'id',
                label: t('ID'),
            },
            {
                value: 'firstName',
                label: t('First Name'),
            },
            {
                value: 'lastName',
                label: t('Last Name'),
            },
            {
                value: 'email',
                label: t('Email'),
            },
            {
                value: 'position',
                label: t('Position'),
            },
            {
                value: 'created_at',
                label: 'Date created',
            },
        ],
    };
};


export default { createBusinessUsersListFiltersConfiguration };
