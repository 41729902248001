import {
    Stack,
    useRadioGroup,
} from '@chakra-ui/react';
import React, { Children, cloneElement, isValidElement, ReactElement, useMemo } from 'react';
import { RadioCardGroupProps } from './RadioCardGroup.types';
import { RadioCardProps } from '../RadioCardProps/RadioCard.types';

export const RadioCardGroup = <T extends string>(props: RadioCardGroupProps<T>) => {
    const { children, name, defaultValue, value, onChange, ...rest } = props;
    const { getRootProps, getRadioProps } = useRadioGroup({
        name,
        defaultValue,
        value,
        onChange,
    });

    const cards = useMemo(
        () =>
            Children.toArray(children)
                .filter<ReactElement<RadioCardProps>>(isValidElement)
                .map((card) => {
                    return cloneElement(card, { radioProps: getRadioProps({ value: card.props.value }) });
                }),
        [children, getRadioProps],
    );

    return <Stack {...getRootProps(rest)}>{cards}</Stack>;
};
