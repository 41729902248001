export const createAccountsListFiltersConfiguration = ({ t, currencyOptions }) => {

    return {
        sortByOptions: [
            {
                value: 'id',
                label: t('ID'),
            },
            {
                value: 'currency',
                label: t('Currency'),
            },
        ],
        filtersConfig: [
            {
                label: t('Currency'),
                queryParamName: 'currency',
                filterOptions: currencyOptions,
                placeholderText: 'Select to narrow',
            },
        ],
    };
};


export default { createAccountsListFiltersConfiguration };
