import { HttpService } from 'services/http';
import { Uuid } from 'models/app/common';


export const fetchBeneficiariesAPI = (queryParams: object) => HttpService.get('/v3/beneficiaries', queryParams);

export const fetchBeneficiaryUuidDetails = (beneficiaryUuid: Uuid) => HttpService.get(`/v3/beneficiaries/${beneficiaryUuid}`);

export const fetchBeneficiaryRequirementsApi = (queryParams: object) => HttpService.get('/v3/beneficiaries/requirements', queryParams);

export const createBeneficiaryApi = (formData: object) => HttpService.post('/v3/beneficiaries', formData);

export const updateBeneficiaryUuid = (beneficiaryUuid: Uuid, formData: object) => HttpService.put(`/v3/beneficiaries/${beneficiaryUuid}`, formData);

export const deleteBeneficiaryUuid = (beneficiaryUuid: Uuid) => HttpService.delete(`/v3/beneficiaries/${beneficiaryUuid}`);


export default {
    fetchBeneficiariesAPI,
    fetchBeneficiaryUuidDetails,
    fetchBeneficiaryRequirementsApi,
    createBeneficiaryApi,
    updateBeneficiaryUuid,
    deleteBeneficiaryUuid,
};
