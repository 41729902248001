export const FETCH_COMPANY_DETAILS = 'FETCH_COMPANY_DETAILS';
export const FETCH_COMPANY_DETAILS_SUCCESS = 'FETCH_COMPANY_DETAILS_SUCCESS';
export const FETCH_COMPANY_DETAILS_FAILURE = 'FETCH_COMPANY_DETAILS_FAILURE';

export const CLEAR_COMPANY_DETAILS = 'CLEAR_COMPANY_DETAILS';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const UPDATE_COMPANY_ADDRESS = 'UPDATE_COMPANY_ADDRESS';
export const UPDATE_COMPANY_ADDRESS_SUCCESS = 'UPDATE_COMPANY_ADDRESS_SUCCESS';
export const UPDATE_COMPANY_ADDRESS_FAILURE = 'UPDATE_COMPANY_ADDRESS_FAILURE';
