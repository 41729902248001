import React from 'react';
import {
    ButtonGroup,
    Card, CardBody, CardFooter, Divider, Heading, Stack, Text, SimpleGrid,
} from '@chakra-ui/react';
import { MdArrowForwardIos } from 'react-icons/md';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import StandardLink from 'components/common/atoms/StandardLink';
import TheIcon from 'components/common/atoms/TheIcon';
import { myProfileRootRoutePath } from 'config/routes';
import ScrollableContainer from 'components/common/atoms/ScrollableContainer';

import { DashboardPageProps } from './DashboardPage.types';


function DashboardPage({ t }: DashboardPageProps): JSX.Element {

    return (
        <StandardAuthorisedPage
            pageTitle={t('Dashboard')}
        >
            <ScrollableContainer>


                <SimpleGrid
                    spacing="40px"
                    columns={2}
                >
                    <Card size={'lg'}>
                        <CardBody>
                            <Stack mt="6" spacing="3">
                                <Heading size="md">Current User</Heading>
                                <Text>
                          You are logged in as:
                                </Text>
                                <Text color="blue.600" fontSize="2xl">
                           ...
                                </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <ButtonGroup spacing="2">
                                <StandardLink
                                    rightIcon={<TheIcon Icon={MdArrowForwardIos} />}
                                    path={myProfileRootRoutePath}
                                    title={t('View my profile')}
                                />
                            </ButtonGroup>
                        </CardFooter>
                    </Card>

                    <Card size={'lg'}>
                        <CardBody>
                            <Stack mt="6" spacing="3">
                                <Heading size="md">Company Details</Heading>
                                <Text>
                            Lorem Ipsum...
                                </Text>
                                <Text color="blue.600" fontSize="2xl">
                            ...
                                </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <ButtonGroup spacing="2" />
                        </CardFooter>
                    </Card>

                    <Card size={'lg'}>
                        <CardBody>
                            <Stack mt="6" spacing="3">
                                <Heading size="md">Company Addresses</Heading>
                                <Text>
                            Lorem Ipsum...
                                </Text>
                                <Text color="blue.600" fontSize="2xl">
                            ...
                                </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <ButtonGroup spacing="2" />
                        </CardFooter>
                    </Card>

                    <Card size={'lg'}>
                        <CardBody>
                            <Stack mt="6" spacing="3">
                                <Heading size="md">Company KYB info</Heading>
                                <Text>
                            Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>
                                <Text>
                                Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>
                                <Text>
                                Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>
                                <Text>
                                Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>
                                <Text>
                                Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>

                                <Text>
                            Lorem Ipsum...
                            Lorem Ipsum...
                            Lorem Ipsum...  Lorem Ipsum...

                                </Text>

                                <Text>
                                Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...  Lorem Ipsum...

                                </Text>
                                <Text color="blue.600" fontSize="2xl">
                                Lorem Ipsum...  Lorem Ipsum...
                                Lorem Ipsum...
                                Lorem Ipsum...
                                </Text>
                            </Stack>
                        </CardBody>
                        <Divider />
                        <CardFooter>
                            <ButtonGroup spacing="2" />
                        </CardFooter>
                    </Card>
                </SimpleGrid>
            </ScrollableContainer>
        </StandardAuthorisedPage>
    );
}


export default DashboardPage;
