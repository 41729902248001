import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useStandardDetailsPageCachingLogic from 'hook/useStandardDetailsPageCachingLogic';
import StandardTabs from 'components/common/molecules/StandardTabs';
import { extractBaseURLWithoutActiveTab } from 'components/common/molecules/StandardTabs/StandardTabs.helpers';
import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import { Card } from 'models/domain/cards';
import TheSpinner from 'components/common/atoms/TheSpinner';

import { createCardDetailsPageTabsConfig } from './CardDetailsPage.helpers';
import { CardDetailsPageProps } from './CardDetailsPage.types';


function CardDetailsPage({
    accessControl,

    entityDetailsData,
    enhancedCurrentLocation,

    dispatchFetchCardDetails,
    dispatchClearCardDetails,
    dispatchSetQueryParams,
    t,
}: CardDetailsPageProps) {
    const { cardId } = useParams();
    const fixedCardId = Number(cardId);

    const contextEnforcedQueryParams = { cardId: fixedCardId };
    const baseURLSegmentsCount = 2; // e.g. /(1) beneficiary /(2) BENEFICIARY_UUID /(3) [1st-level-only tab slug]
    const baseUrlWithoutTabsSlugs = extractBaseURLWithoutActiveTab(enhancedCurrentLocation?.pathname, baseURLSegmentsCount);


    const isDetailsPageInitialStateDefined = useStandardDetailsPageCachingLogic<Card>({
        contextEnforcedQueryParams,
        enhancedCurrentLocation,
        entityDetailsData,
        baseUrlWithoutTabsSlugs,
        dispatchFetchEntityDetails: dispatchFetchCardDetails,
        dispatchClearEntityDetails: dispatchClearCardDetails,
        dispatchSetQueryParams,
    });

    // console.log('CardDetailsPage entityDetailsData', entityDetailsData, isDetailsPageInitialStateDefined);
    const cardDetailsTabsConfig = useMemo(
        () => (fixedCardId
            ? createCardDetailsPageTabsConfig({
                fixedCardId,
                accessControl,
                entityDetailsData,
                t,
            })
            : []),
        [fixedCardId, accessControl],
    );

    return (
        <StandardAuthorisedPage
            pageTitle={t('Card Details')}
        >
            {entityDetailsData?.entityDetails?.id
                ? (
                    <StandardTabs
                        tabsConfig={cardDetailsTabsConfig}
                    />
                )
                : <TheSpinner /> }

        </StandardAuthorisedPage>
    );
}


export default CardDetailsPage;
