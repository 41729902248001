import dayjs from 'dayjs';
import React from 'react';

import { WL_CONFIG } from 'config/environment';

import StandardTranslationWithMarkup from '../StandardTranslationWithMarkup';

function CopyrightNotice({ t }) {
    const currentYear = dayjs().year();
    return (
        <StandardTranslationWithMarkup
            translationKey={'copyrightNotice'}
            namespace="common"
            values={{
                brandLandingPageUrl: WL_CONFIG.brandLandingPageUrl,
                brandShortName: WL_CONFIG.brandShortName,
                brandFullName: WL_CONFIG.brandFullName,
                currentYear,
            }}
            t={t}
        />

    );
}


export default CopyrightNotice;
