import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useColorMode } from '@chakra-ui/system';
import { Box, ButtonGroup, useBreakpointValue, VStack } from '@chakra-ui/react';

import ColorSchemeToggler from 'components/common/atoms/ColorSchemeToggler';
import ColorModeToggler from 'components/common/atoms/CollorModeToggler';
import CopyrightNotice from 'components/common/atoms/CopyrightNotice';
import { WL_CONFIG } from 'config/environment';

import Styled from './PublicLayout.styled';
import { PublicLayoutProps } from './PublicLayout.types';


function PublicLayout({ children }: PublicLayoutProps): JSX.Element {
    const { colorMode } = useColorMode();
    const isDesktop = useBreakpointValue({ base: false, lg: true });

    return (
        <Styled.UnauthorisedOuterWrapper className={colorMode}>
            <Styled.UnauthorisedHeadingWrapper isDesktop={isDesktop}>
                {isDesktop && <ColorSchemeToggler />}
                <Styled.UnauthorisedLogoWrapper>
                    {WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl
                        ? (
                            <Styled.UnauthorisedLogo
                                src={WL_CONFIG?.brandFullLogoUrl || WL_CONFIG?.brandSignetLogoUrl}
                                alt={`${WL_CONFIG?.brandFullName} logo`}
                            />
                        )
                        : <></>}
                    <h2>{WL_CONFIG.applicationName}</h2>
                </Styled.UnauthorisedLogoWrapper>
                {isDesktop && <ColorModeToggler />}
            </Styled.UnauthorisedHeadingWrapper>


            <Styled.UnauthorisedContentWrapper>
                <Styled.UnauthorisedRouteContentWrapper id="route-wrapper">
                    <AnimatePresence mode="wait">
                        {children}
                    </AnimatePresence>
                </Styled.UnauthorisedRouteContentWrapper>
            </Styled.UnauthorisedContentWrapper>

            <VStack>
                <ButtonGroup gap="2">
                    {!isDesktop && (
                        <>
                            <ColorModeToggler />
                            <ColorSchemeToggler />
                        </>
                    )}

                </ButtonGroup>

                <Box padding={{ base: '20px' }}>
                    <CopyrightNotice />
                </Box>

            </VStack>
        </Styled.UnauthorisedOuterWrapper>
    );
}

export default PublicLayout;
