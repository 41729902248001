import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';

import ColorModeToggler from './ColorModeToggler.layout';


const decorate = compose(
    withTranslation('common'),
);

export default decorate(ColorModeToggler);
