import { Box, Container, Text } from '@chakra-ui/react';
import React from 'react';
import { CommonFormFieldConfigItem } from 'models/app/formContols';
import { RadioCardGroup } from './RadioCardGroup';
import { RadioCard } from './RadioCardProps';

export interface FancyRadioProps {
        fieldConfiguration: CommonFormFieldConfigItem
}
export const FancyRadio = ({ fieldConfiguration }:FancyRadioProps) => {

    return (
        <Box as="section" bg="whiteAlpha.300" py={{ base: '4', md: '8' }}>
            <Container maxW="lg">
                <RadioCardGroup
                    spacing="3"
                    name={fieldConfiguration.name}
                    onChange={fieldConfiguration.onChange}
                    id={fieldConfiguration.id}
                >
                    {fieldConfiguration.optionalConfiguration?.options?.map(({ label, value, hintText }, index) => (
                        <RadioCard key={index} value={value.toString()}>
                            <Text color="emphasized" fontWeight="medium" fontSize="sm">
                                {label}
                            </Text>
                            <Text color="muted" fontSize="sm">
                                {hintText}
                            </Text>
                        </RadioCard>
                    ))}
                </RadioCardGroup>
            </Container>
        </Box>
    );
};
