import { SortDirections, SortDirectionsAPI } from 'models/app/applicationState';
import { cleanNilValues } from 'utils/dataMappers';
import {
    getCompanyIdDetails,
    updateCompanyId,
    getCompaniesApi,
    getCompanyIdRiskDetails,
    updateCompanyAddressId,
    getCompanyIdEmployees,
    createEmployeeForCompanyId,
} from './index';


class CompaniesService {
    public getCompanies(rawQuery) {
        const {
            search,
            sortOrder,
            sortBy = 'id',
            ...restOfQueryParams
        } = rawQuery;

        const queryParams = {
            ...restOfQueryParams,
            ...({ order: [`${sortOrder === SortDirections.DESCEND ? `-${sortBy}` : `${sortBy}`}`] }), // TODO v2 Inconsistencies!!!
            ...(search && search.length > 0 ? { search } : {}),
        };

        return getCompaniesApi({ params: queryParams });
    }


    public getCompanyDetails(queryParams) {
        return getCompanyIdDetails(queryParams.companyId);
    }

    public updateCompany(rawRequestPayload) {
        const { companyId, ...requestPayload } = rawRequestPayload;
        return updateCompanyId(companyId, requestPayload);
    }


    static getCompanyRiskDetails(companyId) {
        return getCompanyIdRiskDetails(companyId);
    }


    public updateCompanyAddress(rawRequestPayload) {
        const { companyId, ...requestPayload } = rawRequestPayload;
        return updateCompanyAddressId(companyId, requestPayload);
    }

    public getCompanyEmployees(rawQueryParams) {
        const {
            companyId,
            search,
            sortOrder,
            ...restOfQueryParams
        } = rawQueryParams;

        const queryParams = cleanNilValues({
            ...restOfQueryParams,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        });

        return getCompanyIdEmployees(companyId, { params: queryParams });
    }

    public createEmployeeForCompany(formData) {
        const { companyId, ...rest } = formData;

        return createEmployeeForCompanyId(companyId, rest);
    }
}

export default CompaniesService;

