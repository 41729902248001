import React, { useMemo } from 'react';

import FlagAndValue from 'components/common/molecules/FlagAndValue';
import { CountryIdType } from 'models/domain/countries';
import { CountryRendererProps } from './CountryRenderer.types';


function CountryRenderer({
    countriesList,
    value,
    propertyName = CountryIdType.iso31662,
    showCountryName = true,
    showTooltip,
    isTextEllipsisDisabled,
}: CountryRendererProps) {
    const countryData = useMemo(
        () => {
            if (countriesList && countriesList.items.length > 0) {
                return countriesList.items.find((country) => country?.[propertyName] === value);
            }

            return undefined;
        },
        [countriesList, value, propertyName],
    );


    return countryData ? (
        <FlagAndValue
            countryCode={countryData.iso31662}
            text={showCountryName ? countryData.name : value}
            inlineMode
            isTextEllipsisDisabled={isTextEllipsisDisabled}
            showTooltip={showTooltip}
        />
    ) : '-';
}

export default CountryRenderer;
