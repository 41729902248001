import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';
import BeneficiariesList from 'components/common/templates/BeneficiariesList';

import { BeneficiariesPageProps } from './BeneficiariesPage.types';

function BeneficiariesPage({ t }: BeneficiariesPageProps): JSX.Element {

    return (
        <StandardAuthorisedPage
            pageTitle={t('Beneficiaries')}
        >
            <BeneficiariesList />
        </StandardAuthorisedPage>
    );
}


export default BeneficiariesPage;
