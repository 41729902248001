import React from 'react';
import { connect } from 'react-redux';
import dotObject from 'dot-object';

import { currentUserReducerName } from 'store/current-user/reducer';
import { RootState } from 'store/rootReducer';
import { WithAccessControl } from 'models/domain/accessControl';


const mapPermissionNameToObjectPath = (permissionName): string => permissionName.replace(/:/g, '.');


function createAccessControlObject(state: RootState): WithAccessControl {
    const permissions = state[currentUserReducerName].permissions || { };
    const { clientConfig } = state[currentUserReducerName];


    return {
        accessControl: {
            isAuthorised: !!state[currentUserReducerName].jwtToken,
            clientConfig,

            hasPermission: (permissionName): boolean => {
                const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                const permissionValue = dotObject.pick(permissionNameAsPath, permissions);
                return permissionValue === true;
            },
            hasPermissions: (permissionNames): boolean => {
                return permissionNames.every((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = dotObject.pick(permissionNameAsPath, permissions);
                    return permissionValue === true;
                });
            },

            hasOneOfPermissions: (permissionNames): boolean => {
                return permissionNames.some((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = dotObject.pick(permissionNameAsPath, permissions);
                    return permissionValue === true;
                });
            },
        },
    };
}

// export function accessControlForEpics(state: RootState) {
//     const { accessControl } = createAccessControlObject(state);
//     return accessControl;
// }

export default function withAccessControl(WrappedComponent: React.ComponentType) {
    function AccessControlInjectorComponent(props) {
        return <WrappedComponent {...props} />;
    }

    const mapStateToProps = (state: RootState) => createAccessControlObject(state);

    return connect(mapStateToProps)(AccessControlInjectorComponent);
}
