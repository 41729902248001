import React from 'react';
import { MdPerson, MdKey, MdToken, MdTimer, MdSettings } from 'react-icons/md';
import { HStack } from '@chakra-ui/react';

import { CommonTabsConfigItem } from 'models/app/standardTabs';
import TheIcon from 'components/common/atoms/TheIcon';
import ColorModeToggler from 'components/common/atoms/CollorModeToggler';
import ColorSchemeToggler from 'components/common/atoms/ColorSchemeToggler';

import MyPermissions from './MyPermissions';

export function createMyProfilePageTabsConfig({
    t,
    jwtToken,
    userData,
    permissions,
    accessTokenExpirationTimeStamp,
    refreshTokenExpirationTimeStamp,
    userPreferences,
}):CommonTabsConfigItem[] {

    return [
        {
            tabLabel: t('User Data'),
            tabIcon: <TheIcon Icon={MdPerson} />,
            children: (
                <pre>
                    {JSON.stringify(userData, null, 2)}
                </pre>
            ),
        },

        {
            tabLabel: t('Permissions'),
            tabIcon: <TheIcon Icon={MdKey} />,
            children: <MyPermissions permissions={permissions} t={t} />,
        },
        {
            tabLabel: t('JWT Token'),
            tabIcon: <TheIcon Icon={MdToken} />,
            children: jwtToken,
        },
        {
            tabLabel: t('Token timestamps'),
            tabIcon: <TheIcon Icon={MdTimer} />,
            children: (
                <>
                    <h2>accessTokenExpirationTimeStamp</h2>
                    <pre>
                        {accessTokenExpirationTimeStamp}
                    </pre>
                    <h2>refreshTokenExpirationTimeStamp</h2>
                    <pre>
                        {refreshTokenExpirationTimeStamp}
                    </pre>
                </>
            ),
        },
        {
            tabLabel: t('Preferences'),
            tabIcon: <TheIcon Icon={MdSettings} />,
            children: (
                <HStack>
                    <ColorModeToggler />

                    <ColorSchemeToggler />

                    <pre>
                        {JSON.stringify(userPreferences, null, 2)}
                    </pre>
                </HStack>
            ),
        },
    ];
}
