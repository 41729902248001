import { ofType } from 'redux-observable';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import jwtDecode from 'jwt-decode';

import { rememberMeLoginForm, sessionUuidKey } from 'config/config';
import { getLocalStorageObjectItem, setLocalStorageObjectItem, setSessionStorageObjectItem } from 'utils/browser-storage';
import { forgotPasswordRootRoutePath, loginRootRoutePath, passcodeConfirmRootRoutePath } from 'config/routes';
import { requestNavigation } from 'store/navigation/actions';
import { clearCurrentUser, setCurrentUser } from 'store/current-user/actions';
import { generateSessionUuid } from 'utils/session-uuid';
import { setSessionUuid, showToast } from 'store/application/actions';
import { isTokenNotExpired } from 'utils/jwtToken';
import {
    initialiseLoginSuccess, initialiseLoginFailure,
    confirmLoginSuccess, confirmLoginFailure,
    setNewPasswordSuccess, setNewPasswordFailure,
    requestPasswordResetSuccess, requestPasswordResetFailure, getRememberedUserEmailSuccess, getRememberedUserEmailFailure,
} from 'store/authorisation/actions';
import { Epic } from 'models/meta/epic';
import { DecodedJwtTokenData } from 'models/domain/token';
import { ToastType } from 'models/app/toast';

import {
    CONFIRM_LOGIN,
    GET_REMEMBERED_LOGIN_USER_EMAIL,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_SUCCESS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_SUCCESS,
} from './actions.types';

const generateNewSessionUuid = (http) => {
    const newSessionUuid = generateSessionUuid();
    setSessionStorageObjectItem(sessionUuidKey, newSessionUuid);
    http.setSessionUuid(newSessionUuid);

    return setSessionUuid(newSessionUuid);
};

export const onInitialiseLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(INITIALISE_LOGIN),
        tap(({ payload }) => {
            if (payload.rememberMe) {
                setLocalStorageObjectItem(rememberMeLoginForm, payload.email);
            }
        }),
        switchMap(({ payload: { email, password } }) => {
            return from(authorisation.loginInit({ email, password })).pipe(
                switchMap((response) => {
                    return of(
                        initialiseLoginSuccess(response?.data?.id),
                        requestNavigation({ locationPathname: passcodeConfirmRootRoutePath }),
                    );
                }),
                catchError((error) => of(
                    initialiseLoginFailure(error),
                    showToast({ type: ToastType.error, message: error.message }),
                )),
            );
        }),
    );
};

export const onConfirmLogin: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(CONFIRM_LOGIN),
        switchMap(({ payload }) => {
            return from(authorisation.loginConfirm(payload)).pipe(
                switchMap((response) => of(
                    confirmLoginSuccess(response.data),
                    setCurrentUser(response.data),
                    // TODO remove
                    showToast({ type: ToastType.success, message: 'Brand new success toast', description: 'Lorem ipsum lorem ipsum lorem' }),
                    showToast({ type: ToastType.info, message: 'Brand new info toast' }),
                    showToast({ type: ToastType.warning, message: 'Brand new warning toast' }),
                    showToast({ type: ToastType.error, message: 'Brand new error toast' }),
                )),
                catchError(() => of(confirmLoginFailure())),
            );
        }),
    );
};

export const onSetNewPassword: Epic = (action$, state$, { authorisation, i18n, http }) => {
    return action$.pipe(
        ofType(SET_NEW_PASSWORD),
        switchMap(({ payload }) => {
            const decodedToken: DecodedJwtTokenData = jwtDecode(payload.token);
            const tokenExpirationTime = decodedToken.exp * 1000; // XXX in milliseconds!

            if (isTokenNotExpired(tokenExpirationTime, 2)) {
                return from(authorisation.setNewPassword(payload)).pipe(
                    switchMap((response) => of(setNewPasswordSuccess(response.data))),
                    catchError(() => of(setNewPasswordFailure())),
                );
            }

            return of(
                generateNewSessionUuid(http),
                setNewPasswordFailure(),
                showToast({ type: ToastType.error, message: i18n.t('unauthorised:actionMessages.setNewPasswordTokenHasExpired') }),
                requestNavigation({ locationPathname: forgotPasswordRootRoutePath }),
            );
        }),
    );
};

export const onSetNewPasswordSuccess: Epic = (action$, _, { i18n, http }) => {
    return action$.pipe(
        ofType(SET_NEW_PASSWORD_SUCCESS),
        switchMap(() => {
            return of(
                generateNewSessionUuid(http),
                showToast({ type: ToastType.success, message: i18n.t('unauthorised:actionMessages.setNewPasswordSuccess') }),
                requestNavigation({ locationPathname: loginRootRoutePath }),
            );
        }),
    );
};

export const onRequestPasswordReset: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(REQUEST_PASSWORD_RESET),
        switchMap(({ payload }) => {
            return from(authorisation.requestPasswordReset(payload)).pipe(
                switchMap((response) => of(requestPasswordResetSuccess(response.data))),
                catchError(() => of(requestPasswordResetFailure())),
            );
        }),
    );
};

export const onRequestPasswordResetSuccess: Epic = (action$, _, { http }) => {
    return action$.pipe(
        ofType(REQUEST_PASSWORD_RESET_SUCCESS),
        switchMap(() => of(generateNewSessionUuid(http))),
    );
};
export const onGetRememberedUserEmail: Epic = (action$) => action$.pipe(
    ofType(GET_REMEMBERED_LOGIN_USER_EMAIL),
    switchMap(() => {
        const storedUserNameOrEmail = getLocalStorageObjectItem(rememberMeLoginForm);
        if (storedUserNameOrEmail) {
            return of(getRememberedUserEmailSuccess(storedUserNameOrEmail));
        }

        return of(getRememberedUserEmailFailure());
    }),
);


export const onGetTokensForDuplicatedTab: Epic = (action$, _, { authorisation }) => {
    return action$.pipe(
        ofType(GET_TOKENS_FOR_DUPLICATED_TAB),
        switchMap(({ payload }) => {
            return from(authorisation.getTokensForDuplicatedSession(payload.requestPayload)).pipe(
                switchMap((response) => of(setCurrentUser({ ...response.data, ...payload.sessionStorageData }))),
                catchError(() => of(clearCurrentUser())),
            );
        }),
    );
};


export default [
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onSetNewPasswordSuccess,
    onGetRememberedUserEmail,
    onRequestPasswordReset,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
];
