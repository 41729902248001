import {
    Email, NumericId, SessionUuid, TelephoneNumber, Timestamp, Uuid,
} from 'models/app/common';
import { getObjectKeyByValue } from 'utils/object-tools';

export enum UserPreferencesTimeFormat {
    HOURS_24 = '24',
    HOURS_12 = '12',
}

export enum UserPreferencesDateFormat {
    DAY_MONTH_YEAR_SLASH = 'DD/MM/YYYY', // standardDateFormat,
    DAY_MONTH_YEAR_DOT = 'DD.MM.YYYY',
    DAY_MONTH_YEAR_DASH = 'DD-MM-YYYY',
    YEAR_MONTH_DAY_SLASH = 'YYYY/MM/DD',
    YEAR_MONTH_DAY_DOT = 'YYYY.MM.DD',
    YEAR_MONTH_DAY_DASH = 'YYYY-MM-DD',
    MONTH_DAY_YEAR_SLASH = 'MM/DD/YYYY',
    MONTH_DAY_YEAR_DOT = 'MM.DD.YYYY',
    MONTH_DAY_YEAR_DASH = 'MM-DD-YYYY',
}

export enum UserPreferencesDecimalSeparators {
    COMMA = ',',
    DOT = '.',
    DECIMAL_KEY = '⎖',
}
export enum UserPreferencesThousandsSeparators {
    COMMA = ',', // Comma, the thousands separator used in most English-speaking countries.
    DOT = '.', // Period (or full stop), the thousands separator used in many non-English speaking countries.
    SPACE = ' ', // internationally recommended
    ARABIC = '٬', // internationally recommended
}


export const UserPreferencesNumbersFormat = Object.freeze({
    SPACE__COMMA: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.COMMA}99`,
    SPACE__DOT: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.DOT}99`,
    COMMA__DOT: `999${UserPreferencesThousandsSeparators.COMMA}999${UserPreferencesDecimalSeparators.DOT}99`,
    DOT__COMMA: `999${UserPreferencesThousandsSeparators.DOT}999${UserPreferencesDecimalSeparators.COMMA}99`,
    ARABIC__DOT: `999${UserPreferencesThousandsSeparators.ARABIC}999${UserPreferencesDecimalSeparators.DOT}99`,
    SPACE__DECIMAL_KEY: `999${UserPreferencesThousandsSeparators.SPACE}999${UserPreferencesDecimalSeparators.DECIMAL_KEY}99`,
});

export interface UserPreferences {
    timeFormat: UserPreferencesTimeFormat,
    dateFormat: UserPreferencesDateFormat,
    decimalSeparator: UserPreferencesDecimalSeparators,
    thousandsSeparator: UserPreferencesThousandsSeparators
}

export const mapSeparatorsToNumbersFormat = ({ decimal_separator, thousands_separator }) => {
    const decimalSeparatorKey = getObjectKeyByValue(UserPreferencesDecimalSeparators, decimal_separator);
    const thousandsSeparatorKey = getObjectKeyByValue(UserPreferencesThousandsSeparators, thousands_separator);

    return `${thousandsSeparatorKey}__${decimalSeparatorKey}` || UserPreferencesNumbersFormat.SPACE__DOT;
};

export interface UserDataFormToken {
    id: NumericId
    email: Email

    firstName: string
    lastName: string
    phoneNumber: TelephoneNumber
    companyId: NumericId
    position: string // todo director vs employee
    verified: boolean
    documentsVerificationStatus: number
    isOwner: boolean
    externalUserId: Uuid,
    avatar: string,
    clientId: NumericId,
    shareholderId: NumericId,
    businessUserId: NumericId,
    user2FAId: NumericId,

    uuid: Uuid
    sessionUuid: SessionUuid,
    iat: Timestamp,
    exp: Timestamp

    fullName: string
}
