import { Observable } from 'rxjs';
import { Action, History } from 'history';

import { navigationStopped, preformNavigationBackSuccess, preformNavigationSuccess } from 'store/navigation/actions';
import { EnhancedLocation, NavigationDirectionTypes, RedirectionToDefaultPathTypes } from 'models/app/navigation';


// XXX crucial for navigation guards
export const createStop$ = (history, actions) => {
    const stopPathname = history.location.pathname;
    return new Observable(
        (subscriber) => history.block(({ location }) => {
            if (stopPathname !== location?.pathname) {
                history.replace(stopPathname); // TODO recheck this
                const notification = navigationStopped({ locationPathname: stopPathname });
                [notification, actions].forEach(subscriber.next.bind(subscriber));
                return undefined;
            }

            // prevent navigation
            return false;
        }),
    );
};


const createHistoryListenActions = ({
    direction,
    action,
    location,
}) => {
    const commonActions = [
        // hideModal(),
        // hideDrawer(),
    ];

    const enhancedLocation = {
        ...location,
        action,
        direction,
    };

    const handlers = [
        {
            predicate: () => direction === NavigationDirectionTypes.BACKWARD,
            handler: () => [preformNavigationBackSuccess(enhancedLocation)],
        },
        {
            predicate: () => direction === NavigationDirectionTypes.FORWARD
        && action === Action.Replace
        && location?.state?.type === RedirectionToDefaultPathTypes.ROUTER_REDIRECTION_TO_DEFAULT_PATH,
            handler: () => [preformNavigationSuccess(enhancedLocation)],
        },
        {
            predicate: () => action === Action.Replace,
            handler: () => [preformNavigationSuccess(enhancedLocation)],
        },
        {
            predicate: () => true,
            handler: () => [preformNavigationSuccess(enhancedLocation)],
        },
    ];

    const handler = handlers.filter(({ predicate }) => predicate())[0].handler();

    return [
        ...commonActions,
        ...handler,
    ];
};

// XXX trace & current are kept as local state
// this is done to normalise browser back & forward arrows behaviour asd both those navigation events are actions of type POP,
// but forward arrow should look the same as regular forward (action === PUSH) navigation form UX point of view,
// and only the browser back should look as back.


// XXX crucial for proper application behaviour
export const createLocation$ = (history: History) => {
    const trace: EnhancedLocation[] = [];
    let current = -1;

    return new Observable(
        (subscriber) => history.listen(
            (listener) => {
                const index = trace.findIndex((historyLocation: EnhancedLocation) => historyLocation.key === listener.location.key);

                // eslint-disable-next-line no-nested-ternary
                // const direction: NavigationDirectionTypes = index >= 0 && listener.action === Action.Pop && current > index
                //     ? NavigationDirectionTypes.BACKWARD
                //     : current < 0 || index !== current
                //         ? NavigationDirectionTypes.FORWARD
                //         : NavigationDirectionTypes.SELF;

                const handlers = [
                    {
                        predicate: () => index >= 0 && listener.action === Action.Pop && current > index,
                        handler: () => NavigationDirectionTypes.BACKWARD,
                    },
                    {
                        predicate: () => current < 0 || index !== current,
                        handler: () => NavigationDirectionTypes.FORWARD,
                    },
                    {
                        predicate: () => true,
                        handler: () => NavigationDirectionTypes.SELF,
                    },

                ];
                // XXX uber important!!! Browser 'go forward' button normalisation
                const direction = handlers.filter(({ predicate }) => predicate())[0].handler();

                const { state, ...standardLocationKeys } = listener.location;

                if (direction === NavigationDirectionTypes.BACKWARD) {
                    current = index;
                } else if (direction === NavigationDirectionTypes.FORWARD && standardLocationKeys.key) {
                    trace.length = current + 1;
                    current = trace.length;
                    trace.push({
                        action: listener.action,
                        ...standardLocationKeys,
                        direction,
                    });
                }

                const actions = createHistoryListenActions({
                    direction,
                    action: listener.action,
                    location: { ...standardLocationKeys },
                });

                actions.forEach(subscriber.next.bind(subscriber));
            },
        ),
    );
};

