import React from 'react';

import { StandardContentWrapperProps } from './ScrollableContainer.types';
import Styled from './ScrollableContainer.styled';


function StandardContentWrapper({ children }: StandardContentWrapperProps): JSX.Element {
    return (
        <Styled.StandardContentWrapper>
            {children}
        </Styled.StandardContentWrapper>
    );
}

export default StandardContentWrapper;
