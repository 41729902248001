import React from 'react';
import { MdSupport } from 'react-icons/md';

import StandardLink from 'components/common/atoms/StandardLink';
import { forgotPasswordRootRoutePath } from 'config/routes';
import TheIcon from 'components/common/atoms/TheIcon';
import StandardForm from 'components/common/organisms/StandardForm';
import StandardUnauthorisedPage from 'components/common/templates/StandardUnauthorisedPage';

import { LoginPageProps } from './LoginPage.types';
import { createLoginFormFieldsConfiguration, createLoginFormValidationSchema } from './LoginPage.helpers';

function LoginPage({
    loginFormRememberedUserEmail = '',
    loginFormError = '',
    isLoadingLoginForm,
    dispatchInitialiseLogin,
    t,
}: LoginPageProps): JSX.Element {

    return (
        <StandardUnauthorisedPage
            pageTitle={t('Login')}
            footerContent={(
                <StandardLink
                    leftIcon={<TheIcon Icon={MdSupport} />}
                    path={forgotPasswordRootRoutePath}
                    title={t('formsElements.forgotLink.text')}
                    dataTestId="forgot-password-link"
                />
            )}
        >
            <StandardForm
                t={t}
                submitButtonText={t('formsElements.login.text')}
                formName="loginForm"
                initialFormState={{
                    email: loginFormRememberedUserEmail,
                    password: '',
                    rememberMe: !!loginFormRememberedUserEmail,
                }}
                validationSchema={createLoginFormValidationSchema({ t })}
                formError={loginFormError}
                isLoading={isLoadingLoginForm}
                createFieldsConfiguration={createLoginFormFieldsConfiguration}
                dispatchOnSubmitAction={dispatchInitialiseLogin}
            />
        </StandardUnauthorisedPage>
    );
}


export default LoginPage;
