import { CardType } from 'models/domain/cards';

export const createCardTypeHandlers = (normalisedCardType, t) => {
    const handlers = [
        {
            predicate: () => normalisedCardType === CardType.physical,
            handler: () => ({ color: 'green', text: t('cards:cardFields.type.values.physical') }),
        },
        {
            predicate: () => normalisedCardType === CardType.virtual,
            handler: () => ({ color: 'blue', text: t('cards:cardFields.type.values.virtual') }),
        },
        {
            predicate: () => true,
            handler: () => ({ text: 'Unknown', color: 'red' }),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

export default { createCardTypeHandlers };
