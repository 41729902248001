import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { AlertProps } from './Alert.types';

function Alert({ t, showConfirmation, confirmationHeader, confirmationQuestion, onConfirm, dispatchHide }:AlertProps) {

    const cancelRef :React.MutableRefObject<null> = useRef(null);

    const handleConfirmation = () => {
        if (onConfirm) onConfirm();
        dispatchHide();
    };

    return (
        <AlertDialog
            isOpen={showConfirmation}
            leastDestructiveRef={cancelRef}
            onClose={dispatchHide}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t(confirmationHeader)}
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        {t(confirmationQuestion)}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} variant="outline" onClick={dispatchHide}>
                            {t('no')}
                        </Button>
                        <Button ml={3} onClick={handleConfirmation}>
                            {t('yes')}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );

}
export default Alert;
