import { createAction } from '@reduxjs/toolkit';
import {
    DELETE_CARD, DELETE_CARD_FAILURE, DELETE_CARD_SUCCESS, FETCH_CARD_DETAILS, FETCH_CARD_DETAILS_FAILURE, FETCH_CARD_DETAILS_SUCCESS,
} from './actions.types';
import { CLEAR_CARDS_LIST } from '../cards/actions.types';


export const fetchCardDetails = createAction(FETCH_CARD_DETAILS, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardDetailsSuccess = createAction(FETCH_CARD_DETAILS_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const fetchCardDetailsFailure = createAction(FETCH_CARD_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearCardDetails = createAction(CLEAR_CARDS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const deleteCard = createAction(DELETE_CARD, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const deleteCardSuccess = createAction(DELETE_CARD_SUCCESS, ({ responsePayload }) => ({ payload: responsePayload }));
export const deleteCardFailure = createAction(DELETE_CARD_FAILURE);

