import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import { withTranslation } from 'react-i18next';
import { ComponentType } from 'react';

import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { requestNavigation } from 'store/navigation/actions';
import withAccessControl from 'hoc/withAccessControl';
import { navigationReducerName } from 'store/navigation/reducer';
import { currentUserReducerName } from 'store/current-user/reducer';

import AccountsPage from './AccountsPage.layout';
import { AccountsPageDispatchProps, AccountsPageStateProps } from './AccountsPage.types';


const mapStateToProps = (state: RootState): AccountsPageStateProps => ({
    userData: state[currentUserReducerName].userData,
    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
});
const mapDispatchToProps = (dispatch: Dispatch): AccountsPageDispatchProps => ({
    dispatchRequestNavigation: ({ locationPathname, meta }) => dispatch(requestNavigation({ locationPathname, meta })),
    //
});

const decorate = compose<ComponentType>(
    withAccessControl,
    withTranslation('common'),
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountsPage);
