import React, { useMemo } from 'react';
import useStandardListCachingLogic from 'hook/useStandardListCachingLogic';
import { Transaction } from 'models/domain/transactions';
import { defaultTransactionsListQueryParams } from 'store/transactions/reducer';
import StandardDataTable from 'components/common/organisms/StandardDataList';
import { createCardStatusOptions, createCurrencyOptions, createTransactionStatusOptions } from 'utils/options-crators';

import { TransactionsListProps } from './TransactionsList.types';
import { createTransactionsListColumns } from './tableColumns';
import { createTransactionsListFiltersConfiguration } from './TransactionsList.helpers';


function TransactionsList({
    // own props
    isInReadOnlyMode = false,
    companyId,
    cardId,
    accountId,
    businessUserId,
    beneficiaryUuid,
    // XXX  list state data
    listData,
    enhancedCurrentLocation,

    // other mapped props
    accessControl,
    currenciesListData,
    dispatchFetchTransactionsList,
    dispatchClearTransactionsList,
    dispatchSetQueryParams,

    t,
}: TransactionsListProps): JSX.Element {
    // 1. context-specific / ownProps-driven list configuration
    const contextEnforcedQueryParams = {
        companyId,
        cardId,
        businessUserId,
        beneficiaryUuid,
        accountId,
    };

    // 2. standard BE endpoint -> queryParams & listFetching are in sync.
    const fetchData = (newQueryParams) => {
        if (enhancedCurrentLocation?.pathname) {
            const prams = {
                ...listData?.queryParams,
                ...newQueryParams,
            };

            dispatchSetQueryParams({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
            dispatchFetchTransactionsList({ queryParams: prams, locationPathname: enhancedCurrentLocation.pathname });
        }
    };

    // 3. standardListCachingLogic
    const isListInitialStateDefined = useStandardListCachingLogic<Transaction>({
        contextEnforcedQueryParams,
        defaultItemsListQueryParams: defaultTransactionsListQueryParams,

        enhancedCurrentLocation,
        listData,

        dispatchFetchItemsList: dispatchFetchTransactionsList,
        dispatchClearItemsList: dispatchClearTransactionsList,
        dispatchSetQueryParams,
    });


    // 4. context & permission dependent optional account list filters & buttons
    const currencyOptions = useMemo(
        () => currenciesListData?.items?.length > 0
            ? createCurrencyOptions({ currencies: currenciesListData.items })
            : [],
        [currenciesListData],
    );

    const transactionStatusOptions = createTransactionStatusOptions();

    // 5. standard render component
    return (
        <StandardDataTable<Transaction>
            t={t}
            fetchData={fetchData}
            totalCount={listData.totalCount}
            items={listData.items}
            isLoadingData={listData.isLoadingList}
            queryParams={listData.queryParams}
            tableMode
            filtersConfiguration={createTransactionsListFiltersConfiguration({
                t,
                currencyOptions,
                transactionStatusOptions,
            })}
            isListInitialStateDefined={isListInitialStateDefined}
            columnsConfiguration={createTransactionsListColumns({
                t,
                accessControl,
                queryParams: listData.queryParams,
            })}
        />
    );
}

export default TransactionsList;

