import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { RootState } from 'store/rootReducer';
import { ComponentType } from 'react';
import { connect } from 'react-redux';

import { getStandardListData } from 'store/standardLists.helpers';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'store/countries/reducer';
import { Currency } from 'models/domain/currencies';
import { Country } from 'models/domain/countries';
import withAccessControl from 'hoc/withAccessControl';

import AppLoadingPlaceholder from './AppLoadingPlaceholder.layout';
import { AppLoadingPlaceholderStateProps } from './AppLoadingPlaceholder.types';


const mapStateToProps = (state: RootState): AppLoadingPlaceholderStateProps => ({
    currencies: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    countries: getStandardListData<Country>({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
});


const decorate = compose<ComponentType>(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);


export default decorate(AppLoadingPlaceholder);
