import React from 'react';
import { Skeleton } from '@chakra-ui/react';

import StandardSelect from 'components/common/molecules/StandardSelect';
import { FormInputTypes } from 'models/app/formContols';
import { SortDirections } from 'models/app/applicationState';

import Styled from '../StandardFilters.styled';


function SorterFields({
    queryParams,
    handleQueryParamsChange,
    filtersConfiguration,
    colorScheme,
    isListReady,
    t,
}): JSX.Element {

    return filtersConfiguration?.sortByOptions && filtersConfiguration?.sortByOptions?.length > 0
        ? (
            <Styled.SortersWrapper>
                {isListReady
                    ? (
                        <>
                            <Styled.FilterWrapper>
                                <Styled.FilterLabel>
                                    {t('Sort by:')}
                                </Styled.FilterLabel>
                                <StandardSelect
                                    fieldConfiguration={{
                                        id: 'sortBy',
                                        name: 'sortBy',
                                        fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                        labelText: '',
                                        value: queryParams.sortBy,
                                        onChange: (value) => handleQueryParamsChange({
                                            fieldName: 'sortBy',
                                            value,
                                        }),
                                        optionalConfiguration: { options: filtersConfiguration?.sortByOptions },
                                    }}
                                />
                            </Styled.FilterWrapper>

                            <Styled.FilterWrapper>
                                <Styled.FilterLabel>{t('Sort order:')}</Styled.FilterLabel>
                                <StandardSelect
                                    fieldConfiguration={{
                                        id: 'sortOrder',
                                        name: 'sortOrder',
                                        fieldType: FormInputTypes.SELECT_SINGLE_VALUE,
                                        labelText: '',
                                        value: queryParams.sortOrder,
                                        onChange: (value) => handleQueryParamsChange({
                                            fieldName: 'sortOrder',
                                            value,
                                        }),
                                        optionalConfiguration: {
                                            options: [
                                                {
                                                    value: SortDirections.ASCEND,
                                                    label: t('Ascending'),
                                                },
                                                {
                                                    value: SortDirections.DESCEND,
                                                    label: t('Descending'),
                                                },
                                            ],
                                        },
                                    }}
                                />
                            </Styled.FilterWrapper>
                        </>
                    )
                    : (
                        <>
                            <Skeleton height="50px" width="200px" />
                            <Skeleton height="50px" width="200px" />
                        </>
                    )}
            </Styled.SortersWrapper>
        ) : <></>;

}

export default SorterFields;
