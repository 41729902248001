import styled from 'styled-components';


const StandardDataListOuterWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: minmax(0, auto)  minmax(0, 1fr)  minmax(0, auto);
`;


const StandardDataListWrapper = styled.div`
  height: 100%;
  
  .chakra-table {
    height: auto;

    tbody {
      height: auto !important;
      
      tr {
        height: auto;
        
        td {
          height: auto;
        }
      }
    }
  }
`;


export default { StandardDataListOuterWrapper, StandardDataListWrapper };
