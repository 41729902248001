import { connect } from 'react-redux';
import { compose } from '@reduxjs/toolkit';


import { RootState } from 'store/rootReducer';
import { AnimatedPageWrapperStateProps } from './AnimatedPageWrapper.types';
import AnimatedPageWrapper from './AnimatedPageWrapper.layout';

const mapStateToProps = (state: RootState): AnimatedPageWrapperStateProps => ({ enhancedCurrentLocation: state.navigation.currentLocation });

const decorate = compose(
    connect(mapStateToProps),
);

export default decorate(AnimatedPageWrapper);
