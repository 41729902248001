import React from 'react';

import StandardAuthorisedPage from 'components/common/templates/StandardAuthorisedPage';

import { AddBeneficiaryPageProps } from './AddBeneficiaryPage.types';


function AddBeneficiaryPage({
    accessControl,
    enhancedCurrentLocation,
    t,
}: AddBeneficiaryPageProps): JSX.Element {


    return (
        <StandardAuthorisedPage
            pageTitle={t('Add beneficiary')}
        >
            <h1>TODO: add beneficiary multi step form</h1>
        </StandardAuthorisedPage>
    );
}


export default AddBeneficiaryPage;
