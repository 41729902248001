import { BASE_API_URL, TIME_TO_AUTO_LOGOUT_COUNTDOWN } from './environment';

export const baseApiUrl = BASE_API_URL;


export const jwtTokenKey = 'jwt';
export const sessionUuidKey = 'sessionUuid';
export const permissionsKey = 'permissions';
export const colorSchemeKey = 'BWP_colorSchemeKey';
export const clientConfigKey = 'clientConfig';
export const userPreferencesKey = 'preferences';
export const appHistoryTrace = 'appHistoryTrace';
export const rememberMeLoginForm = 'rememberMe';
export const timeToAutoLogoutCountDownBanner = (Number(TIME_TO_AUTO_LOGOUT_COUNTDOWN) || 4) * 60 * 1000; // in milliseconds


export const standardPaginationOptions = [5, 10, 20, 50];
export const standardDateFormat = 'DD/MM/YYYY';
export const standardDateAndTimeFormat = 'DD/MM/YYYY HH:mm:ss';
export const standardTimeFormat = 'HH:mm:ss';
export const usTimeFormat = 'h:mm:ss A';

export const startOfDayTime = '00:00:00';
export const endOfDayTime = '23:59:59';


export const isoDateFormat = 'YYYY-MM-DD';
export const standardPageSize = 4;
export const defaultLocale = 'en-GB';
