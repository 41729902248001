import * as React from 'react';
import { Table } from '@chakra-ui/react';

import SkeletonTable from 'components/common/atoms/SkeletonTable';

import StandardDataListTableBody from './StandardDataListTableBody';
import StandardDataListTableHead from './StandardDataListTableHead';

import Styled from './StandardDataListTable.styled';

function StandardDataListTable<ItemDefinition>({
    items,
    queryParams,
    columnsConfiguration,
    isListReady,
}) {
    return (
        <Styled.StandardTableWrapper>
            { isListReady
                ? (
                    <Table>
                        <StandardDataListTableHead<ItemDefinition>
                            columnsConfiguration={columnsConfiguration}
                            queryParams={queryParams}
                        />

                        <StandardDataListTableBody<ItemDefinition>
                            columnsConfiguration={columnsConfiguration}
                            items={items}
                            queryParams={queryParams}
                        />
                    </Table>
                )
                : <SkeletonTable columnCount={columnsConfiguration?.length} rowLimit={queryParams.limit} />}
        </Styled.StandardTableWrapper>
    );
}


export default StandardDataListTable;
