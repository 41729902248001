import { createReducer } from '@reduxjs/toolkit';

import { withSkipImages } from 'services/http/http.helpers';
import { SortDirections } from 'models/app/applicationState';
import { globalLocation } from 'models/app/navigation';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { SET_QUERY_PARAMS } from 'store/application/action.types';

import { FETCH_CURRENCIES, FETCH_CURRENCIES_FAILURE, FETCH_CURRENCIES_SUCCESS } from './actions.types';
import { updateStandardListDataQueryParams, updateStandardLists, updateStandardListsOnFailure, updateStandardListsOnSuccess } from '../standardLists.helpers';

export const currenciesReducerName = 'currencies';
export const currenciesListsPerLocationStoreKeyName = 'currenciesListsPerLocation';
export const defaultCurrenciesListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withSkipImages,
};

const initialState = { [currenciesListsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: defaultCurrenciesListQueryParams } } };


const handlers = {
    [FETCH_CURRENCIES]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[currenciesListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CURRENCIES_SUCCESS]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[currenciesListsPerLocationStoreKeyName],
            action,
        });
    },
    [FETCH_CURRENCIES_FAILURE]: (state, action) => {
        state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[currenciesListsPerLocationStoreKeyName],
            action,
        });
    },

    [SET_QUERY_PARAMS]: (state, action) => {
        if (action.payload?.reducerName === currenciesReducerName
            && action.payload?.fieldName === currenciesListsPerLocationStoreKeyName
        ) {
            state[currenciesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state?.[currenciesListsPerLocationStoreKeyName],
                action,
            });
        }
    },

    [CLEAR_CURRENT_USER]: () => {
        return initialState;
    },
};

export default createReducer(initialState, handlers);
