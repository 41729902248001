import React from 'react';
import { TFunction } from 'i18next';

import { Transaction } from 'models/domain/transactions';
import { AccessControlContent } from 'models/domain/accessControl';
import { CurrencyIsoCode, QueryParams } from 'models/app/common';
import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import StandardValue from 'components/common/molecules/StandardValue';
import { PN } from 'models/domain/permissions';
import TransactionStatusTag from 'components/common/atoms/StandardTag/TransactionStatusTag';
import TransactionScopeTag from 'components/common/atoms/StandardTag/TransactionScopeTag';
import FlagAndValue from 'components/common/molecules/FlagAndValue';
import ViewButton from 'components/common/atoms/ViewButton';
import { StandardValueType } from '../../../../models/app/standardValues';


export const createTransactionsListColumns = (
    {
        accessControl,
        queryParams,
        t,
    }: {
        accessControl: AccessControlContent,
        queryParams: QueryParams
        t: TFunction
    },
): TableColumnConfiguration<Transaction>[] => [
    {
        title: 'ID',
        dataIndex: 'id',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <TransactionStatusTag transactionStatus={value} />,
    },
    {
        title: 'Transaction type',
        dataIndex: 'transactionType',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Transaction scope',
        dataIndex: 'transactionScope',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (text, record) => <TransactionScopeTag transactionRecord={record} />,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.AMOUNT} />,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <FlagAndValue currencyCode={value as CurrencyIsoCode} text={value} tooltipPlacement="top-start" showTooltip />,

    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.DATE} />,
    },


    ...(accessControl.hasPermission(PN.TRANSACTIONS.READ_BY_ID)
        ? [
            {
                title: t('common:actions'),
                dataIndex: '',
                align: TableColumnAlign.center,
                render: (_, item) => (
                    <>
                        <ViewButton
                            onClick={(() => alert('TODO dispatch open transaction drawer'))}
                            title={t('View details')}
                        />
                    </>

                ),
            },
        ]
        : []
    ),
];


export default { createTransactionsListColumns };
