import styled, { css } from 'styled-components';

import demoImages from 'assets/images/demoImages';

const UnauthorisedOuterWrapper = styled.div`
  margin: 0;
  height: 100%;
  display: grid;
  justify-content: center;
  grid-template-rows: auto minmax(0, 1fr) auto;
  
  &.light {
    background-image: url(${demoImages.modernHexagonalGridDesign});
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  };
  
`;
const UnauthorisedHeadingWrapper = styled.div<{ isDesktop?: boolean }>`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: min-content minmax(0, 1fr) min-content;
  ${(props) => !props.isDesktop && css`
    grid-template-columns: minmax(0, 1fr);
  `}
`;


const UnauthorisedContentWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
`;

const UnauthorisedLogoWrapper = styled.div`
  padding: 48px 20px 20px;
  text-align: center;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 20px;
  align-items: center;
  justify-items: center;
  justify-content: center;

  h2 {
    margin: 0;
    font-family: monospace;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
    word-spacing: 3px;
  }
`;

const UnauthorisedLogo = styled.img`
  height: 50px;
  width: auto;
`;


export const UnauthorisedRouteContentWrapper = styled.div`
  outline: 1px solid transparent;
`;

// eslint-disable-next-line spellcheck/spell-checker
export const UnauthorisedLinkWrapper = styled.div`
  .ant-btn {
    color: #397dcc;
    cursor: pointer;
    font-size: 14px;
    padding: 0;

    &:hover span {
      text-decoration: underline;
    }
  }
`;

const UnauthorisedFooterWrapper = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) auto;
  padding: 130px 20px 20px;
  justify-content: center;
`;

export default {
    UnauthorisedOuterWrapper,
    UnauthorisedHeadingWrapper,
    UnauthorisedContentWrapper,
    UnauthorisedLogoWrapper,
    UnauthorisedLogo,
    UnauthorisedRouteContentWrapper,
    UnauthorisedFooterWrapper,
};
