import { Divider, Flex, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useColorMode } from '@chakra-ui/system';

import NavButton from 'components/common/molecules/NavButton';
import { dashboardRootRoutePath } from 'config/routes';
import StandardLink from 'components/common/atoms/StandardLink';
import { APPLICATION_NAME, WL_CONFIG } from 'config/environment';

import MainMenu from '../MainMenu';
import { createUserDropdownItemsConfig } from '../UserDropdown/UserDropodown.helpers';
import UserProfile from '../UserProfile';
import { SidebarProps } from './Sidebar.types';


const Sidebar = ({
    t,
    isDesktop,
    username,
    dispatchRequestNavigation,
    dispatchClearCurrentUser,
    dispatchShowConfirmation,

}:SidebarProps) => {
    const { toggleColorMode } = useColorMode();


    return (
        <Flex as="section" minH="100vh" bg="bg-canvas">
            <Flex
                flex="1"
                bg="bg-accent"
                color="on-accent"
                maxW={{
                    base: 'full',
                    sm: 'xs',
                }}
                py={{
                    base: '6',
                    sm: '8',
                }}
                px={{
                    base: '4',
                    sm: '6',
                }}
            >
                <Stack justify="space-between" spacing="1">
                    <Stack
                        spacing={{
                            base: '5',
                            sm: '6',
                        }}
                        shouldWrapChildren
                    >
                        <StandardLink path={dashboardRootRoutePath} title={'Dashboard'} buttonVariant={'unstyled'}>
                            <Flex alignItems="center" gap={2}>
                                {WL_CONFIG?.brandSignetLogoUrl
                                    ? (
                                        <Image
                                            src={WL_CONFIG?.brandSignetLogoUrl}
                                            boxSize="40px"
                                            objectFit="contain"
                                            alt={`${WL_CONFIG?.brandFullName} logo`}
                                        />
                                    )
                                    : <></>}

                                <Text>
                                    {WL_CONFIG.applicationName}
                                </Text>
                            </Flex>
                        </StandardLink>
                        <Divider />
                        <MainMenu
                            isDesktop={isDesktop}
                        />
                    </Stack>
                    <Stack spacing={{
                        base: '5',
                        sm: '6',
                    }}
                    >
                        <Stack spacing="1">
                            { createUserDropdownItemsConfig({
                                t,
                                dispatchClearCurrentUser,
                                dispatchRequestNavigation,
                                toggleColorMode,
                            })
                                .map((menuItemConfiguration) => (
                                    <NavButton
                                        key={menuItemConfiguration.text}
                                        leftIcon={menuItemConfiguration.icon}
                                        onClick={() =>
                                            menuItemConfiguration.confirmation
                                                ? dispatchShowConfirmation({ ...menuItemConfiguration.confirmation })
                                                : menuItemConfiguration.onClick()}
                                    >
                                        {menuItemConfiguration.text}
                                    </NavButton>
                                ))}
                        </Stack>
                        <Divider />
                        <UserProfile name={username} />
                    </Stack>
                </Stack>
            </Flex>
        </Flex>
    );

};
export default Sidebar;
