import * as yup from 'yup';

import { CommonFormFieldConfigItem, FormInputTypes } from 'models/app/formContols';

import { AddAccountFormFields } from './AddAccountPage.types';


export const createAddAccountFormFieldsConfiguration = ({ t }): CommonFormFieldConfigItem[] => ([
    {
        fieldType: FormInputTypes.TEXT,
        id: 'currency',
        name: 'currency',
        labelText: t('accountFields.accountCurrency.label'),
        placeholderText: t('accountFields.accountCurrency.placeholder'),
        hintText: t('accountFields.accountCurrency.hint'),
        optionalConfiguration: { isRequired: true },
    },
    {
        fieldType: FormInputTypes.TEXT,
        id: 'name',
        name: 'name',
        labelText: t('accountFields.accountName.label'),
        placeholderText: t('accountFields.accountName.placeholder'),
        hintText: t('accountFields.accountName.hint'),
        optionalConfiguration: { isRequired: true },
    },
]);

export const createAddAccountFormSchema = ({ t }) => {
    return yup.object<AddAccountFormFields>({
        currency: yup.string()
            .required(t('common:validationMsg.fieldMandatory')),
        name: yup.string()
            .required(t('common:validationMsg.fieldMandatory'))
            .max(100, t('common:validationMsg.maximumLength', { maxCount: 100 })),
    }).required();
};
