import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

import { isoDateFormat, standardDateFormat, standardTimeFormat, usTimeFormat } from 'config/config';
import { UserPreferencesTimeFormat } from 'models/domain/user';

dayjs.extend(utc);
dayjs.extend(relativeTime);
// relativeTime

export const timestampDiffInSeconds = (timestamp) => Math.floor((timestamp / 1000) % 60);

export const utcToLocalTime = (date) => dayjs.utc(date).local();

export const isoDateOnlyStringToDate = (date) => dayjs(date);

export const mapTimeFormat = (userPreference) => {
    if (userPreference === UserPreferencesTimeFormat.HOURS_12) {
        return usTimeFormat;
    }
    return standardTimeFormat;
};

export function formatDate({
    date,
    dateFormat = standardDateFormat,
    convertFromUTC = true,
}) {
    const noDate = !date || date.length === 0;
    const resultDate = convertFromUTC ? utcToLocalTime(date) : date;

    if (noDate) {
        return date;
    }
    return dayjs(resultDate).format(dateFormat);
}

export const getDateRangeForXDaysFromToday = (
    x = 30,
    dateFormat = isoDateFormat,
) => ({
    dateFrom: dayjs().subtract(x, 'days').format(dateFormat),
    dateTo: dayjs().format(dateFormat),
});

export const getDateRangeForPreviousMonth = () => ({
    timeRange: [
        dayjs().subtract(1, 'months').startOf('month'),
        dayjs().subtract(1, 'months').endOf('month'),
    ],
});

export const getRelativeTimeFromNowString = (date) => dayjs(date).fromNow();

export const preventDatesFormFuture = (currentDate) => currentDate && currentDate > dayjs().endOf('day'); // allow time to be grater than now() as long as it isn't next day


export default {
    timestampDiffInSeconds,
    utcToLocalTime,
    isoDateOnlyStringToDate,
    formatDate,
    mapTimeFormat,
    getRelativeTimeFromNowString,
    getDateRangeForXDaysFromToday,
    preventDatesFormFuture,
};
