import { ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';

import { fetchCurrenciesFailure, fetchCurrenciesSuccess } from 'store/currencies/actions';
import { Epic } from 'models/meta/epic';

import { FETCH_CURRENCIES } from './actions.types';


export const onFetchCurrencies: Epic = (action$, state$, { currencies }) => {
    return action$.pipe(
        ofType(FETCH_CURRENCIES),
        switchMap(({ payload }) => from(currencies.fetchCurrencies(
            Object.keys(payload?.queryParams).length > 0
                ? payload?.queryParams
                : { skipImages: true },

        )).pipe(
            switchMap((response) => of(fetchCurrenciesSuccess(
                response.data,
                response?.data?.length,
                payload?.locationPathname,
            ))),
            catchError(() => of(fetchCurrenciesFailure(payload?.locationPathname))),
        )),
    );
};


export default [
    onFetchCurrencies,
];
