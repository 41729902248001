import {
    IndicatorsContainer,
    SelectContainer,
    ValueContainer,
} from './containers';
import Control, {
    ClearIndicator,
    DropdownIndicator,
    IndicatorSeparator,
    LoadingIndicator,
} from './control';
import Input from './input';
import Menu, {
    Group,
    GroupHeading,
    LoadingMessage,
    MenuList,
    NoOptionsMessage,
    Option,
} from './menu';
import MultiValue, {
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
} from './multi-value';
import Placeholder from './placeholder';
import SingleValue from './single-value';

const chakraComponents = {
    ClearIndicator,
    Control,
    DropdownIndicator,
    Group,
    GroupHeading,
    IndicatorSeparator,
    IndicatorsContainer,
    Input,
    LoadingIndicator,
    LoadingMessage,
    Menu,
    MenuList,
    MultiValue,
    MultiValueContainer,
    MultiValueLabel,
    MultiValueRemove,
    NoOptionsMessage,
    Option,
    Placeholder,
    SelectContainer,
    SingleValue,
    ValueContainer,
};

export default chakraComponents;
