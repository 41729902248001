import React from 'react';

import StandardTag from 'components/common/atoms/StandardTag';

import { createCardStatusHandlers } from './CardStatusTag.helpers';

function CardStatusTag({ cardStatus, t }) {
    const tagData = createCardStatusHandlers(cardStatus);

    return <StandardTag color={tagData.color} value={tagData.text} />;
}


export default CardStatusTag;
