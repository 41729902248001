import { CardExpiryDate, DateFullIsoString, JsonString, NumericId, S3Url } from 'models/app/common';

export enum CardCategory {
    business = 'business',
    personal = 'personal',
}

export enum CardType {
    physical = 1,
    virtual = 2,
}
export enum CardStatus {
    // editable statuses
    NOT_ACTIVATED = 'NOT_ACTIVATED',
    ACTIVE = 'ACTIVE',
    SUSPENDED = 'SUSPENDED',

    // final(permanent) statuses, card is in a read-only mode
    NOT_DELIVERED = 'NOT_DELIVERED',
    LOST = 'LOST',
    STOLEN = 'STOLEN',
    FRAUD = 'FRAUD',
    EXPIRED = 'EXPIRED',
    VOIDED = 'VOIDED',
}

export interface Card {
    accountId: NumericId,
    atmWithdrawalEnabled: boolean,
    businessUserId: NumericId,
    cardLimit: number,
    cardSpent: number,
    cardStatus: CardStatus,
    cardToken: string,
    cardType: CardCategory,
    clientId: NumericId,
    companyId: NumericId,
    contactlessPaymentEnabled: boolean,
    createdAt: DateFullIsoString,
    cvv: string, // static value '***' // useless bytes in payload
    deleted: number,
    deletedAt: null | DateFullIsoString,
    embossName: string,
    enrolled: boolean,
    expiry: CardExpiryDate,
    hasLimit: boolean,
    id: NumericId,
    imageUrl: S3Url,
    isActivated: boolean,
    isLinked: boolean,
    isLocked: boolean,
    magstripeEnabled: boolean,
    memberId: NumericId,
    onlinePaymentEnabled: boolean,
    panMask: string,
    request: JsonString,
    response: JsonString,
    token: string,
    type: CardType,
    updatedAt: DateFullIsoString
}

