import { UserPreferencesDecimalSeparators, UserPreferencesThousandsSeparators } from 'models/domain/user';

const parseValue = (rawValue) => {
    const value = Number.parseFloat(rawValue);
    return value && !Number.isNaN(value) ? value : Number.NaN;
};

const formatNumberToNumberWithSeparatorsAndPrecision = ({
    rawValue,
    decimalSeparator = '.',
    thousandsSeparator = ',', // en-GB
    precision = 2,
}) => {
    const value = typeof rawValue === 'number'
        ? rawValue
        : parseValue(rawValue);


    const [stringNumsBeforeDot, stringNumsAfterDot = ''] = value.toFixed(precision).split('.');
    const stringWithThousandSeparator = stringNumsBeforeDot.replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, `$&${thousandsSeparator}`);

    return (
        stringNumsAfterDot
            ? stringWithThousandSeparator + decimalSeparator + stringNumsAfterDot
            : stringWithThousandSeparator
    );
};

export const amountFormatter = ({
    value,
    decimalSeparator,
    thousandsSeparator,
    precision,
}:{
    value: number,
    decimalSeparator?: UserPreferencesDecimalSeparators,
    thousandsSeparator?: UserPreferencesThousandsSeparators,
    precision?: number,
}) => formatNumberToNumberWithSeparatorsAndPrecision({
    rawValue: value,
    decimalSeparator,
    thousandsSeparator,
    precision,
});


export default { amountFormatter };

