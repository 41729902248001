import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';

import {
    clearAccountsList,
    fetchAccountsList,
} from 'store/accounts/actions';
import withAccessControl from 'hoc/withAccessControl';
import { requestNavigation } from 'store/navigation/actions';
import { setQueryParams } from 'store/application/actions';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'store/accounts/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { getStandardListData } from 'store/standardLists.helpers';
import { navigationReducerName } from 'store/navigation/reducer';
import { RootState } from 'store/rootReducer';
import { Dispatch } from 'models/meta/action';
import { Currency } from 'models/domain/currencies';
import { Account } from 'models/domain/accounts';


import AccountsList from './AccountsList.layout';
import { AccountsListDispatchProps, AccountsListOwnProps, AccountsListStateProps } from './AccountsList.types';


const mapStateToProps = (state: RootState): AccountsListStateProps => ({
    currenciesListData: getStandardListData<Currency>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    }),

    enhancedCurrentLocation: state[navigationReducerName].currentLocation,
    listData: getStandardListData<Account>({
        state,
        reducerName: accountsReducerName,
        listsByLocationStoreKeyName: accountsListsPerLocationStoreKeyName,
    }),
});


const mapDispatchToProps = (dispatch: Dispatch): AccountsListDispatchProps => ({
    dispatchFetchAccountsList: ({ queryParams, locationPathname }) => dispatch(fetchAccountsList({ queryParams, locationPathname })),
    dispatchClearAccountsList: ({ locationPathname }) => dispatch(clearAccountsList({ locationPathname })),
    dispatchRequestNavigation: ({ locationPathname }) => dispatch(requestNavigation({ locationPathname })),
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => dispatch(setQueryParams({
        reducerName: accountsReducerName,
        fieldName: accountsListsPerLocationStoreKeyName,
        locationPathname,
        queryParams,
    })),
});

const decorate = compose<ComponentType<AccountsListOwnProps>>(
    withTranslation('accounts'),
    withAccessControl,
    connect(mapStateToProps, mapDispatchToProps),
);

export default decorate(AccountsList);
