import { cleanNilValues } from 'utils/dataMappers';

import { camelToSnakeCase } from 'utils/string-tools';
import {
    getTransactionIdDetails,
    getAllTransactionsAPI,
    createNewPaymentAPI,
} from 'services/transactions/index';
import { SortDirections } from 'models/app/applicationState';


class TransactionsService {
    public getTransactionDetails(transactionId) {
        return getTransactionIdDetails(transactionId);
    }

    public createNewPayment(requestPayload) {
        return createNewPaymentAPI(requestPayload);
    }


    public getAllTransactions(rawQueryParams) {
        const {
            limit = 10,
            offset = 0,
            search,
            status,
            clientId,
            cardId,
            accountId,
            beneficiaryId,
            businessUserId,
            companyId,
            memberId,
            currency,
            sortBy,
            sortOrder,
            showCardVerification,
        } = rawQueryParams || {};

        // const transactionScopeQueryParams = mapTransactionScopeFilterValueToQuery(transaction_scope?.[0], transactionsFilters);

        const clearedQueryParams = cleanNilValues({
            limit,
            offset,
            sortBy: sortBy ? camelToSnakeCase(sortBy) : undefined,
            sortOrder: `${sortOrder === SortDirections.DESCEND ? 'desc' : 'asc'}`,
            search: search?.length > 0 ? search : null,
            clientId,
            accountId,
            beneficiaryId,
            cardId,
            businessUserId,
            companyId,
            memberId,
            status,
            currency,
            showCardVerification,
        });

        const queryParams = { params: { ...clearedQueryParams } };
        return getAllTransactionsAPI(queryParams);
    }
}

export default TransactionsService;
