import React from 'react';
import { TFunction } from 'i18next';

import { BusinessUser } from 'models/domain/businessUsers';
import { AccessControlContent } from 'models/domain/accessControl';
import { QueryParams } from 'models/app/common';
import { TableColumnAlign, TableColumnConfiguration } from 'models/app/tableColumnsConfiguration';
import StandardValue from 'components/common/molecules/StandardValue';
import { PN } from 'models/domain/permissions';
import { createBusinessUserDetailsPath } from 'config/routes.helpers';
import { StandardValueType } from 'models/app/standardValues';
import ViewButton from 'components/common/atoms/ViewButton';

export const createBusinessUsersListColumns = (
    {
        accessControl,
        queryParams,
        t,
    }: {
        accessControl: AccessControlContent,
        queryParams: QueryParams
        t: TFunction
    },
): TableColumnConfiguration<BusinessUser>[] => [
    {
        title: 'ID',
        dataIndex: 'id',
        align: TableColumnAlign.right,
        isSortable: true,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'First Name',
        dataIndex: 'firstName',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} />,
    },
    {
        title: 'Phone number',
        dataIndex: 'phoneNumber',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.PHONE} />,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        align: TableColumnAlign.right,
        render: (value) => <StandardValue value={value} valueType={StandardValueType.DATE} />,
    },


    ...(accessControl.hasPermission(PN.BENEFICIARIES.READ_BY_ID)
        ? [
            {
                title: t('common:actions'),
                dataIndex: '',
                align: TableColumnAlign.center,
                render: (_, item) => (
                    <>
                        <ViewButton
                            path={createBusinessUserDetailsPath(item?.id)}
                            title={t('common:viewDetails')}
                            useLinkVersion
                        />
                    </>
                ),
            },
        ]
        : []
    ),
];


export default { createBusinessUsersListColumns };
