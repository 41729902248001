import { StateObservable } from 'redux-observable';

import { QueryParams } from 'models/app/common';

import { RootState } from './rootReducer';
import { currentUserReducerName } from './current-user/reducer';

export function addCompanyIdToQueryParams({
    state$,
    queryParams,
}: { state$: StateObservable<RootState>, queryParams: QueryParams | any }) {

    const { businessUserId, beneficiaryUuid, accountId, cardId } = queryParams;

    const companyId = state$.value[currentUserReducerName].userData?.companyId;

    return {
        ...queryParams,
        ...(accountId ? { accountId } : {}),
        ...(businessUserId ? { businessUserId } : {}),
        ...(cardId ? { cardId } : {}),
        ...(beneficiaryUuid ? { beneficiaryId: beneficiaryUuid } : {}),
        ...(!beneficiaryUuid && !beneficiaryUuid && !accountId && !cardId
            ? { companyId }
            : {}
        ),
    };
}
