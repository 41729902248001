import React from 'react';

import { PN } from 'models/domain/permissions';
import TheIcon from 'components/common/atoms/TheIcon';
import { MdAccountBalanceWallet, MdInfo, MdReceiptLong, MdAccountBalance, MdCreditCard } from 'react-icons/md';
import TransactionsList from 'components/common/templates/TransactionsList';
import CardsList from 'components/common/templates/CardsList';


export const createAccountDetailsPageTabsConfig = ({
    fixedAccountId,
    accessControl,
    entityDetailsData,
    t,
}) => [
    ...(accessControl.hasPermission(PN.CARDS.READ_BY_ID) ? [
        {
            tabLabel: t('Account details'),
            tabIcon: <TheIcon Icon={MdAccountBalanceWallet} />,
            children: (
                <pre>
                    {JSON.stringify(entityDetailsData?.entityDetails, null, 2)}
                </pre>
            ),
        },
    ] : []),

    {
        tabLabel: t('Account identifications'),
        tabIcon: <TheIcon Icon={MdAccountBalance} />,
        children: (
            <pre>
                {JSON.stringify(entityDetailsData?.entityDetails?.account_identifications, null, 2)}
            </pre>
        ),
    },

    {
        tabLabel: t('Transactions'),
        tabIcon: <TheIcon Icon={MdReceiptLong} />,
        children: (
            <TransactionsList accountId={fixedAccountId} />
        ),
    },
    {
        tabLabel: t('Cards'),
        tabIcon: <TheIcon Icon={MdCreditCard} />,
        children: (
            <CardsList accountId={fixedAccountId} />
        ),
    },

    {
        tabLabel: t('Meta info'),
        tabIcon: <TheIcon Icon={MdInfo} />,
        children: (
            <>
                <pre>
                    {JSON.stringify(entityDetailsData?.queryParams, null, 2)}
                </pre>
                <pre>
                    {JSON.stringify(entityDetailsData?.queryParamsMeta, null, 2)}
                </pre>
            </>
        ),
    },
];


export default { createAccountDetailsPageTabsConfig };
