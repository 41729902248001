export const FETCH_BENEFICIARY_DETAILS = 'FETCH_BENEFICIARY_DETAILS';
export const FETCH_BENEFICIARY_DETAILS_SUCCESS = 'FETCH_BENEFICIARY_DETAILS_SUCCESS';
export const FETCH_BENEFICIARY_DETAILS_FAILURE = 'FETCH_BENEFICIARY_DETAILS_FAILURE';
export const CLEAR_BENEFICIARY_DETAILS = 'CLEAR_BENEFICIARY_DETAILS';


export const DELETE_BENEFICIARY = 'DELETE_BENEFICIARY';
export const DELETE_BENEFICIARY_SUCCESS = 'DELETE_BENEFICIARY_SUCCESS';
export const DELETE_BENEFICIARY_FAILURE = 'DELETE_BENEFICIARY_FAILURE';

export const UPDATE_BENEFICIARY = 'UPDATE_BENEFICIARY';
export const UPDATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS';
export const UPDATE_BENEFICIARY_FAILURE = 'UPDATE_BENEFICIARY_FAILURE';
